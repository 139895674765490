<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
<!--    <notifications></notifications>-->
    <side-bar>
      <template v-slot:links>
        <user-menu v-on:logout="Logout" :user="User"></user-menu>
        <sidebar-item :link="{ name: 'Dashboard', icon: 'now-ui-icons media-2_sound-wave', path: '/dashboard' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Actions', icon: 'fa-shuffle' }">
            <sidebar-item :link="{ name: 'Taken', path: '/Actions'}" :disabled="!CompletedIMCourseOrMemberOfHealth"></sidebar-item>
            <sidebar-item :link="{ name: 'Quick Actions', path: '/Actions/Quick'}" :disabled="!CompletedIMCourseOrMemberOfHealth"></sidebar-item>
            <sidebar-item :link="{ name: 'Progress', path: '/Actions/Progress'}" :disabled="!IntendedMeCourseCompletedClass"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Health', icon: 'fa-heart-pulse' }">
            <sidebar-item :link="{ name: 'Recipes', path: '/Health/Recipes'}" :disabled="!CompletedIMCourseOrMemberOfHealth"></sidebar-item>
            <sidebar-item :link="{ name: 'Weight', path: '/Health/Weight'}" :disabled="!CompletedIMCourseOrMemberOfHealth"></sidebar-item>
            <sidebar-item :link="{ name: 'Size', path: '/Health/Size'}" :disabled="!CompletedIMCourseOrMemberOfHealth"></sidebar-item>
<!--            <sidebar-item :link="{ name: 'Progress', path: '/Actions/Progress'}"></sidebar-item>-->
        </sidebar-item>
        <sidebar-item v-if="!IsHealthSite" :link="{ name: 'Courses', icon: 'fa-school' }">
            <sidebar-item :link="{ name: 'Intended Me', path: '/Courses/1'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="UserIsEmployeeOrHigher" :link="{ name: 'Admin', icon: 'fa-screwdriver-wrench' }">
            <sidebar-item :link="{ name: 'Users', icon: 'fa-user' }">
                <sidebar-item :link="{ name: 'All Users', path: '/Users'}"></sidebar-item>
            </sidebar-item>
            <sidebar-item v-if="UserIsAdmin" :link="{ name: 'Courses', icon: 'fa-school' }">
                <sidebar-item :link="{ name: 'All Courses', path: '/Admin/Courses'}"></sidebar-item>
            </sidebar-item>
        </sidebar-item>
<!--        <sidebar-item :link="{ name: 'Invoices', icon: 'now-ui-icons files_paper' }">
            <sidebar-item :link="{ name: 'View All', path: '/invoices/'}"></sidebar-item>
            <sidebar-item :link="{ name: 'New Invoice', path: '/invoices/new-invoice'}"></sidebar-item>
        </sidebar-item>-->
         <sidebar-item v-if="IsNDevMode"
          :link="{ name: 'Pages', icon: 'now-ui-icons design_image' }"
        >
          <sidebar-item
            :link="{ name: 'Pricing', path: '/pricing' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Timeline', path: '/pages/timeline' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Login', path: '/login' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Register', path: '/register' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Lock Screen', path: '/lock' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Profile Profile', path: '/Profile' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="IsNDevMode"
          :link="{ name: 'Components', icon: 'now-ui-icons education_atom' }"
        >
          <sidebar-item
            :link="{ name: 'Buttons', path: '/components/buttons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Grid System', path: '/components/grid-system' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Panels', path: '/components/panels' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Sweet Alert', path: '/components/sweet-alert' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Notifications', path: '/components/notifications' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Icons', path: '/components/icons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Typography', path: '/components/typography' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="IsNDevMode"
          :link="{ name: 'Forms', icon: 'now-ui-icons files_single-copy-04' }"
        >
          <sidebar-item
            :link="{ name: 'Regular Forms', path: '/forms/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Extended Forms', path: '/forms/extended' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Validation Forms', path: '/forms/validation' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Wizard', path: '/forms/wizard' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="IsNDevMode"
          :link="{ name: 'Tables', icon: 'now-ui-icons design_bullet-list-67' }"
        >
          <sidebar-item
            :link="{ name: 'Regular Tables', path: '/table-list/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Extended Tables', path: '/table-list/extended' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Paginated Tables', path: '/table-list/paginated' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="IsNDevMode"
          :link="{ name: 'Maps', icon: 'now-ui-icons location_pin' }"
        >
          <sidebar-item
            :link="{ name: 'Google Maps', path: '/maps/google' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Full Screen Maps', path: '/maps/full-screen' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Vector Maps', path: '/maps/vector-map' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="IsNDevMode"
          :link="{
            name: 'Widgets',
            icon: 'now-ui-icons objects_diamond',
            path: '/widgets'
          }"
        ></sidebar-item>
        <sidebar-item v-if="IsNDevMode"
          :link="{
            name: 'Charts',
            icon: 'now-ui-icons business_chart-pie-36',
            path: '/charts'
          }"
        ></sidebar-item>
        <sidebar-item v-if="IsNDevMode"
          :link="{
            name: 'Calendar',
            icon: 'now-ui-icons media-1_album',
            path: '/calendar'
          }"
        ></sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
<!--        <transition name="fade" :duration="200" mode="out-in">-->
          <!-- your content here -->
          <router-view></router-view>
<!--        </transition>-->
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>

      <modal :class="{show:typeof Recipe.id !== 'undefined'}" :show-close="true" headerClasses="justify-content-center" :CloseFunc="CloseRecipeModal">
        <template v-slot:header>
          <font-awesome-icon v-if="RecipeLoading" class="pull-right Refresh loading" icon="arrows-rotate" />
          <h4 class="title title-up">{{ Recipe.name }} Recipe</h4>
        </template>
        <Recipe :Recipe="Recipe" @CloseRecipeModal="CloseRecipeModal"></Recipe>
      </modal>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
/*import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";*/

/*function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}*/

/*function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}*/

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import UserMenu from "./Extra/UserMenu.vue";
// import { ZoomCenterTransition } from "vue2-transitions";
import { Modal } from "@/components";
import Recipe from 'src/Modules/Health/Components/Recipe';
import apiUsers from '../api/users';

export default {
    components: {
        TopNavbar,
        ContentFooter,
        UserMenu,
        // ZoomCenterTransition,
        Modal,
        Recipe,
        // SidebarItem
    },
    data() {
        return {
            run: {
                MustBeEmployeeOrHigher: false
            },
            IsNDevMode: process.env.NODE_ENV === 'development',
            IsHealthSite: this.$store.getters.getSiteDomain().indexOf('health.') === 2
        }
    },
    computed: {
        User() {
            return this.$store.state.Users;
        },
        UserIsEmployeeOrHigher() {
            let Return = false;
            let Roles = this.User.Roles;
            if (Roles.length)
            {
                let Role = Roles[0];
                if (Role.level && Role.level < 4) { Return = true; }
            }
            return Return;
        },
        UserRole() {
            return this.$store.getters.UsersGetRole();
        },
        UserIsAdmin() {
            return this.UserRole ? parseInt(this.UserRole.level) === 1 : false;
        },
        CoursesCompleted() {
            return this.$store.getters.CoursesCompleted_Courses();
        },
        IntendedMeCourseCompleted() {
            return this.CoursesCompleted.find(CC => CC.courses_id === 1) || false;
        },
        IntendedMeCourseCompletedClass() {
            return this.IntendedMeCourseCompleted ? true : false;
        },
        IsMemberOfHealthSite() {
            return typeof this.$store.getters.UsersGetMemberships().find(M => M.users_sites_id === 3) !== 'undefined';
        },
        CompletedIMCourseOrMemberOfHealth() {
            return this.IntendedMeCourseCompletedClass || this.IsMemberOfHealthSite;
        },
        Recipes() {
          return this.$store.getters.Recipes_GetAll();
        },
        RecipeLoading() {
            return this.$store.getters.Recipes_GetSpecificLoading();
        },
        Recipe() {
            return this.$store.getters.Recipes_GetSpecific();
        }
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        Logout() {
            apiUsers.Logout().then(() => {
                this.$store.dispatch('Actions_ResetState');
                this.$store.dispatch('BodySizes_ResetState');
                this.$store.dispatch('BodyWeights_ResetState');
                this.$store.dispatch('Courses_ResetState');
                this.$store.dispatch('Tags_ResetState');
                this.$store.dispatch('Subscriptions_ResetState');
                this.$store.dispatch('User_ResetState');
                localStorage.removeItem('Auth');
                localStorage.removeItem('BT');
                this.$router.push({name: 'Login'});
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('Auth');
                    localStorage.removeItem('BT');
                    this.$router.push({name: 'Login'});
                }
            });
        },
        CheckRuns() {
            for (let x in this.run)
            {
                if (this.run[x]) { this[x](); }
            }
        },
        MustBeEmployeeOrHigher() {
            if (this.$store.getters.UsersGetRole() === false) { this.run.MustBeEmployeeOrHigher = true; }
            else
            {
                if (this.$store.getters.UsersGetRole().level > 3)
                {
                    this.$notify({
                        message: 'You do not have permission to view that page',
                        timeout: 0,
                        type: 'danger'
                    });
                    this.$router.push({name: 'Dashboard'});
                }
                this.run.MustBeEmployeeOrHigher = false;
            }
        },
        CloseRecipeModal() {
            this.$store.dispatch('Recipes_Find');
        }
    },
    created() {
        if (!this.User.id)
        {
            apiUsers.GetLoggedInUser().then(res => {
                this.$store.dispatch('Users_UpdateSelf', res.data);
                this.$store.dispatch('CoursesCompleted_GetByUserID', res.data.id);
            }).catch(() => {
                this.Logout();
            });
        }
    },
    mounted() {
        /*let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith("Win");
        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            initScrollbar("sidebar");
            initScrollbar("sidebar-wrapper");

            docClasses.add("perfect-scrollbar-on");
        } else {
            docClasses.add("perfect-scrollbar-off");
        }*/
    },
    watch: {
        UserRole() { this.CheckRuns(); }
    }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
