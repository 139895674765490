import axios from 'axios';

let Api = axios.create({ headers: {Accept: 'application/json'}, baseURL: process.env.VUE_APP_API_URL });

Api.defaults.withCredentials = true;
Api.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem('BT');
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function(error) {
        return Promise.reject({Reject: error.response});
    }
);

export default Api;
