import Api from 'src/api/API';

export default {
    Index() {
        return Api.get('/Auth/Health/Size');
    },
    Add(data) {
        return Api.post('/Auth/Health/Size', data);
    },
    Update(SizeID, data) {
        return Api.patch('/Auth/Health/Size/'+SizeID, data);
    },
    Delete(SizeID) {
        return Api.delete('/Auth/Health/Size/'+SizeID);
    },

    FindByUserID(UserID) {
        return Api.get('/Auth/Health/Size/User/'+UserID);
    }
};
