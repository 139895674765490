<template>
    <div class="Page pageActions">

      <font-awesome-icon class="pull-right Refresh" :class="{loading: ActionsLoading}" icon="arrows-rotate" @click="getActions" />
      <h1>Actions</h1>

      <el-table stripe style="width: 100%" class="BreakWord" :data="Actions">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :prop="column.prop"
            :formatter="TableDataFormatter"
            :label="column.label"
            :min-width="column.minWidth"
            :width="column.width"
        >
        </el-table-column>
        <el-table-column :min-width="80" :width="140" fixed="right" label="Actions">
          <template v-slot:default="props">
            <div class="table-actions">
              <n-button
                  @click="handleView(props.$index, props.row)"
                  class="like"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button>
              <n-button
                  @click="handleEdit(props.$index, props.row)"
                  class="edit"
                  type="warning"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="pencil" />
              </n-button>
              <n-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="trash-can" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <modal :class="{show:modals.ActionAddEdit.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseActionAddEditModal">
        <template v-slot:header>
          <h4 class="title title-up">{{ modals.ActionAddEdit.title }}</h4>
        </template>
        <ActionAddEdit
            :ActionID="modals.ActionAddEdit.Action.id"
            :InsertNew="modals.ActionAddEdit.InsertNew"
            @CloseActionAddEditModal="CloseActionAddEditModal"
        ></ActionAddEdit>
      </modal>

      <modal :class="{show:modals.Action.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseActionModal">
        <template v-slot:header>
          <h4 class="title title-up">{{ modals.Action.Action.action }}</h4>
        </template>
        <Action :ActionID="modals.Action.Action.id" @CloseActionModal="CloseActionModal"></Action>
      </modal>

    </div>
</template>
<script>
import { ElTable, ElTableColumn, ElDatePicker } from "element-plus";
import { Modal } from "@/components";
import { format } from "date-fns";
import ActionAddEdit from 'src/Modules/Actions/Components/Action_AddEdit';
import Action from 'src/Modules/Actions/Components/Action';

export default {
    name: 'Actions',
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        Modal,
        Action, ActionAddEdit
    },
    data() {
        return {
            selectedTags: [],
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'action',
                    label: 'Action',
                    minWidth: 160,
                    width: 0
                },
                {
                    prop: 'measurement',
                    label: 'Measurement',
                    minWidth: 180,
                    width: 180
                },
                {
                    prop: 'unit_of_measurement',
                    label: 'Unit of Measurement',
                    minWidth: 180,
                    width: 180
                },
                {
                    prop: 'action_time',
                    label: 'Taken At',
                    minWidth: 200,
                    width: 200
                },
            ],
            modals: {
                ActionAddEdit: {
                    show: false,
                    title: 'Insert New Action',
                    InsertNew: true,
                    Action: { placeholder: true }
                },
                Action: {
                    show: false,
                    Action: { action: '', placeholder: true }
                }
            }
        };
    },
    computed: {
        Actions() {
            return this.$store.getters.Actions_GetAll();
        },
        Pagination() {
            return this.$store.getters.Actions_GetPagination();
        },
        UserID() {
            return this.$store.state.Users.id;
        },
        Tags() {
            return this.$store.getters.Tags_GetOptions();
        },
        ActionsLoading() {
            return this.$store.state.Actions.ActionsLoading;
        }
    },
    mounted() {
        this.$store.dispatch('Actions_GetMeasurementUnits');
        this.getActions();
        this.$store.dispatch('Tags_UpdateAll');
    },
    methods: {
        getActions() {
            this.$store.dispatch('Actions_UpdateAll');
        },
        UpdateOptions() {
            this.options = this.$store.getters.Tags_GetOptions();
        },
        TableDataFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'action_time':
                    Data = format(new Date(row.action_time), 'M/d/yyyy h:mm a');
                break;
                case 'unit_of_measurement':
                    let UofM = this.$store.getters.Actions_GetMeasurmentUnitsByID(row.unit_of_measurement);
                    Data = UofM.name;
                break;
                case 'measurement':
                    if (row.unit_of_measurement === 5)
                    {
                        let ActionTime = new Date(row.action_time);
                        let DesiredTime = new Date(ActionTime.getFullYear()+'-'+(ActionTime.getMonth()+1)+'-'+ActionTime.getDate()+' '+row.desired_time);
                        let Difference = (DesiredTime-ActionTime)/1000;
                        if (Difference/60 > 1) { Data = (Difference/60).toFixed(2)+' minutes early'; }
                        if (Difference/60 < -1) { Data = (-Difference/60).toFixed(2)+' minutes late'; }
                        if (Difference/3600 > 1) { Data = (Difference/3600).toFixed(2)+' hours early'; }
                        if (Difference/3600 < -1) { Data = (-Difference/3600).toFixed(2)+' hours late'; }
                    }
                    if (row.unit_of_measurement === 6)
                    {
                        if (row.stop)
                        {
                            let Start = new Date(row.start);
                            let Stop = new Date(row.stop);
                            let Difference = (Stop-Start)/1000;
                            if (Difference/60 > 1) { Data = (Difference/60).toFixed(2)+' minutes'; }
                            if (Difference/3600 > 1) { Data = (Difference/3600).toFixed(2)+' hours'; }
                        }
                    }
                    if ([5,6].indexOf(row.unit_of_measurement) === -1) { Data = cellValue; }
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        handleView(index, row) {
            this.ShowActionModal(row);
        },
        handleEdit(index, row) {
            this.modals.ActionAddEdit.title = 'Edit Action';
            this.modals.ActionAddEdit.InsertNew = false;
            this.ShowActionAddEditModal(row);
        },
        handleDelete(index, row) {
            this.$store.dispatch('Actions_RemoveAction', row);
        },
        ShowActionAddEditModal(Action) {
            this.modals.ActionAddEdit.Action = Action;
            this.modals.ActionAddEdit.show = true;
        },
        CloseActionAddEditModal() {
            this.modals.ActionAddEdit.Action = { placeholder: true };
            this.modals.ActionAddEdit.title = 'Insert New Action';
            this.modals.ActionAddEdit.show = false;
        },
        ShowActionModal(Action) {
            this.modals.Action.Action = Action;
            this.modals.Action.show = true;
        },
        CloseActionModal() {
            this.modals.Action.Action = { placeholder: true };
            this.modals.Action.title = '';
            this.modals.Action.show = false;
        }
    }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.pageActions {
    .BreakWord th .cell { word-break: break-word; }
}
</style>