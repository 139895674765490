import Api from 'src/api/API';

export default {
    All() {
        return Api.get('/Auth/Users');
    },
    Find(UserID) {
        return Api.get('/Auth/Users/'+UserID);
    },
    Add(data) {
        return Api.post('Auth/Users', data);
    },
    Update(UserID, data) {
        return Api.patch('/Auth/Users/'+UserID, data);
    },
    Delete(UserID) {
        return Api.delete('/Auth/Users/'+UserID);
    },

    GetLoggedInUser() {
        return Api.get('/Auth/GetLoggedInUser');
    },
    updateProfile(data) {
        return Api.patch('/Auth/Profile', data);
    },

    getContactDetails() {
        return Api.get('Contacts/Details');
    },
    updateContactDetails(ContactID, ContactInfo) {
        return Api.patch('Contacts/'+ContactID+'/Update', ContactInfo);
    },

    GetAllRoles() {
        return Api.get('/Auth/Roles');
    },

    GetAllSites() {
        return Api.get('/Auth/Admin/Sites');
    },

    Register(data) {
        return Api.post('Auth/Register', data);
    },
    VerifyEmail(data) {
        return Api.get('Auth/email/verify/'+data.ID+'/'+data.Hash, { params: data.query });
    },
    ResendVerifyEmail(data) {
        return Api.post('Auth/email/verification-notification');
    },
    Login(data) {
        return Api.post('/Auth/Login', data);
    },
    Logout() {
        return Api.post('/Auth/Logout');
    },
    sendPasswordResetEmail(data) {
        return Api.post('/Auth/Password/Email', data);
    },
    resetPassword(data) {
        return Api.post('/Auth/Password/Reset', data);
    },
};
