<template>
    <div class="Page">

      <font-awesome-icon icon="plus" title="Add" class="btn btn-primary pull-right" @click="ShowWeightModal" />
      <h1>Weight</h1>

      <el-table stripe style="width: 100%" :data="Weights">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
            :formatter="TableDataFormatter"
        >
        </el-table-column>
        <el-table-column :min-width="60" fixed="right" label="Actions">
          <template v-slot:default="props">
            <div class="table-actions">
              <!-- <n-button
                  @click="handleView(props.$index, props.row)"
                  class="like"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button> -->
              <n-button
                  @click="handleEdit(props.$index, props.row)"
                  class="edit"
                  type="warning"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="pencil" />
              </n-button>
              <n-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="trash-can" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <modal :class="{show:modals.Weight.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseWeightModal">
        <template v-slot:header>
          <h4 class="title title-up">{{ modals.Weight.title }}</h4>
        </template>
        <Weight
            :Weight="modals.Weight.Weight"
            :InsertNew="modals.Weight.InsertNew"
            @CloseWeightModal="CloseWeightModal"
        ></Weight>
      </modal>

    </div>
</template>
<script>
import { Modal } from "@/components";
import { ElTable, ElTableColumn, ElDatePicker } from "element-plus";
// import { Pagination as NPagination } from "@/components";
import { format } from "date-fns";
import Weight from '../Components/Weight';

import apiWeights from '@/Modules/Health/api/weight';

export default {
    name: 'Weights',
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        Modal,
        Weight
    },
    data() {
        return {
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'weight',
                    label: 'Weight',
                    minWidth: 100,
                },
                {
                    prop: 'fat',
                    label: 'Fat',
                    minWidth: 100,
                },
                {
                    prop: 'muscle',
                    label: 'Muscle',
                    minWidth: 100,
                },
                {
                    prop: 'water',
                    label: 'Water',
                    minWidth: 100,
                },
                {
                    prop: 'bone',
                    label: 'Bone',
                    minWidth: 100,
                },
                {
                    prop: 'measured_time',
                    label: 'Taken At',
                    minWidth: 100,
                },
            ],
            modals: {
                Weight: {
                    show: false,
                    title: 'Insert New Weight Entry',
                    InsertNew: true,
                    Weight: {}
                }
            }
        };
    },
    computed: {
        Weights() {
            return this.$store.getters.BodyWeights_GetAll();
        },
        Pagination() {
            return this.$store.getters.BodyWeights_GetPagination();
        },
        UserID() {
            return this.$store.state.Users.id;
        },
        UserRole() {
            return this.$store.getters.UsersGetRole();
        }
    },
    mounted() {
        if (this.UserID) { this.GetWeights(); }
        console.log('Weights Mounted');
    },
    methods: {
        GetWeights() {
            this.$store.dispatch('BodyWeights_UpdateAll', {GetemAll: false, UserID: this.UserID});
        },
        TableDataFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'measured_time':
                    Data = format(new Date(row.measured_time), 'M/d/yyyy h:mm a');
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        handleView(index, row) {
            console.log({index, row});
        },
        handleEdit(index, row) {
            this.modals.Weight.Weight = row;
            this.modals.Weight.title = 'Edit Weight Entry';
            this.modals.Weight.InsertNew = false;
            this.ShowWeightModal();
        },
        handleDelete(index, row) {
            console.log({index, row});
            apiWeights.Delete(row.id).then(res => {
                console.log({res});
                if (res.status === 200) { this.$store.dispatch('BodyWeights_RemoveWeight', row.id); }
            });
        },
        ShowWeightModal() {
            this.modals.Weight.show = true;
        },
        CloseWeightModal() {
            this.modals.Weight.Weight = {};
            this.modals.Weight.title = 'Insert New Weight Entry';
            this.modals.Weight.InsertNew = true;
            this.modals.Weight.show = false;
        }
    },
    watch: {
        UserID() {
            console.log('Weights UserID Changed');
            this.GetWeights();
        }
    }
};
</script>
