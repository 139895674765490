import Api from 'src/api/API';

export default {
    Index() {
        return Api.get('/Auth/Tags');
    },
    Add(data) {
        return Api.post('Auth/Tags', data);
    },
    Update(TagID, data) {
        return Api.patch('/Auth/Tags/'+TagID, data);
    },
    Delete(TagID) {
        return Api.delete('/Auth/Tags/'+TagID);
    },
};
