<template>
  <div
    class="form-group MultiTextArea"
    :class="[
        divClasses,
      { 'has-danger': error },
      { 'has-danger': isError },
      { 'has-success': !error && touched },
      { 'has-success': isValid },
    ]"
  >
    <font-awesome-icon v-if="!EditMode" icon="plus" title="Add" class="btn btn-sm btn-primary pull-right" @click="AddText" />
    <font-awesome-icon v-if="EditMode" icon="pencil" title="Edit" class="btn btn-sm btn-info pull-right" @click="SwitchModes(0)" />
    <label>{{ label }}</label>
    <QuillEditor theme="snow" toolbar="full" v-model:content="InputText" contentType="html" :key="keyTA" />
    <div class="TARow" v-for="(Input, k) in Inputs" :key="'Input'+k">
      <div class="col-12">
        <div class="TALabel">{{ placeholder }} {{ k+1 }}</div>
        <div v-html="Input" class="TAInput"></div>
        <div class="TAAction">
            <span class="btn btn-info" @click="SwitchModes(k+1)">
                <font-awesome-icon icon="pencil" title="Edit" />
            </span>
            <span class="btn btn-danger" @click="RemoveText(k)">
                <font-awesome-icon icon="trash-can" title="Delete" />
            </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor, Quill } from '@vueup/vue-quill';

export default {
  inheritAttrs: false,
  name: "TextArea Input",
  components: {
    QuillEditor
  },
  props: {
    Inputs: Array,
    name: String,
    isError: Boolean,
    isValid: Boolean,
    required: Boolean,
    label: String,
    placeholder: String,
    error: String,
    divClasses: String,
    labelClasses: String,
    inputClasses: String,
    value: [String, Number],
    addonRightIcon: String,
    addonRightIconClick: {
      type: Function,
      default: () => {}
    },
    addonLeftIcon: String,
  },
  data() {
    return {
      touched: false,
      focused: false,
      errorMessage: '',
      InputText: '',
      keyTA: 0,
      EditMode: 0
    };
  },
  computed: {
  },
  methods: {
    AddText() {
      if (!this.InputText.length) { return false; }
      this.Inputs.push(this.InputText);
      this.InputText = '';
      this.keyTA++;
    },
    SwitchModes(InputKey) {
      if (!InputKey) { this.Inputs[this.EditMode-1] = this.InputText; }
      this.EditMode = InputKey;
      this.InputText = InputKey ? this.Inputs[InputKey-1] : '';
      this.keyTA++;
    },
    RemoveText(index) {
      this.Inputs.splice(index, 1);
      this.keyTA++;
    },
    QuillReady (quill) {
        // const Cue = quill.getContent();
        // console.log({quill});
        // console.log({Content: quill.container.innerHTML});
    }
  },
};
</script>
<style lang="scss">
.MultiTextArea {
    > .btn { margin: 4px 1px 5px 5px; }
    .ql-editor { min-height: 100px; }
    .TARow {
        > div {
            display: flex; justify-content: space-between; align-items: flex-start;
            background: #CCCCCC;

            .TALabel { min-width: 100px; padding: 15px 15px 15px 0; }
            .TAInput { flex-grow: 2; padding: 15px; }
            .TAAction { min-width: 120px; }
        }

        &:nth-child(even) > div { background: #EEEEEE; }
    }
}
</style>
