<template>
    <form @submit.prevent="save">

        <div v-if="Error" class="alert alert-danger" v-html="Error"></div>

        <div class="row">
            <div class="col-12 col-md-4 col-lg-3" v-for="(Measure, i) in Measures" :key="i">
                <fg-input type="number" step=".1" :label="Measure.label" :placeholder="Measure.label" v-model:value="formInputs[Measure.prop]"></fg-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <label>Taken At</label>
                <el-date-picker
                    type="datetime"
                    v-model:model-value="formInputs.measured_time"
                    format="MM/DD/YYYY h:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Taken At">
                </el-date-picker>
            </div>
        </div>

        <div class="row">
            <Images divClasses="col-12 text-center mt-3" v-model:Images="formInputs.images" @update:Images="UpdateImages"></Images>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <button class="btn btn-primary">{{ AddEdit }} Size{{ SizeID ? ' #'+SizeID : '' }}</button>
            </div>
        </div>

    </form>
</template>
<script>
import { ElDatePicker } from "element-plus";
import { format } from "date-fns";
import Images from "@/components/Inputs/Images";

import apiSizes from '@/Modules/Health/api/size';

let FormInputs = () => {
    return {
        users_id: 0,
        height: '',
        wrist: '',
        knee: '',
        ankle: '',
        shoulders: '',
        chest: '',
        waist: '',
        neck: '',
        bicep_r: '',
        bicep_l: '',
        forearm_r: '',
        forearm_l: '',
        thigh_r: '',
        thigh_l: '',
        calf_r: '',
        calf_l: '',
        measured_time: format(new Date(), 'yyyy-MM-dd HH:mm'),
        images: []
    };
};

export default {
    components: {
        [ElDatePicker.name]: ElDatePicker,
        Images
    },
    data() {
        return {
            formInputs: FormInputs(),
            Error: '',
            options: [],
            NewOptionKey: 0,
            Measures: [
                {prop:'height', label:'Height'},
                {prop:'wrist', label:'Wrist'},
                {prop:'knee', label:'Knee'},
                {prop:'ankle', label:'Ankle'},
                {prop:'shoulders', label:'Shoulders'},
                {prop:'chest', label:'Chest'},
                {prop:'waist', label:'Waist'},
                {prop:'neck', label:'Neck'},
                {prop:'bicep_r', label:'Bicep (right)'},
                {prop:'bicep_l', label:'Bicep (left)'},
                {prop:'forearm_r', label:'Forearm (right)'},
                {prop:'forearm_l', label:'Forearm (left)'},
                {prop:'thigh_r', label:'Thigh (right)'},
                {prop:'thigh_l', label:'Thigh (left)'},
                {prop:'calf_r', label:'Calf (right)'},
                {prop:'calf_l', label:'Calf (left)'}
            ]
        };
    },
    props: {
        Size: {
            type: Object,
            default: {}
        },
        InsertNew: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        AddEdit() {
            this.SetFormInputs();
            return this.InsertNew ? 'Insert New' : 'Update';
        },
        SizeID() {
            return typeof this.Size.id !== 'undefined' ? this.Size.id : 0;
        },
        UserID() {
            return this.$store.state.Users.id;
        }
    },
    mounted() {
        this.SetFormInputs();
    },
    methods: {
        UpdateImages(Images) {
            this.formInputs.images = Images;
        },
        SetFormInputs() {
            this.Error = '';
            this.formInputs = this.SizeID ? this.Size : FormInputs();
            this.formInputs.users_id = this.UserID;
        },
        AddImage() {
            if (!this.Image.length) { return false; }
            if ([7,8].indexOf(this.Image.indexOf('drive.google.com')) > -1)
            {
                let aImage = this.Image.replace('https://', '').replace('http://', '').split('/');
                this.Image = 'https://' + aImage[0] + '/uc?export=view&id=' + aImage[3];
            }
            this.formInputs.images.push(this.Image);
            this.Image = '';
        },
        RemoveImage(index) {
            console.log({index});
            this.formInputs.images.splice(index, 1);
        },
        save() {
            this.Error = '';
            if (this.InsertNew)
            {
                apiSizes.Add(this.formInputs).then(res => {
                    console.log({res});
                    this.$store.dispatch('BodySizes_AddSize', res.data);
                    this.$emit('CloseSizeModal');
                    this.formInputs = FormInputs();
                }).catch(e => {
                    console.log('Error: ', e.response.data);
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
            else
            {
                apiSizes.Update(this.Size.id, this.formInputs).then(res => {
                    this.$store.dispatch('BodySizes_UpdateSize', res.data);
                    this.$emit('CloseSizeModal');
                    this.formInputs = FormInputs();
                }).catch(e => {
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
        },
        processErrors(Errors, Status) {
            let i = 0;
            if (Status === 422)
            {
                for (let x in Errors)
                {
                    this.Error += (i?'<br>':'')+Errors[x].join('<br>');
                    i++;
                }
            }
        }
    }
};
</script>
