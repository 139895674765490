<template>
    <form @submit.prevent="save">

        <div v-if="Error" class="alert alert-danger" v-html="Error"></div>

        <div class="row">
            <div class="col-12 col-md-6">
                <fg-input type="text" label="Username" placeholder="Username" v-model:value="formInputs.User.username"></fg-input>
            </div>
            <div class="col-12 col-md-6">
                <fg-input type="email" label="Email" placeholder="Email" v-model:value="formInputs.User.email"></fg-input>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <label>Role</label><br>
                <el-select class="select-info" size="large" v-model="formInputs.Role">
                    <el-option class="select-info" label="Select A Role" key="RoleKey0" value=""></el-option>
                    <el-option v-for="Role in Roles" class="select-info" :label="Role.name" :key="Role.level" :value="Role.level"></el-option>
                </el-select>
            </div>
            <div class="col-12">
                <label>Sites</label><br>
                <Multiselect
                    v-model="formInputs.Sites"
                    mode="tags"
                    placeholder="Select the Sites"
                    :options="Sites"
                    valueProp="id"
                    label="site"
                    noOptionsText="The options are not showing up"
                    noResultsText="Looks like you are out of options."
                    :searchable="true"
                    :key="'SitesKey'+Sites.length"
                />
            </div>
            <div v-if="formInputs.Role === 3" class="col-12">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <fg-input type="text" label="First Name" placeholder="First Name" v-model="formInputs.Employee.first_name"></fg-input>
                    </div>
                    <div class="col-12 col-md-4">
                        <fg-input type="text" label="Last Name" placeholder="Last Name" v-model="formInputs.Employee.last_name"></fg-input>
                    </div>
                    <div class="col-12 col-md-4">
                        <fg-input type="text" label="Phone" placeholder="Phone" v-model="formInputs.Employee.phone"></fg-input>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="false" class="row">
            <div class="col-4 text-center">
                <label>Status</label><br>
                <n-switch v-model="Status" type="primary" on-text="Active" off-text="Inactive" color="green"></n-switch>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <button class="btn btn-primary">{{ AddEdit }} User{{ UserID ? ' #'+UserID : '' }}</button>
            </div>
        </div>

    </form>
</template>
<script>
import { ElOption, ElSelect } from "element-plus";
import Multiselect from '@vueform/multiselect';
import { Switch } from "src/components/index";

// import apiEmployees from 'src/Modules/Users/api/employees';
import apiUsers from 'src/Modules/Users/api/users';

let FormInputs = () => {
    return {
        Role: '',
        User: {
            username: '',
            email: '',
            status: 1,
        },
        Employee: {
            first_name: '',
            last_name: '',
            phone: ''
        },
        Sites: []
    };
};

export default {
    components: {
        [Switch.name]: Switch,
        [ElOption.name]: ElOption,
        [ElSelect.name]: ElSelect,
        Multiselect
    },
    data() {
        return {
            formInputs: FormInputs(),
            Status: false,
            Error: ''
        };
    },
    props: {
        UserID: {
            type: Number,
            default: 0
        },
        InsertNew: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        AddEdit() {
            // this.SetFormInputs();
            return this.InsertNew ? 'Insert New' : 'Update';
        },
        Roles() {
            return this.$store.getters.UsersGetUserAllowedRoles();
        },
        User() {
            return this.UserID ? this.$store.getters.UsersGetByID(this.UserID) : '';
        },
        Employee() {
            return '';
            // return this.User.Roles[0].level === 3 ? this.$store.getters.UsersGetEmployeeByUserID(this.UserID) : '';
        },
        Active() {
            this.formInputs.User.status = this.Status ? 1 : 0;
            return !!this.Status;
        },
        Sites() {
          return this.$store.getters.UsersGetSites();
        }
    },
    mounted() {
        this.$store.dispatch('User_UpdateSites');
        this.SetFormInputs();
    },
    methods: {
        SetFormInputs() {
            console.log('Setting Form Inputs...');
            this.Error = '';
            this.formInputs = FormInputs();
            console.log({UserID: this.UserID});
            if (this.UserID)
            {
                this.formInputs.User = {
                    username: this.User.username ? this.User.username : '',
                    email: this.User.email ? this.User.email : '',
                    status: this.User.status ? 1 : 0,
                };
                this.formInputs.Role = typeof this.User.Roles[0] !== 'undefined' ? this.User.Roles[0].level : '';
                if (this.formInputs.Role === 3 && this.Employee)
                {
                    this.formInputs.Employee = {
                        first_name: this.Employee.first_name ? this.Employee.first_name : '',
                        last_name: this.Employee.last_name ? this.Employee.last_name : '',
                        phone: this.Employee.phone ? this.Employee.phone : '',
                    }
                }
                this.formInputs.Sites = this.User.Memberships.map(Site => Site.users_sites_id);
            }
        },
        save() {
            this.Error = '';
            this.formInputs.User.status = this.formInputs.User.status ? 1 : 0;
            if (this.InsertNew)
            {
                apiUsers.Add(this.formInputs).then(res => {
                    this.$store.dispatch('Users_AddUser', res.data);
                    if (this.formInputs.Role === 3) { this.$store.dispatch('Users_AddEmployee', res.data.Employee); }
                    this.$emit('CloseUserModal');
                }).catch(e => {
                    console.log('Error: ', e.response.data);
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
            else
            {
                apiUsers.Update(this.UserID, this.formInputs).then(res => {
                    console.log({res});
                    this.$store.dispatch('Users_UpdateUser', res.data);
                    if (this.formInputs.Role === 3) { this.$store.dispatch('Users_AddUpdateEmployee', res.data.Employee); }
                    this.$emit('CloseUserModal');
                }).catch(e => {
                    console.log({e});
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
        },
        processErrors(Errors, Status) {
            let i = 0;
            if (Status === 422)
            {
                for (let x in Errors)
                {
                    this.Error += (i?'<br>':'')+Errors[x].join('<br>');
                    i++;
                }
            }
        }
    },
    watch: {
        UserID() {
            this.SetFormInputs();
        }
    }
};
</script>
<style>
.el-select__popper { z-index: 20000 !important; }
</style>
