import DashboardLayout from "src/Modules/Users/Layouts/DashboardLayout.vue";

// Page Headers
import DefaultHeader from "src/Modules/Users/Pages/DefaultHeader";

// Pages
import Weights from 'src/Modules/Health/Pages/Weights';
// import Progress from './Pages/Progress';

const routes = [
    {
        path: '/Health/Weight',
        component: DashboardLayout,
        children: [
            {
                path: '',
                name: 'Weight',
                components: { default: Weights, header: DefaultHeader },
                meta: { authOnly: true }
            },
            /*{
                path: '/Actions/Progress',
                name: 'Actions Progress',
                components: { default: Progress, header: DefaultHeader },
                // meta: { authOnly: true }
            },*/
        ]
    },
];

export default routes;
