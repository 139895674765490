import Api from 'src/api/API';

export default {
    Index(query) {
        query = query ? '?search='+query : '';
        return Api.get('/Auth/Health/Recipes'+query);
    },
    Find(RecipeID) {
        return Api.get('/Auth/Health/Recipes/'+RecipeID);
    },
    Add(data) {
        return Api.post('/Auth/Health/Recipes', data);
    },
    Update(RecipeID, data) {
        return Api.patch('/Auth/Health/Recipes/'+RecipeID, data);
    },
    Delete(RecipeID) {
        return Api.delete('/Auth/Health/Recipes/'+RecipeID);
    }
};
