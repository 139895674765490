<template>
    <div class="Page">

      <h1>Progress</h1>

      <card>
        <template v-slot:header>
          <div>
              <font-awesome-icon icon="plus" title="Add" class="btn btn-primary pull-right" @click="AddTracker" />
              <h2 class="card-title">Add Tracker</h2>
          </div>
        </template>
        <div class="row">
            <div class="col-12 col-md-3">
                <label>Action</label>
                <Multiselect
                    v-model="formInputs.selectedAction"
                    placeholder="Select your Action"
                    :options="Actions"
                    noOptionsText="Start typing to search your actions"
                    noResultsText="Looks like you are out of options. Maybe you misspelled it?"
                    :searchable="true"
                />
            </div>
            <div class="col-12 col-md-3">
                <label>Desired Measurement</label>
                <fg-input type="number" placeholder="Desired Measurement" v-model:value="formInputs.desiredMeasurement"></fg-input>
            </div>
            <div class="col-12 col-md-3">
                <label>Desired Measurement Per</label>
                <Multiselect
                    v-model="formInputs.desiredMeasurementPer"
                    placeholder="Select your Frequency"
                    :options="MeasurementsPer"
                    noOptionsText="Start typing to search your actions"
                    noResultsText="Looks like you are out of options. Maybe you misspelled it?"
                    :searchable="true"
                />
            </div>
            <div class="col-12 col-md-3">
                <label>Desired Time of Day</label>
                <el-time-picker placeholder="Time Picker" v-model:model-value="desiredActionTime" @change="TimeChange" format="h:mm a"></el-time-picker>
            </div>
        </div>
      </card>

        <ProgressTrack v-for="(Track, k) in Trackers" :key="'ProgressTrack'+k" :TrackInfo="Track"></ProgressTrack>
    </div>
</template>
<script>
import { ElDatePicker, ElTimePicker } from "element-plus";
import { format } from "date-fns";
import Multiselect from '@vueform/multiselect';
import FgInput from "@/components/Inputs/FormGroupInput";
import ProgressTrack from "src/Modules/Actions/Components/ProgressTrack";

export default {
    name: 'Actions Progress',
    components: {
        FgInput,
        [ElDatePicker.name]: ElDatePicker,
        [ElTimePicker.name]: ElTimePicker,
        Multiselect,
        ProgressTrack
    },
    data() {
        return {
            formInputs: {
                selectedAction: '',
                selectedTrackType: '',
                desiredActionTime: format(new Date(), 'HH:mm:ss'),
                desiredMeasurement: '',
                desiredMeasurementPer: '',
            },
            desiredActionTime: '',
            MeasurementsPer: ['Day', 'Week', 'Month', 'Quarter', 'Year'],
            Trackers: []
        };
    },
    computed: {
        Actions() {
            return this.$store.getters.Actions_GetDistinct();
        },
        UserID() {
            return this.$store.state.Users.id;
        },
    },
    mounted() {
        this.$store.dispatch('Actions_UpdateDistinct');
    },
    methods: {
        handleView(index, row) {
            console.log({index, row});
        },
        handleEdit(index, row) {
            console.log({index, row});
        },
        handleDelete(index, row) {
            console.log({index, row});
        },
        TimeChange(Time) {
            this.formInputs.desiredActionTime = format(Time, 'HH:mm:00');
        },
        AddTracker() {
            this.Trackers.push(this.formInputs);
        }
    }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
