<template>
  <div class="panel-header">
    <div class="header text-center">
      <h2 class="title">Vector Map</h2>
      <p class="category">
        Looks great on any resolution. Made by our friends from
        <a target="_blank" rel="noopener" href="http://datamaps.github.io"
          >Data Maps</a
        >.
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
