import apiRecipes from '../api/recipes';

let DefaultState = () => {
    return {
        Recipes: [],
        Specific: {},
        SpecificLoading: false,
        Pagination: {
            total: 0,
            current_page: 1,
            per_page: 20,
            last_page: 1,
        }
    };
};

export default {
    state: DefaultState(),
    getters: {
        Recipes_GetAll: state => () => {
            return state.Recipes;
        },
        Recipes_GetPagination: state => () => {
            return state.Pagination;
        },
        Recipes_GetByID: state => (RecipeID) => {
            let Recipe = state.Specific;
            if (typeof Recipe.id === 'undefined' || Recipe.id !== RecipeID) { Recipe = state.Recipes.find(R => R.id === RecipeID); }
            return Recipe;
        },
        Recipes_GetSpecificLoading: state => () => {
            return state.SpecificLoading;
        },
        Recipes_GetSpecific: state => () => {
            return state.Specific;
        }
    },
    mutations: {
        RECIPES_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        RECIPES_ADD(state, Recipe) {
            state.Recipes.push(Recipe);
        },
        RECIPES_UPDATE(state, Recipe) {
            for (let x in state.Recipes) {
                if (state.Recipes[x].id === Recipe.id) {
                    state.Recipes[x] = Recipe;
                }
            }
        },
        RECIPES_REMOVE(state, RecipeID) {
            for (let x in state.Recipes) { if (state.Recipes[x].id === RecipeID) { state.Recipes.splice(x, 1); } }
        },
        RECIPES_UPDATE_ALL(state, Recipes) {
            state.Recipes = Recipes.data.map(Recipe => {
                return Recipe;
            });
            state.Pagination = {
                total: Recipes.total,
                current_page: Recipes.current_page,
                per_page: Recipes.per_page,
                last_page: Recipes.last_page,
            };
        },
        RECIPES_UPDATE_SPECIFIC(state, Recipe) {
            state.Specific = Recipe;
            state.SpecificLoading = false;
        }
    },
    actions: {
        Recipes_ResetState({commit}) {
            commit('RECIPES_RESET_STATE');
        },
        Recipes_Add({commit}, Recipe) {
            commit('RECIPES_ADD', Recipe);
        },
        Recipes_Update({commit}, Recipe) {
            commit('RECIPES_UPDATE', Recipe);
        },
        Recipes_Remove({commit}, RecipeID) {
            apiRecipes.Delete(RecipeID).then(res => {
                if (res.status === 200) { commit('RECIPES_REMOVE', RecipeID); }
            });
        },
        Recipes_UpdateAll({commit}) {
            apiRecipes.Index().then(res => {
                if (typeof res.data.data !== 'undefined') { commit('RECIPES_UPDATE_ALL', res.data); }
            });
        },
        Recipes_Find({commit, state}, RecipeID) {
            if (typeof RecipeID === 'undefined') { return commit('RECIPES_UPDATE_SPECIFIC', {}); }
            state.SpecificLoading = true;
            apiRecipes.Find(RecipeID).then(res => {
                if (typeof res.data !== 'undefined') { commit('RECIPES_UPDATE_SPECIFIC', res.data); }
            });
        },
        Recipes_Search({commit, state}, query) {
            apiRecipes.Index(query).then(res => {
                if (typeof res.data !== 'undefined') { commit('RECIPES_UPDATE_ALL', res.data); }
            });
        }
    }
};
