import Api from 'src/api/API';

export default {
    Index() {
        return Api.get('/Auth/Health/Weight');
    },
    Add(data) {
        return Api.post('/Auth/Health/Weight', data);
    },
    Update(WeightID, data) {
        return Api.patch('/Auth/Health/Weight/'+WeightID, data);
    },
    Delete(WeightID) {
        return Api.delete('/Auth/Health/Weight/'+WeightID);
    },

    FindByUserID(UserID) {
        return Api.get('/Auth/Health/Weight/User/'+UserID);
    }
};
