<template>
    <form @submit.prevent="save">

        <div v-if="Error" class="alert alert-danger" v-html="Error"></div>

        <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
                <fg-input type="number" step=".1" label="Weight" placeholder="Weight" v-model:value="formInputs.weight"></fg-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <fg-input type="number" step=".1" label="Fat" placeholder="Fat" v-model:value="formInputs.fat"></fg-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <fg-input type="number" step=".1" label="Muscle" placeholder="Muscle" v-model:value="formInputs.muscle"></fg-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <fg-input type="number" step=".1" label="Water" placeholder="Water" v-model:value="formInputs.water"></fg-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <fg-input type="number" step=".1" label="Bone" placeholder="Bone" v-model:value="formInputs.bone"></fg-input>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <label>Taken At</label>
                <el-date-picker
                    type="datetime"
                    v-model:model-value="formInputs.measured_time"
                    format="MM/DD/YYYY h:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Taken At">
                </el-date-picker>
            </div>
        </div>

        <div class="row">
            <Images divClasses="col-12 text-center mt-3" v-model:Images="formInputs.images" @update:Images="UpdateImages"></Images>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <button class="btn btn-primary">{{ AddEdit }} Weight{{ WeightID ? ' #'+WeightID : '' }}</button>
            </div>
        </div>

    </form>
</template>
<script>
import { ElDatePicker } from "element-plus";
import { format } from "date-fns";
import Images from "@/components/Inputs/Images";

import apiWeights from '@/Modules/Health/api/weight';

let FormInputs = () => {
    return {
        users_id: 0,
        weight: '',
        fat: '',
        muscle: '',
        water: '',
        bone: '',
        measured_time: format(new Date(), 'yyyy-MM-dd HH:mm'),
        images: []
    };
};

export default {
    components: {
        [ElDatePicker.name]: ElDatePicker,
        Images
    },
    data() {
        return {
            formInputs: FormInputs(),
            Error: '',
            options: [],
            NewOptionKey: 0
        };
    },
    props: {
        Weight: {
            type: Object,
            default: {}
        },
        InsertNew: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        AddEdit() {
            this.SetFormInputs();
            return this.InsertNew ? 'Insert New' : 'Update';
        },
        WeightID() {
            return typeof this.Weight.id !== 'undefined' ? this.Weight.id : 0;
        },
        UserID() {
            return this.$store.state.Users.id;
        }
    },
    mounted() {
        this.SetFormInputs();
    },
    methods: {
        UpdateImages(Images) {
            this.formInputs.images = Images;
        },
        SetFormInputs() {
            this.Error = '';
            this.formInputs = this.WeightID ? this.Weight : FormInputs();
            this.formInputs.users_id = this.UserID;
        },
        save() {
            this.Error = '';
            if (this.InsertNew)
            {
                apiWeights.Add(this.formInputs).then(res => {
                    console.log({res});
                    this.$store.dispatch('BodyWeights_AddWeight', res.data);
                    this.$emit('CloseWeightModal');
                    this.formInputs = FormInputs();
                }).catch(e => {
                    console.log('Error: ', e.response.data);
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
            else
            {
                apiWeights.Update(this.Weight.id, this.formInputs).then(res => {
                    this.$store.dispatch('BodyWeights_UpdateWeight', res.data);
                    this.$emit('CloseWeightModal');
                    this.formInputs = FormInputs();
                }).catch(e => {
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
        },
        processErrors(Errors, Status) {
            let i = 0;
            if (Status === 422)
            {
                for (let x in Errors)
                {
                    this.Error += (i?'<br>':'')+Errors[x].join('<br>');
                    i++;
                }
            }
        }
    }
};
</script>
