import DashboardLayout from "src/Modules/Users/Layouts/DashboardLayout.vue";

// Page Headers
import DefaultHeader from "src/Modules/Users/Pages/DefaultHeader";

// Pages
import Sizes from 'src/Modules/Health/Pages/Sizes';
// import Progress from './Pages/Progress';

const routes = [
    {
        path: '/Health/Size',
        component: DashboardLayout,
        children: [
            {
                path: '',
                name: 'Size',
                components: { default: Sizes, header: DefaultHeader },
                meta: { authOnly: true }
            },
            /*{
                path: '/Actions/Progress',
                name: 'Actions Progress',
                components: { default: Progress, header: DefaultHeader },
                // meta: { authOnly: true }
            },*/
        ]
    },
];

export default routes;
