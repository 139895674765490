<template>
    <div class="Page">

      <font-awesome-icon class="btn btn-link pull-right Refresh" :class="{loading: SubjectsLoading}" icon="arrows-rotate" @click="getSubjects" />
      <h1>{{ Course.title }} / Subjects</h1>

      <el-table stripe style="width: 100%" :data="Subjects">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :width="column.width"
            :prop="column.prop"
            :formatter="TableDateFormatter"
            :label="column.label"
        >
        </el-table-column>
        <el-table-column :width="100" fixed="right" label="View">
          <template v-slot:default="props">
            <div class="table-actions">
              <n-button
                  @click="$router.push({name: 'Contents', params: {SubjectID: props.row.id}})"
                  :class="{like: true, disabled: SubjectIsDisabled(props.row)}"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

    </div>
</template>
<script>
import { ElTable, ElTableColumn, ElDatePicker } from "element-plus";
import { format } from "date-fns";

export default {
    name: 'Course Subjects',
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn
    },
    data() {
        return {
            selectedTags: [],
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'sequence',
                    label: 'Sequence',
                    minWidth: 150,
                    width: 150,
                },
                {
                    prop: 'title',
                    label: 'Title',
                    minWidth: 200,
                    width: '',
                }
            ]
        };
    },
    computed: {
        Course() {
            return this.$store.getters.Courses_GetByID(this.$route.params.CourseID);
        },
        Subjects() {
            return this.$store.getters.CoursesSubjects_GetAll();
        },
        SubjectsLoading() {
            return this.$store.state.Courses.CoursesLoading;
        },
        SubjectsCompleted() {
            return this.$store.getters.CoursesCompleted_Subjects();
        },
        SubjectIDsCompleted() {
          return this.SubjectsCompleted.map(Complete => { return Complete.subjects_id; });
        },
        UserID() {
            let ID = this.$store.state.Users.id;
            if (typeof ID !== 'undefined' && ID) { this.$store.dispatch('CoursesCompleted_GetByUserID', ID); }
            return ID;
        }
    },
    mounted() {
        this.getSubjects();
    },
    methods: {
        getSubjects() {
            this.$store.dispatch('Courses_UpdateAll');
        },
        TableDateFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'created_at':
                    Data = format(new Date(row.created_at), 'M/d/yyyy h:mm a');
                break;
                case 'updated_at':
                    Data = format(new Date(row.updated_at), 'M/d/yyyy h:mm a');
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        SubjectIsDisabled(Subject) {
            let scIndex = this.SubjectIDsCompleted.indexOf(Subject.id);
            if (scIndex > -1 || Subject.sequence === 1) { return false; }
            let iSubject = this.Subjects.indexOf(Subject);
            return this.SubjectIDsCompleted.indexOf(this.Subjects[iSubject - 1].id) < 0;
        }
    }
};
</script>
