<template>
  <div class="col-md-4 ml-auto mr-auto">
    <h5 class="mt-5">Reset password for:<br>{{email}}</h5>
    <div v-if="SuccessMessage" class="alert alert-success">Your password has been successfully updated!</div>
    <div v-if="Error" class="alert alert-danger">{{ Error }}</div>
    <form v-if="!SuccessMessage" @submit.prevent="onSubmit">
      <card class="card-plain">
        <template v-slot:header>
          <div class="logo-container">
            <img src="img/favicon.svg" alt="" />
          </div>
        </template>

        <vee-input
            name="password"
            type="password"
            inputClasses="no-border form-control-lg"
            label="New Password"
            placeholder="New Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
        />

        <vee-input
            name="password_confirmation"
            type="password"
            inputClasses="no-border form-control-lg"
            label="Confirm New Password"
            placeholder="Confirm New Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
        />

        <template v-slot:footer>
          <div>
            <n-button native-type="submit" type="primary" block>Reset Password</n-button>
            <div class="text-center">
              <router-link class="link" to="/Login">Login</router-link>
            </div>
          </div>
        </template>

      </card>
    </form>
  </div>
</template>
<script>
import { VeeInput } from "@/components";
import { useForm } from "vee-validate";
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import * as Yup from "yup";
import apiUsers from '../../api/users';

/*extend("confirmed", {...confirmed, message: '{_field_} does not match the Password field'});
extend("email", {...email, message: '{_field_} must be a valid email address'});
extend("required", {...required, message: '{_field_} is required'});*/

export default {
    name: "ResetPassword",
    components: {
        VeeInput,
    },
    setup() {
      const router = useRouter()
      const route = useRoute()
      const store = useStore();

      const email = route.query.email.replace('%20', '+').replace(' ', '+');
      const password = ref('');
      const password_confirmation = ref('');

      let SuccessMessage = ref(false);
      let Error = ref('');
      const schema = Yup.object().shape({
        password: Yup.string().required().label("The Password"),
        password_confirmation: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match').label("The Password Confirmation"),
      });
      const { handleSubmit, isSubmitting, setFieldError } = useForm({ validationSchema: schema });
      const onSubmit = handleSubmit (async values => {
        SuccessMessage.value = false;
        Error.value = '';
        console.log('The form is valid', values);
        let Data = {
          token: route.query.token,
          email,
          ...values
        };
        apiUsers.resetPassword(Data).then((response) => {
          console.log(response);
          store.dispatch('SetMessage', {Type: 'Success', Message: 'Your password has been successfully updated! Login below.'});
          SuccessMessage.value = true;
          router.push({name: 'Login'});
        }).catch(error => {
          console.log(error);
          let ErrorCodes = [400, 500];
          if (ErrorCodes.indexOf(error.response.status) > -1) { Error.value = 'Something went wrong. Try again later.'; }
          if (error.response.status === 422) {
            for (let x in error.response.data.errors) {
              if (x === 'email') { Error.value = error.response.data.errors[x][0]; }
              else { setFieldError(x, error.response.data.errors[x][0]); }
            }
          }
        });
      });
        return {
            SuccessMessage,
            Error,
            onSubmit,
            isSubmitting,
            email,
            password,
            password_confirmation
        };
    },
};
</script>
<style lang="scss">
.resetpassword-page
{
  h5 { color: #9A9A9A; }
  .logo-container { width: 65px; margin: 0 auto 55px; }
  .LoginLink { font-weight: bold; text-decoration: underline; color: #000000; }
}
</style>