<template>
  <section id="pageContent" class="Page" :ref="$route.params.ContentID">
    <header>
      <span class="btn btn-prev entities pull-right" title="back" @click="$router.push({name: 'Contents'})">&leftarrow;</span>
      <h1>{{ Course.title }} / {{ Subject.title }}</h1>
    </header>
    <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <card headerBGColor="orange" :title="Content.title">
        <div v-html="Content.content"></div>
        <div class="text-center">
          <router-link :to="Prev" class="btn btn-primary" v-if="typeof Prev.params !== 'undefined'">Prev</router-link>
          <span @click="Go" class="btn btn-primary">Next</span>
        </div>
      </card>
    </div>
  </section>
</template>
<script>
import { Notification } from "src/components";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, onUpdated, watch } from 'vue';
import { useToast } from 'vue-toastification';

export default {
    name: "Content",
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        // Course Info
        let CourseID = computed(() => typeof route.params.CourseID === 'undefined' ? 0 : parseInt(route.params.CourseID));
        let Course = computed(() => {
            let ThisCourse = store.getters.Courses_GetByID(CourseID.value);
            if (ThisCourse.id === 0) { store.dispatch('Courses_GetByID', CourseID.value); }
            return ThisCourse;
        });

        // Subject Info
        let SubjectID = computed(() => typeof route.params.SubjectID === 'undefined' ? 0 : parseInt(route.params.SubjectID));
        let Subject = computed(() => {
            return store.getters.CoursesSubjects_GetByID(SubjectID.value);
        });
        let Subjects = computed(() => {
            return store.getters.CoursesSubjects_GetAll();
        });

        // Content Info
        let ContentID = computed(() => typeof route.params.ContentID === 'undefined' ? 0 : parseInt(route.params.ContentID));
        let Content = computed(() => {
            let ThisContent = store.getters.CoursesSubjectsContents_GetByID(ContentID.value);
            if (ThisContent.id === 0) { store.dispatch('CoursesSubjectsContents_UpdateAll', { CourseID: CourseID.value, SubjectID: SubjectID.value }); }
            return ThisContent;
        });

        // Get the course completed info for the user from the db
        let Completed = computed(() => { return store.getters.CoursesCompleted_Contents(); });
        let UserID = computed(() => {
            let ID = store.state.Users.id;
            if (typeof ID !== 'undefined' && ID && !Completed.value.length) { store.dispatch('CoursesCompleted_GetByUserID', ID); }
            return ID;
        });
        watch(UserID, () => { console.log({WatchedUserID: UserID.value}); });

        // Let's check if the user is trying to skip content
        let Contents = computed(() => { return store.getters.CoursesSubjectsContents_GetAll(); });
        let CheckForSkipAttempt = () => {
            if (Content.value.sequence > 1)
            {
                let prevContent = Contents.value.find(Cont => Cont.sequence === Content.value.sequence - 1);
                let prevCompleted = Completed.value.find(Comp => Comp.contents_id === prevContent.id);
                if (typeof prevCompleted === 'undefined')
                {
                    runToast('Whoa! The content builds on each other. Try not to skip anything.', 'danger');
                    router.push({name: 'Contents'});
                }
            }
        };
        let AllFetched = computed(() => store.state.Courses.CompletedFetched && store.state.Courses.ContentsFetched);
        watch(AllFetched, (currentValue, oldValue) => { return CheckForSkipAttempt(); });

        // Previous Params
        let Prev = computed(() => {
            let Return = { name: 'Content' };
            if (ContentID.value && Content.value.id && Subject.value.id && Content.value.sequence !== 0) {
                let prevContent = store.getters.CoursesSubjectsContents_GetPrevContent(Content.value.sequence);
                if (typeof prevContent !== 'undefined') { Return.params = { ContentID: prevContent.id }; }
            }
            return Return;
        });

        // Next Params
        let Next = computed(() => {
            let Return = { name: Content.value.sequence === Subject.value.contents_count ? 'Subjects' : 'Content' };
            if (ContentID.value && Content.value.id && Subject.value.id && Content.value.sequence !== Subject.value.contents_count) {
                let nextContent = store.getters.CoursesSubjectsContents_GetNextContent(Content.value.sequence);
                if (typeof nextContent !== 'undefined') { Return.params = { ContentID: nextContent.id }; }
            }
            return Return;
        });

        // Let's Go!
        let Go = ref(() => {
            let LetsGo = Next.value;
            let CompletedData = { users_id: UserID.value, courses_id: CourseID.value, subjects_id: SubjectID.value, contents_id: ContentID.value };
            store.dispatch('CoursesCompleted_Add', CompletedData);
            if (Next.value.name === 'Subjects')
            {
                CompletedData.contents_id = 0;
                store.dispatch('CoursesCompleted_Add', CompletedData);
                runToast('You have completed the subject: '+Subject.value.title+'!', 'success');
                // Check if the user has completed the course
                if (Subject.value.id === Subjects.value[Subjects.value.length-1].id)
                {
                    CompletedData.subjects_id = 0;
                    store.dispatch('CoursesCompleted_Add', CompletedData);
                    if (CourseID.value === 1) { LetsGo = { name: 'Course Completed' }; }
                }
            }
            router.push(LetsGo);
        });

        // Toasty!
        const toast = useToast();
        const runToast = (text, type) => {
            const icon = "now-ui-icons ui-1_bell-53";
            const content = {
                component: Notification,
                props: { icon, text, type },
            };
            toast(content, {
                hideProgressBar: true,
                icon: false,
                closeButton: false,
                position: 'top-right',
            });
        };

        onUpdated(() => { window.scrollTo(0,0) });

        return {
            Course,
            Subject,
            Content,
            Prev,
            Go
        };
    },
};
</script>