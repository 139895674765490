<template>
    <div class="Page">

      <span class="btn btn-prev entities pull-right" title="back" @click="$router.push({name: 'Subjects'})">&leftarrow;</span>
      <font-awesome-icon class="btn btn-link pull-right Refresh" :class="{loading: ContentsLoading}" icon="arrows-rotate" @click="getContents" />
      <h1>{{ Course.title }} / {{ Subject.title }} / Contents</h1>

      <el-table stripe style="width: 100%" :data="Contents">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :width="column.width"
            :prop="column.prop"
            :formatter="TableDateFormatter"
            :label="column.label"
        >
        </el-table-column>
        <el-table-column :width="100" fixed="right" label="View">
          <template v-slot:default="props">
            <div class="table-actions">
              <n-button
                  @click="$router.push({name: 'Content', params: {CourseID: Course.id, SubjectID: Subject.id, ContentID: props.row.id}})"
                  :class="{like: true, disabled: ContentIsDisabled(props.row)}"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

    </div>
</template>
<script>
import { ElTable, ElTableColumn, ElDatePicker } from "element-plus";
import { format } from "date-fns";
import { Notification } from "@/components";
import { useToast } from "vue-toastification";

export default {
    name: 'Contents',
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn
    },
    data() {
        return {
            selectedTags: [],
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'sequence',
                    label: 'Sequence',
                    minWidth: 150,
                    width: 150,
                },
                {
                    prop: 'title',
                    label: 'Title',
                    minWidth: 200,
                    width: '',
                }
            ]
        };
    },
    computed: {
        Course() {
            return this.$store.getters.Courses_GetByID(this.$route.params.CourseID);
        },
        Subjects() {
            return this.$store.getters.CoursesSubjects_GetAll();
        },
        Subject() {
            return this.$store.getters.CoursesSubjects_GetByID(this.$route.params.SubjectID);
        },
        AllFetched() {
            return this.$store.state.Courses.CompletedFetched && this.$store.state.Courses.CoursesFetched;
        },
        Contents() {
            return this.$store.getters.CoursesSubjectsContents_GetAll();
        },
        ContentsLoading() {
            return this.$store.state.Courses.ContentsLoading;
        },
        ContentsCompleted() {
            return this.$store.getters.CoursesCompleted_Contents();
        },
        ContentIDsCompleted() {
          return this.ContentsCompleted.map(Complete => { return Complete.contents_id; });
        },
        UserID() {
            let ID = this.$store.state.Users.id;
            if (typeof ID !== 'undefined' && ID) { this.$store.dispatch('CoursesCompleted_GetByUserID', ID); }
            return ID;
        }
    },
    mounted() {
        this.getContents();
        this.getCourse();
    },
    methods: {
        getContents() {
            this.$store.dispatch('CoursesSubjectsContents_UpdateAll', {CourseID: this.$route.params.CourseID, SubjectID: this.$route.params.SubjectID});
        },
        getCourse() {
            this.$store.dispatch('Courses_GetByID', this.$route.params.CourseID);
        },
        TableDateFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'created_at':
                    Data = format(new Date(row.created_at), 'M/d/yyyy h:mm a');
                break;
                case 'updated_at':
                    Data = format(new Date(row.updated_at), 'M/d/yyyy h:mm a');
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        ContentIsDisabled(Content) {
            let ccIndex = this.ContentIDsCompleted.indexOf(Content.id);
            if (ccIndex > -1 || Content.sequence === 1) { return false; }
            let iContent = this.Contents.indexOf(Content);
            return this.ContentIDsCompleted.indexOf(this.Contents[iContent - 1].id) < 0;
        },
        CheckForSkipAttempt() {
            let Completed = this.$store.getters.CoursesCompleted_Subjects();
            if (this.AllFetched && this.Subject.sequence > 1)
            {
                let prevContent = this.Subjects.find(Subj => Subj.sequence === this.Subject.sequence - 1);
                let prevCompleted = Completed.find(Comp => Comp.subjects_id === prevContent.subjects_id);
                if (typeof prevCompleted === 'undefined')
                {
                    this.runToast('Whoa! The subjects build on each other. Try not to skip any.', 'danger');
                    this.$router.push({name: 'Subjects'});
                }
            }
        },
        runToast(text, type) {
            const icon = "now-ui-icons ui-1_bell-53";
            const content = {
                component: Notification,
                props: { icon, text, type },
            };
            const toast = useToast();
            toast(content, {
                hideProgressBar: true,
                icon: false,
                closeButton: false,
                position: 'top-right',
            });
        }
    },
    watch: {
        AllFetched(vCurr, vOld) { if (vCurr) { this.CheckForSkipAttempt(); } }
    }
};
</script>
