<template>
    <div class="Action">

        <div class="row">
            <div class="col-12 col-md-4 checkbox-radios">
                <label>Unit of Measurement</label>
                <div>{{ UnitOfMeasurement }}</div>
            </div>
            <div class="col-12 col-md-4" v-if="[5,6].indexOf(Action.unit_of_measurement)===-1">
                <label>{{ labelUnitOfMeasurement }}</label>
                <div>{{ Action.measurement }}</div>
            </div>
            <div class="col-12 col-md-4" v-else>
                <label>{{ labelDesiredTime }}</label>
                <div>{{ DesiredTime }}</div>
            </div>
            <div class="col-12 col-md-4">
                <label>{{ Action.is_timed ? 'Start Time' : 'Start / Stop' }}</label>
                <checkbox id="StartStopLabel"
                          v-if="ActionTimeLabel === 'Stop Time'"
                          v-model:model-value="ActionIsTimed"
                          :inline="true"
                          class="mt-0 pull-right"
                          disabled="disabled"
                >Timer</checkbox><br>
                <div v-if="ActionTimeLabel === 'Stop Time'">{{ Dates.Start }}</div>
                <checkbox v-else id="StartStop" v-model:model-value="ActionIsTimed" :inline="true" disabled="disabled">Timer</checkbox>
            </div>
            <div class="col-12 col-md-4 checkbox-radios">
                <label>{{ ActionTimeLabel }}</label>
                <div v-if="ActionTimeLabel === 'Action Time'">{{ Dates.Action }}</div>
                <div v-if="ActionTimeLabel === 'Start Time'">{{ Dates.Start }}</div>
                <div v-if="ActionTimeLabel === 'Stop Time'">{{ Dates.Stop }}</div>
            </div>
            <div class="col-12 col-md-4 Tags">
                <label>Tags</label>
                <span v-for="Tag in Action.tags" :key="'TagsKey'+Tag.id" class="btn btn-info">{{ Tag.title }}</span>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-3">
                <label>Description</label><br>
                <div v-html="Action.description" ref="DScription" @click.prevent="DScriptionClick"></div>
            </div>
        </div>

        <div class="row Images">
            <label class="col-12">Images</label>
            <div v-for="Img in Action.images" class="col-12 col-md-6 col-lg-3">
                <img :src="Img">
            </div>
        </div>

    </div>
</template>
<script>
import { format, parseISO } from "date-fns";
import { Checkbox, Radio } from "@/components/index";
import Images from "@/components/Inputs/Images";

export default {
    components: {
        Checkbox, Radio,
        Images
    },
    data() {
        return {
            Now: new Date()
        };
    },
    props: {
        ActionID: {
            type: Number,
            default: 0
        }
    },
    computed: {
        Action() {
            return this.ActionID ? this.$store.getters.Actions_GetByID(this.ActionID) : '';
        },
        UserID() {
            return this.$store.state.Users.id;
        },
        Tags() {
            return this.$store.getters.Tags_GetOptions();
        },
        ActionTimeLabel() {
            return this.Action.is_timed ? 'Stop Time' : 'Action Time';
        },
        MeasurementUnits() {
            return this.$store.state.Actions.MeasurementUnits.map(Unit => {
                return {
                    value: Unit.id,
                    label: Unit.name
                };
            });
        },
        UnitOfMeasurement() {
            let Option = this.MeasurementUnits.filter(Unit => Unit.value === this.Action.unit_of_measurement);
            return Option.length ? Option[0].label : '';
        },
        labelUnitOfMeasurement() {
            return this.UnitOfMeasurement.length ? 'Measurement ('+this.UnitOfMeasurement+')' : 'Measurement';
        },
        labelDesiredTime() {
            return this.Action.unit_of_measurement === 5 ? 'Desired Time of Day' : 'Desired Time (Hours:Minutes:Seconds)';
        },
        formatDesiredTime() {
            return this.Action.unit_of_measurement === 5 ? 'h:mm a' : 'HH:mm:ss';
        },
        ActionIsTimed() {
            return !!this.Action.is_timed;
        },
        DesiredTime() {
            let Action = this.Action;
            let Now = this.Now;
            return Action.desired_time ? format(new Date(Now.getFullYear()+'-'+(Now.getMonth()+1)+'-'+Now.getDate()+' '+Action.desired_time), this.formatDesiredTime) : null;
        },
        Dates() {
            let Action = this.Action;
            let Now = format(this.Now, 'M/d/yyyy h:mm a');
            return {
                Action: Action.action_time ? format(new Date(Action.action_time), 'M/d/yyyy h:mm a') : null,
                Start: Action.start ? format(new Date(Action.start), 'M/d/yyyy h:mm a') : (Action.start || Action.is_timed ? Now : null),
                Stop: Action.stop ? format(new Date(Action.stop), 'M/d/yyyy h:mm a') : (Action.start ? Now : null)
            }
        }
    },
    mounted() {
        this.$store.dispatch('Actions_GetMeasurementUnits');
        this.$store.dispatch('Tags_UpdateAll');
    },
    methods: {
        DScriptionClick(Ele) {
            let SE = Ele.srcElement;
            if (SE.localName === 'a')
            {
                if (typeof SE.dataset.recipe === 'string') { this.ShowRecipe(SE.dataset.recipe); }
            }
        },
        ShowRecipe(RecipeID) {
            RecipeID = parseInt(RecipeID);
            this.$store.dispatch('Recipes_Find', RecipeID);
        }
    }
};
</script>
<style lang="scss">
.Action {
    label { text-decoration: underline; font-weight: bold; }
    .Tags {
        label { display: block; }
        .btn { margin: 1px; }
    }
    .Images img { margin-bottom: 20px; }
}
</style>
