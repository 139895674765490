<template>
    <section id="pageCompletedCourse" class="Page">
        <header>
          <h1>{{ Course.title }}</h1>
        </header>
        <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
            <card headerBGColor="orange" title="Course Complete">
                <p>
                    Congratulations! You have completed this course! I hope you have found it informative and eye opening. I hope
                    it has also excited you about making some changes in your life to become an extraordinary person (if you are not
                    already extraordinary).
                </p>
                <div v-if="!UserSubscribedToAll">
                    <p>
                        If you have found this information helpful, I want to invite you subscribe to the tools of this website.
                        They will help you apply these things to your life. The tools are only ${{price.monthly}}/month but, if you subscribe for a whole
                        year, you get 2 months off. That comes out to only ${{price.yearly}}/year. That's not too bad for something that can help
                        you change your life.
                    </p>
                    <div class="alert alert-info text-center">Right now there is an early subscriber discount!</div>
                    <p>
                        Right now the tools only include the health module along with an actions element. The actions element helps you keep track of
                        what you do including entering your health habits like meals, exercise, water, etc. This is why I am offering an early subscriber
                        discount at ${{total.monthly}}/month or ${{total.yearly}}/year.
                    </p>
                    <p>
                        The next thing that will be available will be the lists. When the lists become available, I will be increasing the price. However,
                        if you subscribe now, before the lists become available, you will be able to keep your discounted price as long as this subscription
                        is available and your subscription remains active. It might only take a month or two to get the lists module up so, subscribe
                        now and tell your friends and family so they can get the discount too.
                    </p>
                    <div class="col-12 col-lg-10 offset-lg-1">
                        <div v-if="errSubscribe" class="text-center alert alert-danger">{{errSubscribe}}</div>
                        <Collapse>
                            <CollapseItem title="Sign me up on the monthly plan" id="1" class="mb-0">
                                <div class="font">
                                    <p>
                                        Excellent choice! Improving your life is priceless. It's worth way more than ${{price.monthly}}/month.
                                    </p>
                                    <Cart :item="cartMonth" />
                                    <StripeElementsVue interval="month" :disabled="disableSubscribe" :preSubmit="submitSubscribe" />
                                </div>
                            </CollapseItem>
                            <CollapseItem title="Sign me up on the yearly plan" id="2" class="mb-0">
                                <div class="text-left font">
                                    <p>
                                        That's what I'm talking about! Improving your life is priceless. It's worth way more than ${{price.yearly}}/year.
                                    </p>
                                    <Cart :item="cartYear" />
                                    <StripeElementsVue interval="year" :disabled="disableSubscribe" :preSubmit="submitSubscribe" />
                                </div>
                            </CollapseItem>
                        </Collapse>
                    </div>
                </div>
            </card>
        </div>
    </section>
</template>
<script>
import { Notification, Collapse, CollapseItem } from "src/components";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, watch } from 'vue';
import { useToast } from 'vue-toastification';
import StripeElementsVue from 'src/Modules/Users/Components/StripeElements';
import Cart from 'src/Modules/Users/Components/Cart';

import apiSubscriptions from 'src/Modules/Subscriptions/api/subscriptions';

export default {
    name: "Course Completed",
    components: {
        Cart,
        Collapse, CollapseItem,
        StripeElementsVue
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        // Course Info
        let CourseID = computed(() => typeof route.params.CourseID === 'undefined' ? 0 : parseInt(route.params.CourseID));
        let Course = computed(() => {
            let ThisCourse = store.getters.Courses_GetByID(CourseID.value);
            if (ThisCourse.id === 0) { store.dispatch('Courses_GetByID', CourseID.value); }
            return ThisCourse;
        });

        // Get the course completed info for the user from the db
        let UserID = computed(() => {
            let ID = store.state.Users.id;
            if (typeof ID !== 'undefined' && ID && !store.state.Courses.Completed.length) { store.dispatch('CoursesCompleted_GetByUserID', ID); }
            return ID;
        });
        watch(UserID, () => { console.log({WatchedUserID: UserID.value}); });

        // Check if the user is subscribed to the whole tool set
        let Subscriptions = computed(() => {
            if (!store.state.Subscriptions.SubscriptionsFetched) { store.dispatch('Subscriptions_UpdateAll'); }
            return store.getters.Subscriptions_GetAll();
        });
        let UserSubscribedToAll = computed(() => {
            let SubscriptionFound = Subscriptions.value.find(Sub => Sub.title === 'Intended Me');
            return typeof SubscriptionFound !== 'undefined';
        });

        // Let's check if the user is trying to skip content
        let Completed = computed(() => { return store.getters.CoursesCompleted_Courses(); });
        let CheckForSkipAttempt = () => {
            let CourseCompleted = Completed.value.find(Comp => Comp.courses_id === CourseID.value);
            if (typeof CourseCompleted === 'undefined')
            {
                runToast('Whoa! Looks like you may have missed something. Try not to skip anything.', 'danger');
                router.push({name: 'Subjects'});
            }
        };
        let AllFetched = computed(() => store.state.Courses.CompletedFetched && !store.state.Courses.CompletedAdding.length);
        watch(AllFetched, (currentValue, oldValue) => { return CheckForSkipAttempt(); });

        // Stripe Subscriptions
        let errSubscribe = ref('');
        let disableSubscribe = ref(false);
        let submitSubscribe = olStripeElements => {
            disableSubscribe.value = true;
            apiSubscriptions.Create(reg.value.id, {app: 'Intended Me', interval: olStripeElements.interval}).then(async res => {
                olStripeElements.clientSecret = res.data.stripe_client_secret;
                await olStripeElements.confirmPayment();
                disableSubscribe.value = false;
                reg.value.interval = olStripeElements.interval === 'month' ? 1 : 12;
            }).catch(err => {
                console.log({err});
                disableSubscribe.value = false;
                if (err.response.status === 422) {
                    for (let x in err.response.data.errors) { actions.setFieldError(x, err.response.data.errors[x][0]); }
                }// else { errCriterion1.value = 'Something went wrong. Try again later.'; }
            });
        };
        let reg = ref({
            id: 0,
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            register_form: 'Intended Me Program',
            interval: 1
        });
        let price = ref({
            monthly: 20,
            yearly: 200
        });
        let discount = computed(() => {
            return {
                monthly: 10,
                yearly: 100
            };
        });
        let total = computed(() => {
            return {
                monthly: price.value.monthly-discount.value.monthly,
                yearly: price.value.yearly-discount.value.yearly
            };
        });
        let cartMonth = computed(() => {
            return {
                name: reg.value.register_form,
                price: price.value.monthly,
                discount: discount.value.monthly
            };
        });
        let cartYear = computed(() => {
            return {
                name: reg.value.register_form,
                price: price.value.yearly,
                discount: discount.value.yearly
            };
        });

        // Toasty!
        const toast = useToast();
        const runToast = (text, type) => {
            const icon = "now-ui-icons ui-1_bell-53";
            const content = {
                component: Notification,
                props: { icon, text, type },
            };
            toast(content, {
                hideProgressBar: true,
                icon: false,
                closeButton: false,
                position: 'top-right',
            });
        };

        return {
            Course,
            UserSubscribedToAll,
            price, total,
            cartMonth, cartYear,
            disableSubscribe, errSubscribe, submitSubscribe
        };
    },
};
</script>

<style lang="scss">
#pageCompletedCourse
{
    .collapse { background-color: #E1E1E1; }
}
</style>