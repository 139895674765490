<template>
    <div id="Users" class="Page">

        <font-awesome-icon icon="plus" title="Add" class="btn btn-primary pull-right" @click="ShowUserModal"/>
        <h1>Users</h1>

        <el-table stripe :data="Users">
          <el-table-column prop="id" label="ID" width="80"> </el-table-column>
          <el-table-column prop="username" label="Username" min-width="150"> </el-table-column>
          <el-table-column prop="email" label="Email" min-width="150"> </el-table-column>
          <el-table-column label="Role" min-width="100">
            <template v-slot:default="props">
              {{ DisplayRoles(props.row.Roles) }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="Status" min-width="100">
            <template v-slot:default="props">
              {{ props.row.status ? 'Active' : 'Inactive' }}
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="115" class-name="text-center">
            <template v-slot:default="props">
              <font-awesome-icon icon="pencil-alt" class="text-info" @click="ShowUserModal(props.row.id)" title="Edit"/>
              <font-awesome-icon icon="trash-alt" class="text-danger" @click="DeleteUser(props.row.id)" title="Delete"/>
            </template>
          </el-table-column>
        </el-table>

        <!-- Classic Modal -->
        <modal :class="{show:modals.User.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseUserModal">
            <template v-slot:header>
                <h4 class="title title-up">{{ modals.User.title }} User{{ modals.User.UserID ? ' #'+modals.User.UserID : '' }}</h4>
            </template>
            <User :UserID="modals.User.UserID" :InsertNew="modals.User.InsertNew" @CloseUserModal="CloseUserModal"></User>
        </modal>

    </div>
</template>
<script>
import { Modal } from "src/components";
import { ElTable, ElTableColumn } from "element-plus";
import { format } from 'date-fns';

// import apiEmployees from 'src/Modules/Users/api/employees';
import apiUsers from 'src/Modules/Users/api/users';
import User from 'src/Modules/Users/Components/User';

export default {
    components: {
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        Modal,
        User
    },
    data() {
        return {
            RefreshUsers: 0,
            modals: {
                User: {
                    show: false,
                    title: 'Insert New',
                    InsertNew: true,
                    UserID: 0
                }
            }
        };
    },
    computed: {
        Users() {
            return this.$store.getters.UsersGetAll();
        },
        Roles() {
            return this.$store.getters.UsersGetAllRoles();
        }
    },
    created() {
        if (!this.Users.length)
        {
            apiUsers.All().then(res => { this.$store.dispatch('Users_UpdateAll', res.data); });
            // apiEmployees.All().then(res => { this.$store.dispatch('Users_UpdateEmployees', res.data); });
        }
        if (!this.Roles.length)
        {
            apiUsers.GetAllRoles().then(res => {this.$store.dispatch('Users_UpdateAllRoles', res.data); });
        }
    },
    mounted() {
        this.$emit('MustBeEmployeeOrHigher');
    },
    methods: {
        ShowUserModal(UserID) {
            UserID = typeof UserID === 'number' ? UserID : 0;
            this.modals.User.title = UserID ? 'Update' : 'Insert New';
            this.modals.User.InsertNew = !UserID;
            this.modals.User.show = true;
            this.modals.User.UserID = UserID;
        },
        FormatDate(Unformatted) {
            return Unformatted ? format(new Date(Unformatted), 'MM/dd/yyyy') : '?';
        },
        DisplayRoles(Roles) {
            return Roles.map(Role => Role.name).join(', ');
        },
        DeleteUser(UserID) {
            apiUsers.Delete(UserID);
            this.$store.dispatch('Users_RemoveUser', {UserID});
            this.RefreshUsers++;
        },
        CloseUserModal() {
            this.RefreshUsers++;
            this.modals.User.show = false;
        },
    }
};
</script>
<style lang="scss">
</style>
