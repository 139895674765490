<template>
  <div class="col-md-4 ml-auto mr-auto">
    <div v-if="SuccessMessage" class="alert alert-success">Your password reset link has been sent to your email.</div>
    <div v-if="Error" class="alert alert-danger">{{ Error }}</div>
    <form v-if="!SuccessMessage"  @submit="onSubmit">
      <card class="card-plain">
        <template v-slot:header>
          <div class="logo-container">
            <img src="img/favicon.svg" alt="Logo" />
          </div>
        </template>

        <vee-input
            name="email"
            type="email"
            inputClasses="no-border form-control-lg"
            placeholder="Email Address"
            addon-left-icon="now-ui-icons ui-1_email-85"
        />

        <template v-slot:footer>
          <div>
            <n-button native-type="submit" type="primary" block>Get Password Reset Link</n-button>
            <div class="text-center">
              <router-link class="link" to="/Login">Login</router-link>
            </div>
          </div>
        </template>

      </card>
    </form>
  </div>
</template>
<script>
import { VeeInput } from "@/components";
import { useForm } from "vee-validate";
import { ref } from 'vue';
import * as Yup from "yup";
import apiUsers from '../../api/users';

export default {
    name: "ForgotPassword",
    components: {
        VeeInput,
    },
    setup() {
        let SuccessMessage = ref(false);
        let Error = ref('');
        const schema = Yup.object().shape({
            email: Yup.string().required().email().label("The Email"),
        });
        const { handleSubmit, isSubmitting, setFieldError } = useForm({ validationSchema: schema });
        const onSubmit = handleSubmit (async values => {
            SuccessMessage.value = false;
            Error.value = '';
            apiUsers.sendPasswordResetEmail(values).then((response) => {
                if (response.response === 'passwords.throttled') { Error.value = 'Something went wrong. Try again later.'; }
                else { SuccessMessage.value = true; }
            }).catch(error => {
                let ErrorCodes = [400, 500];
                if (ErrorCodes.indexOf(error.response.status) > -1) { Error.value = 'Something went wrong. Try again later.'; }
                if (error.response.status === 422) {
                    for (let x in error.response.data.errors) { setFieldError(x, error.response.data.errors[x][0]); }
                }
            });
        });

        return {
            SuccessMessage,
            Error,
            onSubmit,
            isSubmitting
        };
    },
};
</script>
<style lang="scss">
.forgotpassword-page
{
    .logo-container { width: 65px; margin: 0 auto 55px; }
}
</style>