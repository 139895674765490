import DashboardLayout from "src/Modules/Users/Layouts/DashboardLayout.vue";

// Page Headers
import DefaultHeader from "src/Modules/Users/Pages/DefaultHeader";

// Pages
import AdminCourses from './Pages/Admin/Courses';
import AdminCourseAddEdit from "./Pages/Admin/CourseAddEdit";
import AdminSubjects from './Pages/Admin/Subjects';
import AdminSubjectsAddEdit from './Pages/Admin/SubjectAddEdit';
import AdminContent from './Pages/Admin/Content';
import AdminContents from './Pages/Admin/Contents';
import AdminContentsAddEdit from './Pages/Admin/ContentAddEdit';
import Subjects from './Pages/User/Subjects';
import Contents from './Pages/User/Contents';
import Content from './Pages/User/Content';
import CourseCompleted from './Pages/User/CourseCompleted';

const routes = [
    {
        path: '/Admin/Courses',
        component: DashboardLayout,
        redirect: 'Admin Courses',
        children: [
            {
                path: '',
                name: 'Admin Courses',
                components: { default: AdminCourses, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: 'Add',
                name: 'Add Course',
                components: { default: AdminCourseAddEdit, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: 'Edit/:CourseID',
                name: 'Edit Course',
                components: { default: AdminCourseAddEdit, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':CourseID/Subjects',
                name: 'Admin Subjects',
                components: { default: AdminSubjects, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':CourseID/Subjects/Add',
                name: 'Add Subject',
                components: { default: AdminSubjectsAddEdit, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':CourseID/Subjects/Edit/:SubjectID',
                name: 'Edit Subject',
                components: { default: AdminSubjectsAddEdit, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':CourseID/Subjects/:SubjectID/Contents',
                name: 'Admin Contents',
                components: { default: AdminContents, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':CourseID/Subjects/:SubjectID/Contents/Add',
                name: 'Add Content',
                components: { default: AdminContentsAddEdit, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':CourseID/Subjects/:SubjectID/Contents/Edit/:ContentID',
                name: 'Edit Content',
                components: { default: AdminContentsAddEdit, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':CourseID/Subjects/:SubjectID/Contents/:ContentID',
                name: 'Admin Content',
                components: { default: AdminContent, header: DefaultHeader },
                meta: { authOnly: true }
            }
        ]
    },
    {
        path: '/Courses/:CourseID',
        component: DashboardLayout,
        redirect: 'Subjects',
        children: [
            {
                path: '',
                name: 'Subjects',
                components: { default: Subjects, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':SubjectID',
                name: 'Contents',
                components: { default: Contents, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: ':SubjectID/:ContentID',
                name: 'Content',
                components: { default: Content, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: 'Completed',
                name: 'Course Completed',
                components: { default: CourseCompleted, header: DefaultHeader },
                meta: { authOnly: true }
            },
        ]
    }
];

export default routes;
