import Api from 'src/api/API';

export default {
    Index(UserID) {
        return Api.get('/Auth/Courses/Completed/'+UserID);
    },
    Add(UserID, data) {
        return Api.post('/Auth/Courses/Completed/'+UserID, data);
    },
    Update(CourseCompletedID, data) {
        return Api.patch('/Auth/Courses/Completed/'+CourseCompletedID, data);
    }
};
