import Api from 'src/api/API';

let path = (CourseID, SubjectID) => '/Auth/Courses/'+CourseID+'/Subjects/'+SubjectID+'/Contents';

export default {
    Index(CourseID, SubjectID) {
        return Api.get(path(CourseID, SubjectID));
    },
    Add(CourseID, SubjectID, data) {
        return Api.post(path(CourseID, SubjectID), data);
    },
    Update(CourseID, SubjectID, ContentID, data) {
        return Api.patch(path(CourseID, SubjectID)+'/'+ContentID, data);
    },
    Delete(CourseID, SubjectID, ContentID) {
        return Api.delete(path(CourseID, SubjectID)+'/'+ContentID);
    },
    FindByID(CourseID, SubjectID, ContentID) {
        return Api.get(path(CourseID, SubjectID)+'/'+ContentID);
    }
};