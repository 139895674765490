<template>
    <div class="Sizes Page">

      <font-awesome-icon icon="plus" title="Add" class="btn btn-primary pull-right" @click="ShowSizeModal" />
      <h1>Size</h1>

      <el-table stripe style="width: 100%" :data="Sizes">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
            :formatter="TableDataFormatter"
        >
        </el-table-column>
        <el-table-column :min-width="140" fixed="right" label="Actions">
          <template v-slot:default="props">
            <div class="table-actions">
              <!-- <n-button
                  @click="handleView(props.$index, props.row)"
                  class="like"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button> -->
              <n-button
                  @click="handleEdit(props.$index, props.row)"
                  class="edit"
                  type="warning"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="pencil" />
              </n-button>
              <n-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="trash-can" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <modal :class="{show:modals.Size.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseSizeModal">
        <template v-slot:header>
          <h4 class="title title-up">{{ modals.Size.title }}</h4>
        </template>
        <Size
            :Size="modals.Size.Size"
            :InsertNew="modals.Size.InsertNew"
            @CloseSizeModal="CloseSizeModal"
        ></Size>
      </modal>

    </div>
</template>
<script>
import { Card, Modal } from "@/components";
import { ElTable, ElTableColumn, ElDatePicker } from "element-plus";
import { Pagination as NPagination } from "@/components";
import { format } from "date-fns";
import Size from '../Components/Size';

import apiSizes from '@/Modules/Health/api/size';

export default {
    name: 'Sizes',
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        Modal,
        Size
    },
    data() {
        return {
            searchQuery: '',
            tableColumns: [
                {
                  prop:'height',
                  label:'Height',
                  minWidth: 105
                },
                {
                  prop:'wrist',
                  label:'Wrist',
                  minWidth: 90
                },
                {
                  prop:'knee',
                  label:'Knee',
                  minWidth: 90
                },
                {
                  prop:'ankle',
                  label:'Ankle',
                  minWidth: 95
                },
                {
                  prop:'shoulders',
                  label:'Shoulders',
                  minWidth: 140
                },
                {
                  prop:'chest',
                  label:'Chest',
                  minWidth: 95
                },
                {
                  prop:'waist',
                  label:'Waist',
                  minWidth: 95
                },
                {
                  prop:'neck',
                  label:'Neck',
                  minWidth: 90
                },
                {
                  prop:'bicep_r',
                  label:'Bicep (right)',
                  minWidth: 95
                },
                {
                  prop:'bicep_l',
                  label:'Bicep (left)',
                  minWidth: 95
                },
                {
                  prop:'forearm_r',
                  label:'Forearm (right)',
                  minWidth: 125
                },
                {
                  prop:'forearm_l',
                  label:'Forearm (left)',
                  minWidth: 120
                },
                {
                  prop:'thigh_r',
                  label:'Thigh (right)',
                  minWidth: 100
                },
                {
                  prop:'thigh_l',
                  label:'Thigh (left)',
                  minWidth: 95
                },
                {
                  prop:'calf_r',
                  label:'Calf (right)',
                  minWidth: 100
                },
                {
                  prop:'calf_l',
                  label:'Calf (left)',
                  minWidth: 95
                },
                {
                    prop: 'measured_time',
                    label: 'Taken At',
                    minWidth: 115,
                },
            ],
            modals: {
                Size: {
                    show: false,
                    title: 'Insert New Size Entry',
                    InsertNew: true,
                    Size: {}
                }
            }
        };
    },
    computed: {
        Sizes() {
            return this.$store.getters.BodySizes_GetAll();
        },
        Pagination() {
            return this.$store.getters.BodySizes_GetPagination();
        },
        UserID() {
            return this.$store.state.Users.id;
        },
        UserRole() {
            return this.$store.getters.UsersGetRole();
        }
    },
    mounted() {
        if (this.UserID) { this.GetSizes(); }
        console.log('Sizes Mounted');
    },
    methods: {
        GetSizes() {
            this.$store.dispatch('BodySizes_UpdateAll', {GetemAll: false, UserID: this.UserID});
        },
        TableDataFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'measured_time':
                    Data = format(new Date(row.measured_time), 'M/d/yyyy h:mm a');
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        handleView(index, row) {
            console.log({index, row});
        },
        handleEdit(index, row) {
            this.modals.Size.Size = row;
            this.modals.Size.title = 'Edit Size Entry';
            this.modals.Size.InsertNew = false;
            this.ShowSizeModal();
        },
        handleDelete(index, row) {
            console.log({index, row});
            apiSizes.Delete(row.id).then(res => {
              console.log({res});
              if (res.status === 200) { this.$store.dispatch('BodySizes_RemoveSize', row.id); }
            });
        },
        ShowSizeModal() {
            this.modals.Size.show = true;
        },
        CloseSizeModal() {
            this.modals.Size.Size = {};
            this.modals.Size.title = 'Insert New Size Entry';
            this.modals.Size.InsertNew = true;
            this.modals.Size.show = false;
        }
    },
    watch: {
        UserID() {
            console.log('Sizes UserID Changed');
            this.GetSizes();
        }
    }
};
</script>
<style>
  .Sizes .el-table .cell { text-align: center; word-break: break-word; }
</style>
