import apiTags from './api/tags';

let DefaultState = () => {
    return {
        Tags: []
    };
};

export default {
    state: DefaultState(),
    getters: {
        Tags_GetAll: state => () => {
            return state.Tags;
        },
        Tags_GetOptions: state => () => {
            return state.Tags.map(Tag => { return { value: Tag.id, label: Tag.title }; });
        },
        Tags_GetByID: state => (TagID) => {
            return state.Tags.find(A => A.id === TagID);//for (let x in state.AllUsers) { if (state.AllUsers[x].id === TagID) return state.AllUsers[x]; }
        },
    },
    mutations: {
        TAGS_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        TAGS_ADD(state, Tag) {
            state.Tags.push(Tag);
        },
        TAGS_UPDATE_TAG(state, Tag) {
            for (let x in state.Tags) { if (state.Tags[x].id === Tag.id) { state.Tags[x] = Tag; } }
        },
        TAGS_REMOVE_TAG(state, Tag) {
            for (let x in state.Tags) { if (state.Tags[x].id === Tag.id) { state.Tags.splice(x, 1); } }
        },
        TAGS_UPDATE_ALL(state, Tags) {
            state.Tags = Tags;
        }
    },
    actions: {
        Tags_ResetState({commit}) {
            commit('TAGS_RESET_STATE');
        },
        Tags_AddTag({commit}, Tag) {
            commit('TAGS_ADD', Tag);
        },
        Tags_UpdateTag({commit}, Tag) {
            commit('TAGS_UPDATE_TAG', Tag);
        },
        Tags_RemoveTag({commit}, Tag) {
            commit('TAGS_REMOVE_TAG', Tag);
        },
        Tags_UpdateAll({commit}, Tags) {
            if (Tags) { commit('TAGS_UPDATE_ALL', Tags); }
            else
            {
                apiTags.Index().then(res => {
                    commit('TAGS_UPDATE_ALL', res.data);
                });
            }
        }
    }
};
