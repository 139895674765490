import apiSubscriptions from './api/subscriptions';

let DefaultState = () => {
    return {
        Subscriptions: [],
        SubscriptionsFetched: false
    };
};

export default {
    state: DefaultState(),
    getters: {
        Subscriptions_GetAll: state => () => {
            return state.Subscriptions;
        },
        Subscriptions_GetByID: state => SubscriptionID => {
            return state.Subscriptions.find(A => A.id === SubscriptionID);
        },
    },
    mutations: {
        SUBSCRIPTIONS_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        SUBSCRIPTIONS_ADD(state, Subscription) {
            state.Subscriptions.push(Subscription);
        },
        SUBSCRIPTIONS_UPDATE_SUBSCRIPTION(state, Subscription) {
            for (let x in state.Subscriptions) { if (state.Subscriptions[x].id === Subscription.id) { state.Subscriptions[x] = Subscription; } }
        },
        SUBSCRIPTIONS_REMOVE_SUBSCRIPTION(state, Subscription) {
            for (let x in state.Subscriptions) { if (state.Subscriptions[x].id === Subscription.id) { state.Subscriptions.splice(x, 1); } }
        },
        SUBSCRIPTIONS_UPDATE_ALL(state, Subscriptions) {
            state.Subscriptions = Subscriptions;
            state.SubscriptionsFetched = true;
        }
    },
    actions: {
        Subscriptions_ResetState({commit}) {
            commit('SUBSCRIPTIONS_RESET_STATE');
        },
        Subscriptions_AddSubscription({commit}, Subscription) {
            commit('SUBSCRIPTIONS_ADD', Subscription);
        },
        Subscriptions_UpdateSubscription({commit}, Subscription) {
            commit('SUBSCRIPTIONS_UPDATE_SUBSCRIPTION', Subscription);
        },
        Subscriptions_RemoveSubscription({commit}, Subscription) {
            commit('SUBSCRIPTIONS_REMOVE_SUBSCRIPTION', Subscription);
        },
        Subscriptions_UpdateAll({commit, state}, Subscriptions) {
            if (Subscriptions) { commit('SUBSCRIPTIONS_UPDATE_ALL', Subscriptions); }
            else
            {
                state.SubscriptionsFetched = false;
                apiSubscriptions.Index().then(res => {
                    commit('SUBSCRIPTIONS_UPDATE_ALL', res.data);
                });
            }
        }
    }
};
