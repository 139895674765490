<template>
  <div id="contact-page">
    <div class="row">
      <div class="col-lg-7 m-auto">
        <card class="card-signup" no-footer-line headerBGColor="orange" headerClasses="text-center">
            <template v-slot:header>
                <h4 class="card-title mb-2">Contact</h4>
            </template>
            <div v-if="Err" class="alert alert-danger text-center mb2">{{ Err }}</div>
            <div v-if="Success" class="alert alert-success text-center">{{ Success }}</div>
            <Form v-else @submit="formSubmit" :validation-schema="formValidate()" v-slot="{ isSubmitting }" class="text-center">

                <vee-input
                name="name"
                type="text"
                placeholder="Name..."
                :value="formInputs.name"
                />

                <vee-input
                name="email"
                type="text"
                placeholder="Email..."
                :value="formInputs.email"
                />

                <vee-input
                name="subject"
                type="text"
                placeholder="Subject..."
                :value="formInputs.subject"
                />

                <vee-input
                name="message"
                type="textarea"
                placeholder="Message..."
                :value="formInputs.message"
                />

                <div v-if="Err" class="text-danger text-center">{{ Err }}</div>
                <n-button type="primary" native-type="submit" round size="lg" :disabled="disabled||isSubmitting">
                    Send
                    <font-awesome-icon v-if="isSubmitting" class="loading" icon="arrows-rotate" />
                </n-button>
            </Form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { VeeInput } from "@/components";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import generalApi from '@/api/general';

export default {
    components: {
        VeeInput, Form
    },
    data() {
        return {
            formInputs: {
                domain: process.env.VUE_APP_URL,
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            Err: '',
            Success: '',
            disabled: '',
        };
    },
    methods: {
        formValidate() {
            return Yup.object().shape({
                name: Yup.string().required().label("Name"),
                email: Yup.string().email().required().label("The Email"),
                subject: Yup.string().required().label("The Subject"),
                message: Yup.string().required().label("The Message"),
            });
        },
        formSubmit(values, actions) {
            this.disabled = true;
            this.Err = '';
            this.Success = '';
            this.formInputs = {...this.formInputs, ...values};
            return generalApi.Contact(this.formInputs).then(res => {
                this.Success = res.data;
                this.disabled = false;
            }).catch(err => {
                if (err.response.status === 422) {
                    for (let x in err.response.data.errors) { actions.setFieldError(x, err.response.data.errors[x][0]); }
                } else { this.Err = 'Something went wrong. Try again later.'; }
                this.disabled = false;
            });
        }
    },
};
</script>
<style lang="scss">
#contact-page {
    .card {
        .card-header { padding-bottom: 1px; }
        textarea { border: 1px solid #E3E3E3; border-radius: 30px; }
    }
}
</style>
