<template>
    <div class="ProgressTrack">

        <card>
            <b class="d-inline-block mb-2">{{ TrackInfo.selectedAction }}</b>
            <span v-if="DesiredActionTime"> - Desired Action Time: {{ DesiredActionTime }}</span>
            <div class="row">
                <div v-for="(Week, k) in Diffs" :key="'Week'+k" class="col-12 col-md-3">
                    <label>Week of: {{ Week.WeekOf }}</label>
                    <div>
                        # Times Committed: {{ Week.TimesCommitted }}
                    </div>
                    <div>
                        Total Minutes Late: {{ Week.Total }}
                    </div>
                    <div>
                        Average Minutes Late: {{ Math.round(Week.Average) }}
                    </div>
                    <div :class="{'text-success':(Week.Progress * 100)>1,'text-danger': (Week.Progress * 100)<0}">
                        Progress: {{ (Week.Progress * 100).toFixed(2) }}% improvement over last week
                    </div>
                </div>
            </div>
        </card>

    </div>
</template>
<script>
import { format } from 'date-fns';
import apiActions from '../api/actions';

export default {
    name: 'Actions Progress Track',
    props: {
        TrackInfo: Object
    },
    data() {
        return {
            Diffs: []
        };
    },
    computed: {
        DesiredActionTime() {
            if (this.TrackInfo.desiredActionTime)
            {
                let dat = this.TrackInfo.desiredActionTime.split(':');
                let Today = new Date();
                Today.setHours(dat[0], dat[1]);
                return format(Today, 'h:mm a');
            }
            return '';
        }
    },
    mounted() {
        apiActions.CalculateProgress(this.TrackInfo).then(res => {
            this.Diffs = res.data;
        });
    },
    methods: {
    }
};
</script>
