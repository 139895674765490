<template>
    <div class="Page">

      <font-awesome-icon icon="plus" title="Add" class="btn btn-primary pull-right" @click="$router.push({name: 'Add Course'})" />
      <font-awesome-icon class="btn btn-link pull-right Refresh" :class="{loading: CoursesLoading}" icon="arrows-rotate" @click="getCourses" />
      <h1>Courses</h1>

      <el-table stripe style="width: 100%" :data="Courses">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :formatter="TableDateFormatter"
            :label="column.label"
        >
        </el-table-column>
        <el-table-column :min-width="60" fixed="right" label="Actions">
          <template v-slot:default="props">
            <div class="table-actions">
              <n-button
                  @click="$router.push({name: 'Admin Subjects', params: {CourseID: props.row.id}})"
                  class="like"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button>
              <n-button
                  @click="$router.push({name: 'Edit Course', params: {CourseID: props.row.id}})"
                  class="edit"
                  type="warning"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="pencil" />
              </n-button>
              <n-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="trash-can" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

    </div>
</template>
<script>
import { ElTable, ElTableColumn, ElDatePicker } from "element-plus";
import { format } from "date-fns";

export default {
    name: 'Courses',
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn
    },
    data() {
        return {
            selectedTags: [],
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'title',
                    label: 'Title',
                    minWidth: 200,
                },
                {
                    prop: 'created_at',
                    label: 'Created',
                    minWidth: 200,
                },
                {
                    prop: 'updated_at',
                    label: 'Updated',
                    minWidth: 200,
                },
            ]
        };
    },
    computed: {
        Courses() {
            return this.$store.getters.Courses_GetAll();
        },
        Pagination() {
            return this.$store.getters.Courses_GetPagination();
        },
        CoursesLoading() {
            return this.$store.state.CoursesLoading;
        }
    },
    mounted() {
        this.getCourses();
    },
    methods: {
        getCourses() {
            this.$store.dispatch('Courses_UpdateAll');
        },
        TableDateFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'created_at':
                    Data = format(new Date(row.created_at), 'M/d/yyyy h:mm a');
                break;
                case 'updated_at':
                    Data = format(new Date(row.updated_at), 'M/d/yyyy h:mm a');
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        handleDelete(index, row) {
          console.log({index, row});
          this.$store.dispatch('Courses_RemoveCourse', row);
        }
    }
};
</script>
