import { createStore } from 'vuex';

import Actions from './Modules/Actions/store';
import BodySizes from './Modules/Health/store/sizes';
import BodyWeights from './Modules/Health/store/weights';
import Courses from './Modules/Courses/store';
import Recipes from './Modules/Health/store/recipes';
import Subscriptions from './Modules/Subscriptions/store';
import Tags from './Modules/Tags/store';
import Users from './Modules/Users/store';

export const store = new createStore({
    state: {
        ReturnToPage: '',
        Site: {
            Domain: process.env.VUE_APP_URL,
            Name: process.env.VUE_APP_TITLE,
            Phones: {  },
            AuthLayout: {
                BG: ''
            }
        },
        Messages: { Success: '', Error: '', Info: '' },
    },
    getters: {
        getSuccessMessage: state => () => {
            let Message = state.Messages.Success;
            state.Messages.Success = '';
            return Message;
        },
        getErrorMessage: state => () => {
            let Message = state.Messages.Error;
            state.Messages.Error = '';
            return Message;
        },
        getSiteDomain: state => () => {
            return state.Site.Domain;
        }
    },
    mutations: {
        SET_SUCCESS_MESSAGE(state, Message) {
            state.Messages.Success = Message;
        },
        SET_ERROR_MESSAGE(state, Message) {
            state.Messages.Error = Message;
        },
        UPDATE_SITE_INFO(state, Info) {
            state.Site = Info;
        }
    },
    actions: {
        ResetState({commit}, Message) {
            this.dispatch('UserResetState');
            commit('SET_'+Message.Type.toUpperCase()+'_MESSAGE', Message.Message);
        },
        SetMessage({commit}, Message)
        {
            commit('SET_'+Message.Type.toUpperCase()+'_MESSAGE', Message.Message);
        }
        /*UpdateSiteInfo({commit}, Profile) {
            apiSite.SiteInfo()
                .then(response => { commit('UPDATE_SITE_INFO', response.data); })
                .catch(error => { console.log(error); });
        },*/
    },
    modules: {
        Actions,
        BodySizes,
        BodyWeights,
        Courses,
        Recipes,
        Subscriptions,
        Tags,
        Users,
    }
});
