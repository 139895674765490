import apiSizes from '../api/size';

let DefaultState = () => {
    return {
        BodySizes: [],
        Pagination: {
            total: 0,
            current_page: 1,
            per_page: 20,
            last_page: 1,
        }
    };
};

export default {
    state: DefaultState(),
    getters: {
        BodySizes_GetAll: state => () => {
            return state.BodySizes;
        },
        BodySizes_GetPagination: state => () => {
            return state.Pagination;
        },
        BodySizes_GetByID: state => (SizeID) => {
            return state.BodySizes.find(BW => BW.id === SizeID);
        }
    },
    mutations: {
        BODY_SIZES_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        BODY_SIZES_ADD(state, Size) {
            Size.images = JSON.parse(Size.images);
            state.BodySizes.push(Size);
        },
        BODY_SIZES_UPDATE_SIZE(state, Size) {
            for (let x in state.BodySizes) {
                if (state.BodySizes[x].id === Size.id) {
                    Size.images = JSON.parse(Size.images);
                    state.BodySizes[x] = Size;
                }
            }
        },
        BODY_SIZES_REMOVE_SIZE(state, SizeID) {
            for (let x in state.BodySizes) { if (state.BodySizes[x].id === SizeID) { state.BodySizes.splice(x, 1); } }
        },
        BODY_SIZES_UPDATE_ALL(state, Sizes) {
            state.BodySizes = Sizes.data.map(Size => {
                Size.images = JSON.parse(Size.images);
                return Size;
            });
            state.Pagination = {
                total: Sizes.total,
                current_page: Sizes.current_page,
                per_page: Sizes.per_page,
                last_page: Sizes.last_page,
            };
        },
    },
    actions: {
        BodySizes_ResetState({commit}) {
            commit('BODY_SIZES_RESET_STATE');
        },
        BodySizes_AddSize({commit}, Size) {
            commit('BODY_SIZES_ADD', Size);
        },
        BodySizes_UpdateSize({commit}, Size) {
            commit('BODY_SIZES_UPDATE_SIZE', Size);
        },
        BodySizes_RemoveSize({commit}, SizeID) {
            commit('BODY_SIZES_REMOVE_SIZE', SizeID);
        },
        BodySizes_UpdateAll({commit}, Sizes) {
            console.log({Sizes});
            if (typeof Sizes.GetemAll === 'undefined') { commit('BODY_SIZES_UPDATE_ALL', Sizes); }
            else
            {
                let Promise = Sizes.GetemAll ? apiSizes.Index() : apiSizes.FindByUserID(Sizes.UserID);
                Promise.then(res => {
                    if (typeof res.data.data !== 'undefined') { commit('BODY_SIZES_UPDATE_ALL', res.data); }
                });
            }
        },
    }
};
