<template>
    <div class="viewRecipe">

        <div class="row">
            <div v-if="Recipe.image" class="col-12">
                <img :src="Recipe.image" alt="Image">
            </div>
            <div class="col-12">
                <label class="StepIng">Ingredients</label>
                <div v-for="Ingredient in Recipe.ingredients">
                    <label class="Measurement">{{ Ingredient.Measurment }}</label>
                    {{ Ingredient.Ingredient }}
                </div>
            </div>
            <div class="col-12">
                <label class="StepIng">Steps</label>
                <div v-for="(Step, k) in Recipe.steps" class="Step">
                    <div class="StepNum">Step {{ k+1 }}</div>
                    <div v-html="Step"></div>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-if="Recipe.video" class="col-12">
                <iframe v-if="Recipe.video.indexOf('youtube.com') > -1"
                    width="445" height="250" :src="Recipe.video"
                    style="border: 0"
                    ></iframe>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        Recipe: {
            type: Object,
            default: {
                ingredients: [],
                steps: [],
                image: '',
                video: ''
            }
        }
    },
};
</script>
<style lang="scss">
.viewRecipe {
    .StepIng { font-size: 20px; font-weight: bold; text-decoration: underline; }
    .Measurement { margin-right: 10px; min-width: 120px; text-align: right; font-weight: bold; }

    .Step {
        display: flex;

        div { padding: 7px; }
        .StepNum { min-width: 65px; padding-left: 0; font-weight: bold; }
    }
}
</style>
