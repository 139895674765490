<template>
  <div class="Page">
    <span class="btn btn-prev entities pull-right" title="back" @click="router.go(navBack)">&leftarrow;</span>
    <h1>{{ Course.title }} Subject</h1>
    <div v-if="SuccessMessage" class="alert alert-success">Subject has been saved</div>
    <div v-if="Error" class="alert alert-danger">{{ Error }}</div>
    <form @submit="onSubmit" class="col-md-4 mt-5 mr-auto ml-auto">
      <card headerBGColor="orange" title="Subject">

        <vee-input
            name="title"
            type="text"
            :value="Subject.title"
            inputClasses="no-border form-control-lg"
            placeholder="Title"
            :ref="refInputTitle"
            :key="Subject.title"
        />
        <vee-input
            name="sequence"
            type="number"
            :value="''+Subject.sequence"
            inputClasses="no-border form-control-lg"
            placeholder="Sequence"
            :ref="refInputSequence"
            :key="Subject.sequence"
        />

        <template v-slot:footer>
          <div class="text-center">
            <n-button native-type="submit" type="primary" :disabled="isSubmitting">
              Save
              <font-awesome-icon v-if="isSubmitting" class="loading" icon="arrows-rotate" />
            </n-button>
          </div>
        </template>

      </card>
    </form>
  </div>
</template>
<script>
import { VeeInput } from "@/components";
import { useForm } from "vee-validate";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import * as Yup from "yup";

import apiSubjects from '../../api/subjects';

export default {
    name: "Subject Modify",
    components: {
        VeeInput,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        let navBack = ref(-1);

        // Course Info
        let CourseID = typeof route.params.CourseID === 'undefined' ? 0 : parseInt(route.params.CourseID);
        let Course = computed(() => store.getters.Courses_GetByID(CourseID));
        if (CourseID && Course.value.id === 0) { store.dispatch('Courses_GetByID', CourseID); }

        // Subject Info
        let SubjectID = typeof route.params.SubjectID === 'undefined' ? 0 : parseInt(route.params.SubjectID);
        let Subject = computed(() => store.getters.CoursesSubjects_GetByID(SubjectID));

        // The Form
        let SuccessMessage = ref(false);
        let Error = ref('');
        let refInputTitle = ref(null);
        let refInputSequence = ref(null);
        const schema = Yup.object().shape({
            title: Yup.string().required().label("The Subject Title"),
            sequence: Yup.string().required().label("The Sequence"),
        });
        const { handleSubmit, isSubmitting, setFieldError } = useForm({ validationSchema: schema });
        const onSubmit = handleSubmit (async values => {
            SuccessMessage.value = false;
            Error.value = '';
            if (SubjectID)
            {
                apiSubjects.Update(CourseID, SubjectID, values).then(res => {
                    // store.dispatch('CoursesSubjects_UpdateSubject', res.data);
                    SuccessMessage.value = true;
                }).catch(error => {
                  let ErrorCodes = [400, 500];
                  if (ErrorCodes.indexOf(error.response.status) > -1) { Error.value = 'Something went wrong. Try again later.'; }
                  if (error.response.status === 422) {
                    for (let x in error.response.data.errors) { setFieldError(x, error.response.data.errors[x][0]); }
                  }
                });
            }
            else
            {
                apiSubjects.Add(CourseID, values).then((response) => {
                    SuccessMessage.value = true;
                    navBack.value = -2;
                    router.push({name: 'Edit Subject', params: {CourseID, SubjectID: response.data.id}});
                }).catch(error => {
                    let ErrorCodes = [400, 500];
                    if (ErrorCodes.indexOf(error.response.status) > -1) { Error.value = 'Something went wrong. Try again later.'; }
                    if (error.response.status === 422) {
                        for (let x in error.response.data.errors) { setFieldError(x, error.response.data.errors[x][0]); }
                    }
                });
            }
        });

        return {
            navBack,
            SuccessMessage,
            Error,
            onSubmit,
            isSubmitting,
            router,
            Course,
            Subject,
            refInputTitle,
            refInputSequence
        };
    },
};
</script>
<style lang="scss">
.forgotpassword-page
{
    .logo-container { width: 65px; margin: 0 auto 55px; }
}
</style>