<template>
    <form @submit.prevent="updateProfile">
        <card>
            <h5 slot="header" class="title">Edit Profile</h5>
            <div v-if="SuccessMessage" class="alert alert-success">Your profile has been successfully updated!</div>
            <div v-if="Error" class="alert alert-danger">{{ Error }}</div>
        </card>

        <div style="display: flex; justify-content: space-between">
            <card class="col-12 col-md-6">
                <div class="row">
                    <fg-input type="text" label="Username" placeholder="Username" v-model="User.username" class="col-12"></fg-input>
                </div>
                <div class="row" v-if="UserLevel !== 3">
                    <fg-input type="text" label="Name" placeholder="Name" v-model="User.name" class="col-12"></fg-input>
                </div>
                <div class="row">
                    <fg-input type="text" label="Email" placeholder="Email" v-model="User.email" class="col-12"></fg-input>
                </div>
                <div class="row">
                    <fg-input type="password" label="Password" placeholder="Password" v-model="Password" class="col-12"></fg-input>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-primary">Update</button>
                    </div>
                </div>
            </card>

            <card v-if="UserLevel === 3" class="col-12 col-md-6">
                <div class="row">
                    <fg-input type="text" label="First Name" placeholder="First Name" v-model="User.Employee.first_name" class="col-12"></fg-input>
                </div>
                <div class="row">
                    <fg-input type="text" label="Last Name" placeholder="Last Name" v-model="User.Employee.last_name" class="col-12"></fg-input>
                </div>
                <div class="row">
                    <fg-input type="text" label="Phone" placeholder="Phone" v-model="User.Employee.phone" class="col-12"></fg-input>
                </div>
            </card>
        </div>
    </form>
</template>
<script>
import apiUsers from 'src/Modules/Users/api/users'

export default {
    data() {
        return {
            SuccessMessage: '',
            Error: '',
            Password: '',
            // Todo: Disable form while submitting
            saving: false
        };
    },
    computed: {
        User() { return this.$store.state.Users; },
        UserLevel() { return this.User.Roles.length ? this.User.Roles[0].level : 1; },
        formInputs() { return this.$store.getters.profileInputs(); }
    },
    methods: {
        updateProfile() {
            this.SuccessMessage = '';
            this.Error = '';
            let Inputs = this.formInputs;
            if (this.Password) { Inputs.password = this.Password; }
            console.log("Your data: ", Inputs);
            apiUsers.updateProfile(Inputs).then(resp => {
                console.log(resp);
                this.$store.dispatch('Users_UpdateSelf', resp.data);
                this.SuccessMessage = true;
                this.Password = '';
            }).catch(error => {
                console.log(error.response.status, error.response.data);
                if (error.response.status === 422)
                {
                    if (typeof error.response.data.errors.username !== 'undefined') { this.Error = error.response.data.errors.username[0]; }
                    if (typeof error.response.data.errors.password !== 'undefined') { this.Error = error.response.data.errors.password[0]; }
                    if (typeof error.response.data.errors.email !== 'undefined') { this.Error = error.response.data.errors.email[0]; }
                }
            });
        }
    }
};
</script>
<style></style>
