import Api from 'src/api/API';

export default {
    Index(CourseID) {
        return Api.get('/Auth/Courses/'+CourseID+'/Subjects');
    },
    Add(CourseID, data) {
        return Api.post('/Auth/Courses/'+CourseID+'/Subjects', data);
    },
    Update(CourseID, SubjectID, data) {
        return Api.patch('/Auth/Courses/'+CourseID+'/Subjects/'+SubjectID, data);
    },
    Delete(CourseID, SubjectID) {
        return Api.delete('/Auth/Courses/'+CourseID+'/Subjects/'+SubjectID);
    },
    FindByID(CourseID, SubjectID) {
        return Api.get('/Auth/Courses/'+CourseID+'/Subjects/'+SubjectID);
    }
};
