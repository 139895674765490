import DashboardLayout from "src/Modules/Users/Layouts/DashboardLayout.vue";

// Page Headers
import DefaultHeader from "src/Modules/Users/Pages/DefaultHeader";

// Pages
import Recipes from 'src/Modules/Health/Pages/Recipes';

const routes = [
    {
        path: '/Health/Recipes',
        component: DashboardLayout,
        children: [
            {
                path: '',
                name: 'Recipes',
                components: { default: Recipes, header: DefaultHeader },
                meta: { authOnly: true }
            }
        ]
    },
];

export default routes;
