<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{ toggled: !$sidebar.showSidebar }">
        <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
      </div>
        <button
            @click="minimizeSidebar"
            :class="{ toggled: !$sidebar.isMinimized }"
            class="SideBarMinimizer"
            type="button"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
        </button>
      <span class="navbar-brand">
        {{ $route.name }}
      </span>
    </div>
    <button
      @click="toggleNavbar"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template slot="navbar-menu">
      <!--<form>
        <div class="input-group no-border">
          <fg-input
            placeholder="Search..."
            addon-right-icon="now-ui-icons ui-1_zoom-bold"
          >
          </fg-input>
        </div>
      </form>-->

      <ul class="navbar-nav">
        <!--<li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="now-ui-icons media-2_sound-wave"></i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <drop-down
          tag="li"
          position="right"
          class="nav-item"
          icon="now-ui-icons location_world"
        >
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </drop-down>-->

        <li class="nav-item">
          <router-link to="/Profile" class="nav-link">
            <i class="now-ui-icons users_single-02"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </router-link>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components";

export default {
  components: {
    Navbar,
    NavbarToggleButton
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
      minimizeSidebar() {
          if (this.$sidebar) {
              this.$sidebar.toggleMinimize();
          }
      }
  }
};
</script>
<style lang="scss">
.SideBarMinimizer {
    display: none; position: relative; left: -8px; margin-right: 5px;
    height: 27px; width: 34px; border: 0;
    background: transparent;

    @media screen and (min-width: 992px)
    {
        display: block;

        .navbar-toggler-bar {
            display: block;
            position: relative;
            width: 22px;
            height: 1px;
            border-radius: 1px;
            background: #FFFFFF;
        }
        .navbar-toggler-bar + .navbar-toggler-bar {
            margin-top: 7px;
        }
        &.toggled .navbar-toggler-bar + .navbar-toggler-bar {
            margin-top: 5px;
        }
        .bar1 {
            top: 0px;
            -webkit-animation: topbar-back 500ms linear 0s;
            animation: topbar-back 500ms 0s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
        &.toggled .bar1 {
             top: 6px;
             -webkit-animation: topbar-x 500ms linear 0s;
             animation: topbar-x 500ms 0s;
             -webkit-animation-fill-mode: forwards;
             animation-fill-mode: forwards;
         }
        .navbar-toggler-bar.bar2 {
            width: 17px;
            -webkit-transition: width .2s linear;
            transition: width .2s linear;
            opacity: 1;
        }
        &.toggled .bar2 {
             opacity: 0;
        }
        .bar3 {
            bottom: 0px;
            -webkit-animation: bottombar-back 500ms linear 0s;
            animation: bottombar-back 500ms 0s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
        &.toggled .bar3 {
            bottom: 6px;
            -webkit-animation: bottombar-x 500ms linear 0s;
            animation: bottombar-x 500ms 0s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }
        .bar1, .bar2, .bar3 {
            outline: 1px solid transparent;
        }
    }

    @keyframes topbar-x {
        0% {
            top: 0px;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        45% {
            top: 6px;
            -webkit-transform: rotate(145deg);
            transform: rotate(145deg);
        }

        75% {
            -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
        }

        100% {
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
        }
    }

    @-webkit-keyframes topbar-x {
        0% {
            top: 0px;
            -webkit-transform: rotate(0deg);
        }

        45% {
            top: 6px;
            -webkit-transform: rotate(145deg);
        }

        75% {
            -webkit-transform: rotate(130deg);
        }

        100% {
            -webkit-transform: rotate(135deg);
        }
    }

    @keyframes topbar-back {
        0% {
            top: 6px;
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
        }

        45% {
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }

        75% {
            -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
        }

        100% {
            top: 0px;
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
    }

    @-webkit-keyframes topbar-back {
        0% {
            top: 6px;
            -webkit-transform: rotate(135deg);
        }

        45% {
            -webkit-transform: rotate(-10deg);
        }

        75% {
            -webkit-transform: rotate(5deg);
        }

        100% {
            top: 0px;
            -webkit-transform: rotate(0);
        }
    }

    @keyframes bottombar-x {
        0% {
            bottom: 0px;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        45% {
            bottom: 6px;
            -webkit-transform: rotate(-145deg);
            transform: rotate(-145deg);
        }

        75% {
            -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
        }

        100% {
            -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
    }

    @-webkit-keyframes bottombar-x {
        0% {
            bottom: 0px;
            -webkit-transform: rotate(0deg);
        }

        45% {
            bottom: 6px;
            -webkit-transform: rotate(-145deg);
        }

        75% {
            -webkit-transform: rotate(-130deg);
        }

        100% {
            -webkit-transform: rotate(-135deg);
        }
    }

    @keyframes bottombar-back {
        0% {
            bottom: 6px;
            -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }

        45% {
            -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
        }

        75% {
            -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
        }

        100% {
            bottom: 0px;
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
    }

    @-webkit-keyframes bottombar-back {
        0% {
            bottom: 6px;
            -webkit-transform: rotate(-135deg);
        }

        45% {
            -webkit-transform: rotate(10deg);
        }

        75% {
            -webkit-transform: rotate(-5deg);
        }

        100% {
            bottom: 0px;
            -webkit-transform: rotate(0);
        }
    }
}
</style>
