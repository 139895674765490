<template>
  <form @submit="onSubmit">
    <div class="col-md-4 ml-auto mr-auto">
      <card class="card-login card-plain">
        <template v-slot:header>
          <div>
            <div class="logo-container">
              <img src="img/favicon.svg" alt="" />
            </div>
          </div>
        </template>

        <div>
          <div v-if="Error" class="alert alert-danger">{{ Error }}</div>
          <vee-input
              name="username"
              type="text"
              inputClasses="no-border form-control-lg"
              placeholder="Username"
              addon-left-icon="now-ui-icons users_single-02"
          />

          <vee-input
              name="password"
              type="text"
              inputClasses="no-border form-control-lg"
              placeholder="Password"
              addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />
        </div>

        <template v-slot:footer>
          <div>
            <n-button native-type="submit" type="primary" round block :disabled="isSubmitting">Login</n-button>
            <div class="pull-left">
              <h6>
                <router-link class="link footer-link" to="/register">Create Account</router-link>
              </h6>
            </div>

            <div class="pull-right">
              <h6>
                <router-link class="link" to="/ForgotPassword">Forgot Password?</router-link>
              </h6>
            </div>
          </div>
        </template>
      </card>
    </div>
  </form>
</template>
<script>
import { VeeInput } from "@/components";
import { useForm } from "vee-validate";
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import * as Yup from "yup";
import API from 'src/api/API';
import apiUsers from '../../api/users';

export default {
  name: "login",
  components: {
    VeeInput,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    let Error = ref('');
    const schema = Yup.object().shape({
      username: Yup.string().required().label("The Username"),
      password: Yup.string().required().label("The Password"),
    });
    const { handleSubmit, isSubmitting, setFieldError } = useForm({ validationSchema: schema });
    const onSubmit = handleSubmit (async values => {
      Error.value = '';
      apiUsers.Login(values).then((response) => {
        console.log(response);
        localStorage.setItem('Auth', 'true');
        localStorage.setItem('BT', response.data.token);
        API.defaults.headers.common = {'Authorization': `bearer ${response.data.token}`}
        let GoTo = route.query.redirect || { name: 'Dashboard' };
        router.push(GoTo);
      }).catch(error => {
        let ErrorCodes = [400, 500];
        if (ErrorCodes.indexOf(error.response.status) > -1) { Error.value = 'Something went wrong. Try again later.'; }
        if (error.response.status === 422) {
          for (let x in error.response.data.errors) { setFieldError(x, error.response.data.errors[x][0]); }
        }
      });
    });


    return {
      onSubmit,
      isSubmitting,
      Error
    };
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
input:-internal-autofill-selected { background-color: transparent; }
</style>
