<template>
    <div class="Page">

      <font-awesome-icon icon="plus" title="Add" class="btn btn-primary pull-right" @click="$router.push({name: 'Add Content', params: {CourseID: Course.id, SubjectID: Subject.id}})" />
      <span class="btn btn-prev entities pull-right" title="back" @click="$router.go(-1)">&leftarrow;</span>
      <font-awesome-icon class="btn btn-link pull-right Refresh" :class="{loading: ContentsLoading}" icon="arrows-rotate" @click="getContents" />
      <h1>{{ Course.title }} / {{ Subject.title }} - Contents</h1>

      <el-table stripe style="width: 100%" :data="Contents">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :formatter="TableDateFormatter"
            :label="column.label"
        >
        </el-table-column>
        <el-table-column :min-width="80" fixed="right" label="Actions">
          <template v-slot:default="props">
            <div class="table-actions">
              <n-button
                  @click="$router.push({name: 'Admin Content', params: {CourseID: Course.id, SubjectID: Subject.id, ContentID: props.row.id}})"
                  class="like"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button>
              <n-button
                  @click="$router.push({name: 'Edit Content', params: {CourseID: Course.id, SubjectID: Subject.id, ContentID: props.row.id}})"
                  class="edit"
                  type="warning"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="pencil" />
              </n-button>
              <n-button
                  @click="DeleteContent(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="trash-can" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

    </div>
</template>
<script>
import { ElTable, ElTableColumn, ElDatePicker } from "element-plus";
import { format } from "date-fns";

export default {
    name: 'Contents',
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn
    },
    data() {
        return {
            selectedTags: [],
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'sequence',
                    label: 'Sequence',
                    minWidth: 200,
                },
                {
                    prop: 'title',
                    label: 'Title',
                    minWidth: 200,
                },
                {
                    prop: 'created_at',
                    label: 'Created',
                    minWidth: 200,
                },
                {
                    prop: 'updated_at',
                    label: 'Updated',
                    minWidth: 200,
                },
            ]
        };
    },
    computed: {
        Course() {
            return this.$store.getters.Courses_GetByID(this.$route.params.CourseID);
        },
        Subject() {
            return this.$store.getters.CoursesSubjects_GetByID(this.$route.params.SubjectID);
        },
        Contents() {
            return this.$store.getters.CoursesSubjectsContents_GetAll();
        },
        ContentsLoading() {
            return this.$store.state.Courses.ContentsLoading;
        }
    },
    mounted() {
        this.getContents();
        this.getCourse();
    },
    methods: {
        getContents() {
            this.$store.dispatch('CoursesSubjectsContents_UpdateAll', {CourseID: this.$route.params.CourseID, SubjectID: this.$route.params.SubjectID});
        },
        getCourse() {
            this.$store.dispatch('Courses_GetByID', this.$route.params.CourseID);
        },
        TableDateFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'created_at':
                    Data = format(new Date(row.created_at), 'M/d/yyyy h:mm a');
                break;
                case 'updated_at':
                    Data = format(new Date(row.updated_at), 'M/d/yyyy h:mm a');
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        DeleteContent(index, row) {
          console.log({index, row});
            this.$store.dispatch('CoursesSubjectsContents_RemoveContent', {CourseID: this.Course.id, SubjectID: this.Subject.id, ContentID: row.id});
        }
    }
};
</script>
