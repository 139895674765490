import apiActions from './api/actions';

let DefaultState = () => {
    return {
        Actions: [],
        ActionsLoading: false,
        Pagination: {
            total: 0,
            current_page: 1,
            per_page: 20,
            last_page: 1,
        },
        QuickActions: [],
        QuickActionsLoading: false,
        Distinct: [],
        MeasurementUnits: []
    };
};

export default {
    state: DefaultState(),
    getters: {
        Actions_GetAll: state => () => {
            return state.Actions;
        },
        Actions_GetPagination: state => () => {
            return state.Pagination;
        },
        Actions_GetByID: state => (ActionID) => {
            return state.Actions.find(A => A.id === ActionID);
        },
        Actions_GetQuickActions: state => () => {
            return state.QuickActions;
        },
        Actions_GetDistinct: state => () => {
            return state.Distinct.map(Action => { return { value: Action.action, label: Action.action }; });
        },
        Actions_GetMeasurmentUnitsByID: state => (UnitID) => {
            console.log({UnitID, state});
            return state.MeasurementUnits.find(A => A.id === UnitID);
        }
    },
    mutations: {
        ACTIONS_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        ACTIONS_ADD(state, Action) {
            // Action.images = JSON.parse(Action.images);
            let ActionExists = false;
            for (let x in state.Actions)
            {
                if (state.Actions[x].id === Action.id)
                {
                    state.Actions[x] = Action;
                    ActionExists = true;
                }
            }
            if (!ActionExists) { state.Actions.push(Action); }
            if (Action.start && !Action.stop)
            {
                state.QuickActions.forEach(QA => {
                    if (QA.action === Action.action) { QA.started = Action.id; }
                });
            }
        },
        ACTIONS_UPDATE_ACTION(state, Action) {
            // Action.images = JSON.parse(Action.images);
            for (let x in state.Actions) { if (state.Actions[x].id === Action.id) { state.Actions[x] = Action; } }
        },
        ACTIONS_REMOVE_ACTION(state, Action) {
            for (let x in state.Actions) { if (state.Actions[x].id === Action.id) { state.Actions.splice(x, 1); } }
            for (let x in state.QuickActions) { if (state.QuickActions[x].id === Action.id) { state.QuickActions.splice(x, 1); } }
        },
        ACTIONS_UPDATE_ALL(state, Actions) {
            /* Actions.data.forEach(A => {
                A.images = JSON.parse(A.images);
            }); */
            state.Actions = Actions.data;
            state.Pagination = Actions.meta;
            state.ActionsLoading = false;
        },
        ACTIONS_UPDATE_QUICK_ACTIONS(state, Actions) {
            /* Actions.forEach(A => {
                A.images = JSON.parse(A.images);
            }); */
            state.QuickActions = Actions;
            let Started = Actions.filter(A => A.quick_actions_started.length);
            Started.forEach(A => {
                state.QuickActions.forEach(QA => {
                    if (QA.action === A.action) { QA.started = A.id; }
                });
            });
            state.QuickActionsLoading = false;
        },
        ACTIONS_UPDATE_DISTINCT(state, Actions) {
            Actions.forEach(Action => {
                let ActionExists = state.Distinct.find(stateAction => stateAction.action === Action.action);
                if (!ActionExists) { state.Distinct.push(Action); }
            });
        },
        ACTIONS_UPDATE_MEASUREMENT_UNITS(state, Units) {
            state.MeasurementUnits = Units;
        }
    },
    actions: {
        Actions_ResetState({commit}) {
            commit('ACTIONS_RESET_STATE');
        },
        Actions_AddAction({commit}, Action) {
            commit('ACTIONS_ADD', Action);
        },
        Actions_UpdateAction({commit}, Action) {
            commit('ACTIONS_UPDATE_ACTION', Action);
        },
        Actions_RemoveAction({commit}, Action) {
            apiActions.Delete(Action.id).then(res => {
                commit('ACTIONS_REMOVE_ACTION', Action);
            });
        },
        Actions_UpdateAll({commit, state}, Actions) {
            if (!state.ActionsLoading)
            {
                state.ActionsLoading = true;
                if (Actions) { commit('ACTIONS_UPDATE_ALL', Actions); }
                else
                {
                    apiActions.Index().then(res => {
                        commit('ACTIONS_UPDATE_ALL', res.data);
                    });
                }
            }
        },
        Actions_UpdateQuickActions({commit, state}) {
            if (!state.QuickActionsLoading)
            {
                state.QuickActionsLoading = true;
                apiActions.GetQuickActions().then(res => {
                    commit('ACTIONS_UPDATE_QUICK_ACTIONS', res.data);
                });
            }
        },
        Actions_UpdateDistinct({commit}) {
            apiActions.GetDistinctActions().then(res => {
                commit('ACTIONS_UPDATE_DISTINCT', res.data || []);
            });
        },
        Actions_GetByID({commit}, ActionID) {
            apiActions.FindByID(ActionID).then(res => {
                commit('ACTIONS_ADD', res.data);
            });
        },
        Actions_GetMeasurementUnits({commit, state}) {
            if (!state.MeasurementUnits.length)
            {
                apiActions.GetMeasurementUnits().then(res => {
                    commit('ACTIONS_UPDATE_MEASUREMENT_UNITS', res.data);
                });
            }
        }
    }
};
