<template>
    <div class="Page">

      <font-awesome-icon v-if="UserIsAdmin" icon="plus" title="Add" class="btn btn-primary pull-right" @click="ShowRecipeModal" />
      <h1>Recipes</h1>

      <el-table stripe style="width: 100%" :data="Recipes">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
        >
        </el-table-column>
        <el-table-column :min-width="80" :width="140" fixed="right" label="Actions">
          <template v-slot:default="props">
            <div class="table-actions">
              <n-button
                  @click="handleView(props.$index, props.row)"
                  class="like"
                  type="info"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="eye" />
              </n-button>
              <n-button
                  v-if="UserIsAdmin"
                  @click="handleEdit(props.$index, props.row)"
                  class="edit"
                  type="warning"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="pencil" />
              </n-button>
              <n-button
                  v-if="UserIsAdmin"
                  @click="handleDelete(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="trash-can" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <modal :class="{show:modals.Recipe.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseRecipeModal">
        <template v-slot:header>
          <h4 class="title title-up">{{ modals.Recipe.title }}</h4>
        </template>
        <Recipe
            :Recipe="modals.Recipe.Recipe"
            :InsertNew="modals.Recipe.InsertNew"
            @CloseRecipeModal="CloseRecipeModal"
        ></Recipe>
      </modal>

    </div>
</template>
<script>
import { Modal } from "@/components";
import { ElTable, ElTableColumn } from "element-plus";
import Recipe from '../Components/AddEditRecipe';

export default {
    name: 'Recipes',
    components: {
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        Modal,
        Recipe
    },
    data() {
        return {
            selectedTags: [],
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'name',
                    label: 'Name',
                    minWidth: 100,
                }
            ],
            modals: {
                Recipe: {
                    show: false,
                    title: 'Insert New Recipe',
                    InsertNew: true,
                    Recipe: {}
                }
            }
        };
    },
    computed: {
        Recipes() {
            return this.$store.getters.Recipes_GetAll();
        },
        Pagination() {
            return this.$store.getters.Recipes_GetPagination();
        },
        UserIsAdmin() {
            return this.$store.getters.UsersGetRole().level === 1;
        }
    },
    mounted() {
        this.GetRecipes();
    },
    methods: {
        GetRecipes() {
            this.$store.dispatch('Recipes_UpdateAll');
        },
        handleView(index, row) {
            this.$store.dispatch('Recipes_Find', row.id);
        },
        handleEdit(index, row) {
            this.modals.Recipe.Recipe = row;
            this.modals.Recipe.title = 'Edit Recipe';
            this.modals.Recipe.InsertNew = false;
            this.ShowRecipeModal();
        },
        handleDelete(index, row) {
            this.$store.dispatch('Recipes_Remove', row.id);
        },
        ShowRecipeModal() {
            this.modals.Recipe.show = true;
        },
        CloseRecipeModal() {
            this.modals.Recipe.Recipe = {};
            this.modals.Recipe.title = 'Insert New Recipe';
            this.modals.Recipe.InsertNew = true;
            this.modals.Recipe.show = false;
        }
    }
};
</script>
