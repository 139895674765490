<template>
  <card class="card-user">
    <div slot="image" class="image">
<!--      <img src="/img/favicon.svg" alt="..." />-->
    </div>
    <div>
      <div class="author">
        <span>
          <img class="avatar border-gray" :src="Gravatar" alt="..." />
          <h5 class="title">{{ Name }}</h5>
        </span>
        <p class="description">{{ User.username }}</p>
      </div>
      <div v-if="false" class="form-group has-label">
        <label>Roles</label>
        <el-select multiple class="select-info" size="large" v-model="RolesSelected" placeholder="Multiple Select">
          <el-option v-for="(option, k) in Roles" class="select-info" :value="k" :label="option" :key="option"></el-option>
        </el-select>
      </div>
      <div v-else class="form-group has-label text-center">
        <span v-for="Role in User.Roles" :key="Role.id" style="margin: 0 4px">{{Role.name}}</span>
      </div>
      <div class="form-group has-label text-center">{{ User.email }}</div>
    </div>
    <!--<div slot="footer" class="button-container">
      <n-button href="#" type="neutral" icon round size="lg">
        <i class="fa fa-facebook-square"></i>
      </n-button>
      <n-button href="#" type="neutral" icon round size="lg">
        <i class="fa fa-twitter"></i>
      </n-button>
      <n-button href="#" type="neutral" icon round size="lg">
        <i class="fa fa-google-plus-square"></i>
      </n-button>
    </div>-->
  </card>
</template>
<script>
import { ElOption, ElSelect } from "element-plus";

export default {
  components: { [ElOption.name]: ElOption, [ElSelect.name]: ElSelect },
  data() {
    return {
      Roles: ['Model', 'Dice', 'Play'],
      RolesSelected: [0]
    };
  },
  computed: {
    User() {
      return this.$store.state.Users;
    },
    Gravatar() {
      return 'https://www.gravatar.com/avatar/'+this.User.gravatar
    },
    Name() {
      if (this.User.Roles.length) {
        switch (this.User.Roles[0].level) {
          case 3:
            return this.User.Employee.first_name+' '+this.User.Employee.last_name;
        }
      }
      return this.User.name;
    }
  }
};
</script>
<style></style>
