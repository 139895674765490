<template>
    <div>
        <div class="logo-container my-5">
            <img src="/img/favicon.svg" alt="Logo" />
        </div>
        <div class="col-11 ml-auto mr-auto">
            <div v-if="SuccessMessage" class="alert alert-success">{{ SuccessMessage }}</div>
            <div v-if="Error" class="alert alert-danger">{{ Error }}</div>
            <div v-if="ShowResendLink"><span class="btn btn-primary" @click="ResendVerifyEmail">Resend Verification Email</span></div>
        </div>
    </div>
</template>
<script>
import apiUsers from '../../api/users';

export default {
    name: "VerifyEmail",
    data() {
        return {
            SuccessMessage: '',
            Error: '',
            ShowResendLink: false
        };
    },
    mounted() {
        let Data = {...this.$route.params, query: this.$route.query};
        apiUsers.VerifyEmail(Data).then(res => {
            this.$notify({
                message: res.data.Success,
                timeout: 0,
                type: 'success'
            });
            this.$router.push({ name: 'Dashboard' });
        }).catch(Error => {
            if (Error.response.status === 403)
            {
                this.Error = Error.response.data.message;
                this.ShowResendLink = true;
            }
        });
    },
    methods: {
        ResendVerifyEmail() {
            this.Error = '';
            apiUsers.ResendVerifyEmail().then(res => {
                this.SuccessMessage = res.data.Success;
            });
        }
    }
};
</script>
