<template>
  <div id="pageEditContent" class="Page">
    <span class="btn btn-prev entities pull-right" title="back" @click="router.go(navBack)">&leftarrow;</span>
    <h1>{{ Course.title }} / {{ Subject.title }} - Content</h1>
    <div v-if="Success" class="alert alert-success">{{ SuccessMessage }}</div>
    <div v-if="Error" class="alert alert-danger">{{ Error }}</div>
    <form @submit="onSubmit" class="col-12 col-lg-10 col-xl-8 mt-5 mr-auto ml-auto">
      <card headerBGColor="orange" title="Content">

        <vee-input
            name="title"
            type="text"
            :value="Content.title"
            inputClasses="no-border form-control-lg"
            placeholder="Title"
            :ref="refInputTitle"
            :key="Content.title"
        />
        <vee-input
            name="sequence"
            type="number"
            :value="''+Content.sequence"
            inputClasses="no-border form-control-lg"
            placeholder="Sequence"
            :ref="refInputSequence"
            :key="Content.sequence"
        />
        <QuillEditor theme="snow" toolbar="full" v-model:content="Content.content" contentType="html" @ready="QuillReady" />
        <vee-input
            name="content"
            type="hidden"
            :value="''+Content.content"
            :ref="refInputContent"
            :key="Content.content"
        />

        <template v-slot:footer>
          <div class="text-center">
            <n-button native-type="submit" type="primary" :disabled="isSubmitting">
              Save
              <font-awesome-icon v-if="isSubmitting" class="loading" icon="arrows-rotate" />
            </n-button>
          </div>
        </template>

      </card>
    </form>
  </div>
</template>
<script>
import { VeeInput, Notification } from "@/components";
import { useForm } from "vee-validate";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import * as Yup from "yup";
import { QuillEditor, Quill } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useToast } from "vue-toastification";

import apiContents from '../../api/contents';

export default {
    name: "Content Modify",
    components: {
        VeeInput,
        QuillEditor
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        let navBack = ref(-1);

        // Course Info
        let CourseID = typeof route.params.CourseID === 'undefined' ? 0 : parseInt(route.params.CourseID);
        let Course = computed(() => store.getters.Courses_GetByID(CourseID));
        if (CourseID && Course.value.id === 0) { store.dispatch('Courses_GetByID', CourseID); }

        // Subject Info
        let SubjectID = typeof route.params.SubjectID === 'undefined' ? 0 : parseInt(route.params.SubjectID);
        let Subject = computed(() => store.getters.CoursesSubjects_GetByID(SubjectID));

        // Content Info
        let ContentID = typeof route.params.ContentID === 'undefined' ? 0 : parseInt(route.params.ContentID);
        let Content = computed(() => store.getters.CoursesSubjectsContents_GetByID(ContentID));
        if (ContentID && Content.value.id === 0) { store.dispatch('CoursesSubjectsContents_GetByID', {CourseID, SubjectID, ContentID}); }

        // The Form
        let SuccessMessage = ref('Content has been saved');
        let Success = ref(false);
        let Error = ref('');
        let refInputTitle = ref(null);
        let refInputSequence = ref(null);
        let refInputContent = ref(null);
        const schema = Yup.object().shape({
            title: Yup.string().required().label("The Content Title"),
            sequence: Yup.string().required().label("The Sequence"),
            content: Yup.string().required().label("The Content"),
        });
        const { handleSubmit, isSubmitting, setFieldError } = useForm({ validationSchema: schema });
        const onSubmit = handleSubmit (async values => {
            Success.value = false;
            Error.value = '';
            console.log({values});
            if (ContentID)
            {
                apiContents.Update(CourseID, SubjectID, ContentID, values).then(res => {
                    store.dispatch('CoursesSubjectsContents_UpdateContent', res.data);
                    Success.value = true;
                    runToast(SuccessMessage, 'success');
                }).catch(error => {
                    console.log({error});
                    processErrors(error);
                });
            }
            else
            {
                apiContents.Add(CourseID, SubjectID, values).then((response) => {
                    Success.value = true;
                    navBack.value = -2;
                    runToast(SuccessMessage, 'success');
                    router.push({name: 'Edit Content', params: {CourseID, SubjectID, ContentID: response.data.id}});
                }).catch(error => {
                    console.log({error});
                    processErrors(error);
                });
            }
        });

        // Catch server side errors
        const processErrors = (error) => {
            let ErrorCodes = [400, 500];
            if (ErrorCodes.indexOf(error.response.status) > -1)
            {
                Error.value = 'Something went wrong. Try again later.';
                runToast(Error.value, 'danger');
            }
            if (error.response.status === 422) {
                for (let x in error.response.data.errors)
                {
                    setFieldError(x, error.response.data.errors[x][0]);
                    runToast(error.response.data.errors[x][0], 'danger');
                }
            }
        };

        // Toasty!
        const toast = useToast();
        const runToast = (text, type) => {
            const icon = "now-ui-icons ui-1_bell-53";
            const content = {
                component: Notification,
                props: { icon, text, type },
            };
            toast(content, {
                hideProgressBar: true,
                icon: false,
                closeButton: false,
                position: 'top-right',
            });
        };

        /*onMounted(() => {
            // Quill
            const Cue = Quill.getContent();
            console.log({Quill, Cue});
        });*/
        const QuillReady = quill => {
            // const Cue = quill.getContent();
            console.log({quill});
            console.log({Content: quill.container.innerHTML});
        };

        return {
            navBack,
            SuccessMessage,
            Success,
            Error,
            onSubmit,
            isSubmitting,
            router,
            Course,
            Subject,
            Content,
            refInputTitle,
            refInputSequence,
            refInputContent,
          QuillReady
        };
    },
};
</script>
<style lang="scss">
#pageContent
{
    header h2 { text-align: center; }
}
</style>