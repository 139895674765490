<template>
    <div class="user user__menu">
        <div class="photo">
            <img :src="Gravatar" alt="avatar" />
        </div>
        <div class="info">
            <a data-toggle="collapse" :aria-expanded="!isClosed" @click.prevent="toggleMenu" href="#">
                <span>
                  {{ user.username }}
                  <b class="caret"></b>
                </span>
            </a>
            <div class="clearfix"></div>
            <div>
                <transition name="collapse">
                    <ul class="nav user-menu__nav" v-show="!isClosed">
                        <slot>
                            <!--<li>
                                <a href="#vue">
                                    <span class="sidebar-mini-icon">MP</span>
                                    <span class="sidebar-normal">My Profile</span>
                                </a>
                            </li>-->
                            <li>
                                <router-link to="/Profile">
                                    <span class="sidebar-mini-icon">EP</span>
                                    <span class="sidebar-normal">Edit Profile</span>
                                </router-link>
                            </li>
                            <!--<li>
                                <a href="#vue">
                                    <span class="sidebar-mini-icon">S</span>
                                    <span class="sidebar-normal">Settings</span>
                                </a>
                            </li>-->
                            <li>
                                <a href="#Logout" @click.prevent="$emit('logout')">
                                    <span class="sidebar-mini-icon">LO</span>
                                    <span class="sidebar-normal">Logout</span>
                                </a>
                            </li>
                        </slot>
                    </ul>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
// import { CollapseTransition } from "vue2-transitions";

export default {
    name: "user-menu",
    components: {
        // CollapseTransition
    },
    props: {
        user: {
            type: Object
        },
        image: {
            type: String,
            default: "img/james.jpg"
        }
    },
    data() {
        return {
            isClosed: true
        };
    },
    computed: {
        Gravatar() {
            return 'https://www.gravatar.com/avatar/'+this.user.gravatar
        }
    },
    methods: {
        toggleMenu() {
            this.isClosed = !this.isClosed;
        }
    }
};
</script>
<style>
.user__menu ul.user-menu__nav {
    margin-top: 0;
    padding-top: 20px;
}
</style>
