<template>
  <section id="pageContent" class="Page">
    <header>
      <span class="btn btn-prev entities pull-right" title="back" @click="router.go(navBack)">&leftarrow;</span>
      <h1>{{ Course.title }} / {{ Subject.title }}</h1>
    </header>
    <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <card headerBGColor="orange" :title="Content.title">
        <div v-html="Content.content"></div>
      </card>
    </div>
  </section>
</template>
<script>
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';

export default {
    name: "Content",
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        let navBack = ref(-1);

        // Course Info
        let CourseID = typeof route.params.CourseID === 'undefined' ? 0 : parseInt(route.params.CourseID);
        let Course = computed(() => store.getters.Courses_GetByID(CourseID));
        if (CourseID && Course.value.id === 0) { store.dispatch('Courses_GetByID', CourseID); }

        // Subject Info
        let SubjectID = typeof route.params.SubjectID === 'undefined' ? 0 : parseInt(route.params.SubjectID);
        let Subject = computed(() => store.getters.CoursesSubjects_GetByID(SubjectID));

        // Content Info
        let ContentID = typeof route.params.ContentID === 'undefined' ? 0 : parseInt(route.params.ContentID);
        let Content = computed(() => store.getters.CoursesSubjectsContents_GetByID(ContentID));
        if (ContentID && Content.value.id === 0) { store.dispatch('CoursesSubjectsContents_GetByID', {CourseID, SubjectID, ContentID}); }

        return {
            navBack,
            router,
            Course,
            Subject,
            Content,
          state: store.state.Courses
        };
    },
};
</script>