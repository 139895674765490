import Api from 'src/api/API';

export default {
    Index() {
        return Api.get('/Auth/Courses');
    },
    Add(data) {
        return Api.post('/Auth/Courses', data);
    },
    Update(CourseID, data) {
        return Api.patch('/Auth/Courses/'+CourseID, data);
    },
    Delete(CourseID) {
        return Api.delete('/Auth/Courses/'+CourseID);
    },
    FindByID(CourseID) {
        return Api.get('/Auth/Courses/'+CourseID);
    }
};
