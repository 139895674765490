<template>
  <div v-if="!b41123" id="Maintenance-page" class="centered text-primary text-center">
    <h1>
      <font-awesome-icon class="loading" icon="gear" /><br>
      Maintenance Mode
    </h1>
    <p>The site is currently down for maintenance. We'll get things back up shortly.</p>
  </div>
  <div v-else id="Maintenance-page" class="centered text-primary text-center">
    <h1>
      <font-awesome-icon class="loading" icon="gear" /><br>
      Coming Soon!
    </h1>
    <p>The site is coming New Year's Day 2023</p>
  </div>
</template>

<script>

export default {
  computed: {
    b41123() {
      let now = new Date();
      let newYearsDay = new Date('2023-01-01');
      return now < newYearsDay;
    }
  }
};
</script>
<style lang="scss">
#Maintenance-page {
  .full-page > .content {
    min-height: calc(100vh - 160px);
  }
  .not-found-icon {
    margin-right: 10px;
  }
  &.centered {
    h1,
    i {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    display: flex;
    min-height: calc(100vh - 310px);
    //padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .loading { animation: rotate-loading 2s infinite linear; }
}
</style>
