import DashboardLayout from "src/Modules/Users/Layouts/DashboardLayout.vue";

// Page Headers
import DefaultHeader from "src/Modules/Users/Pages/DefaultHeader";

// Pages
import Actions from 'src/Modules/Actions/Pages/Actions';
import QuickActions from 'src/Modules/Actions/Pages/QuickActions';
import Progress from './Pages/Progress';

const routes = [
    {
        path: '/Actions',
        component: DashboardLayout,
        redirect: 'Actions',
        children: [
            {
                path: '/Actions/',
                name: 'Actions',
                components: { default: Actions, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: '/Actions/Progress',
                name: 'Actions Progress',
                components: { default: Progress, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: '/Actions/Quick',
                name: 'Quick Actions',
                components: { default: QuickActions, header: DefaultHeader },
                meta: { authOnly: true }
            }
        ]
    },
];

export default routes;
