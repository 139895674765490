import Api from 'src/api/API';

export default {
    Index() {
        return Api.get('/Auth/Actions');
    },
    Add(data) {
        return Api.post('/Auth/Actions', data);
    },
    Update(ActionID, data) {
        return Api.patch('/Auth/Actions/'+ActionID, data);
    },
    Delete(ActionID) {
        return Api.delete('/Auth/Actions/'+ActionID);
    },
    FindByID(ActionID) {
        return Api.get('/Auth/Actions/FindByID/'+ActionID);
    },

    GetDistinctActions() {
        return Api.get('/Auth/Actions/GetDistinctActions');
    },
    CalculateProgress(data) {
        return Api.post('/Auth/Actions/CalculateProgress', data);
    },

    GetQuickActions() {
        return Api.get('/Auth/Actions/GetQuickActions');
    },

    GetMeasurementUnits() {
        return Api.get('/Auth/Actions/GetMeasurementUnits');
    }
};
