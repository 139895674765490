<template>
    <div id="eleStripeElements" class="text-center">
        <div class="text-danger">{{ err }}</div>
        <div ref="seCard" class="form-control bg-white"></div>
        <span class="btn btn-primary btn-round btn-lg" @click="submitCC" :class="{disabled}">
            Subscribe
            <font-awesome-icon v-if="isSubmitting" class="loading" icon="arrows-rotate" />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        interval: {
            type: String,
            default: 'month'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        preSubmit: {
            type: Function,
            default: () => { return true; }
        }
    },
    data() {
        return {
            stripe: Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY),
            now: new Date(),
            newYear: new Date('2023-01-01T00:00:00'),
            intervalNow: 0,
            clientSecret: '',
            err: '',
            elesCard: '',
            submitting: false
        }
    },
    computed: {
        before1123() {
            return this.now < this.newYear;
        },
        discountTimeLeft() {
            if (this.before1123) {
                let difference = this.newYear.getTime()-this.now.getTime();
                let days = Math.floor(difference/(1000*60*60*24));
                let hours = Math.floor((difference%(1000*60*60*24))/(1000*60*60));
                let minutes = Math.floor((difference%(1000*60*60))/(1000*60));
                let seconds = Math.floor((difference%(1000*60))/1000);
                return days+' Days, '+hours+' Hours, '+minutes+' Minutes, '+seconds+' Seconds';
            } else {
                return '0 Days, 0 Hours, 0 Minutes, 0 Seconds';
            }
        },
        isSubmitting: {
            get() {
                return !this.disabled ? false : this.submitting;
            },
            set(newValue) {
                this.submitting = newValue;
            }
        }
    },
    mounted() {
        if (this.elesCard === '') {
          const elements = this.stripe.elements();
          this.elesCard = elements.create('card');
          this.$refs.seCard.innerHTML = '';
          this.elesCard.mount(this.$refs.seCard);
          this.elesCard.on('change', event => { this.err = event.error ? event.error.message : ''; });
        } else {
          if (!this.$refs.seCard.innerHTML.length) {
            this.err = '';
            this.elesCard.mount(this.$refs.seCard);
          }
        }
        if (!this.intervalNow) { this.intervalNow = setInterval(() => { this.now = new Date; }, 1000); }
    },
    methods: {
        submitCC() {
            this.isSubmitting = true;
            if (this.preSubmit(this)) { this.confirmPayment(); }
        },
        confirmPayment() {
            return this.stripe.confirmCardPayment(this.clientSecret, {
                payment_method: { card: this.elesCard }
            }).then(res => {
                this.isSubmitting = false;
                if (res.error) {
                    this.err = res.error.message;
                    return false;
                } else {
                    return true;
                }
            }).catch(err => {
                this.isSubmitting = false;
                console.log({err});
                return false;
            });
        }
    }
};
</script>

<style lang="scss">
#eleStripeElements
{
    .loading { animation: rotate-loading 2s infinite linear; }
}
</style>