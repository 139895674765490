<template>
  <div class="Page Dashboard">
    <div v-if="false" class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-primary">
                      <i class="now-ui-icons ui-2_chat-round"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="853"></animated-number>
                    </h3>
                    <h6 class="stats-title">Messages</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-success">
                      <i class="now-ui-icons business_money-coins"></i>
                    </div>
                    <h3 class="info-title">
                      <small>$</small>
                      <animated-number :value="3521"></animated-number>
                    </h3>
                    <h6 class="stats-title">Today Revenue</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-info">
                      <i class="now-ui-icons users_single-02"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="562"></animated-number>
                    </h3>
                    <h6 class="stats-title">Customers</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-danger">
                      <i class="now-ui-icons objects_support-17"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="353"></animated-number>
                    </h3>
                    <h6 class="stats-title">Support Requests</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card class="card-chart" no-footer-line>
          <template v-slot:header>
            <div>
              <font-awesome-icon icon="plus" title="Add" class="btn btn-primary pull-right" @click="ShowActionModal({quick: true}, true)" />
              <font-awesome-icon class="pull-right Refresh" :class="{loading: QuickActionsLoading}" icon="arrows-rotate" @click="GetQuickActions" />
              <!--              <h5 class="card-category">Active Users</h5>-->
              <h2 class="card-title">Quick Actions</h2>
            </div>
          </template>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary" @click="ShowActionModal" >Blank</button>
              <button
                  v-for="QA in QuickActions" :key="'QA'+QA.id" class="btn btn-primary" :class="{'btn-primary': !QA.started, 'btn-success': QA.started}"
                  @click="ShowActionModal(QA)"
              >{{ QA.action }}</button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <modal :class="{show:modals.QuickAction.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseActionModal">
      <template v-slot:header>
          <h4 class="title title-up">{{ modals.QuickAction.title }}</h4>
      </template>
      <ActionAddEdit
          :ActionID="modals.QuickAction.ActionID"
          :QuickAction="modals.QuickAction.Action"
          :CreateQuickAction="modals.QuickAction.CreateQuickAction"
          :InsertNew="modals.QuickAction.InsertNew"
          @CloseActionAddEditModal="CloseActionModal"
      ></ActionAddEdit>
    </modal>

  </div>
</template>
<script>
import {
  Card,
  Table as NTable,
  AnimatedNumber,
  Progress as NProgress,
  AsyncWorldMap,
  Modal
} from "@/components";
import ActionAddEdit from 'src/Modules/Actions/Components/Action_AddEdit';

export default {
  components: {
    Card,
    NTable,
    AnimatedNumber,
    NProgress,
    AsyncWorldMap,
    ActionAddEdit,
    Modal
  },
  data() {
    return {
      progress: 0,
      modals: {
        QuickAction: {
          show: false,
          title: 'Insert New Action',
          InsertNew: true,
          CreateQuickAction: false,
          Action: { placeholder: true },
          ActionID: 0
        }
      }
    };
  },
  computed: {
      QuickActions() {
          return this.$store.getters.Actions_GetQuickActions();
      },
      QuickActionsLoading() {
          return this.$store.state.Actions.QuickActionsLoading;
      }
  },
  mounted() {
      this.GetQuickActions();
  },
  methods: {
    GetQuickActions() {
        this.$store.dispatch('Actions_UpdateQuickActions');
    },
    ShowActionModal(QuickAction, CreateQuickAction) {
      if (QuickAction.started)
      {
          console.log({QuickAction});
          let Action = QuickAction.quick_actions_started[0];
          this.modals.QuickAction.ActionID = Action.id;
          // this.modals.QuickAction.Action = Action;
          this.modals.QuickAction.title = 'Edit Action';
          this.modals.QuickAction.InsertNew = false;
      }
      else
      {
          this.modals.QuickAction.CreateQuickAction = CreateQuickAction || false;
          this.modals.QuickAction.Action = QuickAction || { placeholder: true };
          this.modals.QuickAction.title = CreateQuickAction ? 'Create Quick Action Button' : 'Insert New Action';
      }
      this.modals.QuickAction.show = true;
    },
    CloseActionModal() {
      this.modals.QuickAction.CreateQuickAction = false;
      this.modals.QuickAction.InsertNew = true;
      this.modals.QuickAction.Action = { placeholder: true };
      this.modals.QuickAction.title = 'Insert New Action';
      this.modals.QuickAction.show = false;
      this.modals.QuickAction.ActionID = 0;
    }
  }
};
</script>
<style lang="scss">
.Page.Dashboard {
  .card-header .btn { margin: 0; }
  .Refresh { padding: 10px; }
}
</style>
