import Api from 'src/api/API';

export default {
    Index() {
        return Api.get('/Auth/Subscriptions');
    },
    Create(data) {
        return Api.post('Auth/Subscriptions', data);
    },
    Update(SubscriptionID, data) {
        return Api.patch('/Auth/Subscriptions/'+SubscriptionID, data);
    },
    Delete(SubscriptionID) {
        return Api.delete('/Auth/Subscriptions/'+SubscriptionID);
    },
};
