<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input
          @input="(event) => $emit('update:checked', event.target.checked)"
        :id="cbId"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        :checked="modelValue"
      />
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: "n-checkbox",
  emits: ["update:modelValue", 'update:checked'],
  props: {
    modelValue: [Array, Boolean],
    disabled: Boolean,
    inline: Boolean,
    hasError: Boolean,
  },
  data() {
    return {
      cbId: ""
    };
  },
  computed: {
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return ``;
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  }
};
</script>
