<template>
    <form @submit.prevent="save" class="formAction">

        <div v-if="Error" class="alert alert-danger" v-html="Error"></div>

        <div v-if="ActionLoading" style="text-align: center">
            <font-awesome-icon class="Refresh loading" icon="arrows-rotate" />
        </div>
        <div v-else class="row">
            <div class="col-12 col-md-4">
                <fg-input type="text" label="Action" placeholder="Action" v-model:value="formInputs.action"></fg-input>
            </div>
            <div class="col-12 col-md-4 checkbox-radios">
              <label>Unit of Measurement</label>
              <Multiselect v-model="formInputs.unit_of_measurement" :options="MeasurementUnits" @change="changeUnitOfMeasurement" />
            </div>
            <div class="col-12 col-md-4" v-if="[5,6].indexOf(formInputs.unit_of_measurement)===-1">
                <fg-input type="number" step=".01" :label="labelUnitOfMeasurement" placeholder="Measurement" v-model:value="formInputs.measurement"></fg-input>
            </div>
            <div class="col-12 col-md-4" v-else>
                <label>{{ labelDesiredTime }}</label>
                <el-time-picker v-model="DesiredTime" :placeholder="labelDesiredTime" :format="formatDesiredTime" />
            </div>
            <div v-if="!CreateQuickAction" class="col-12 col-md-4">
                <label>{{ formInputs.is_timed ? 'Start Time' : 'Start / Stop' }}</label>
                <checkbox id="StartStopLabel"
                          v-if="ActionTimeLabel === 'Stop Time'"
                          v-model:model-value="formInputs.is_timed"
                          :inline="true"
                          v-on:update:checked="ToggleStartStop"
                          class="mt-0 pull-right"
                >Timer</checkbox><br>
                <el-date-picker
                    v-if="ActionTimeLabel === 'Stop Time'"
                    type="datetime"
                    v-model:model-value="Dates.Start"
                    format="MM/DD/YYYY h:mm a"
                    placeholder="Start Time">
                </el-date-picker>
                <checkbox v-else id="StartStop" v-model:model-value="formInputs.is_timed" :inline="true" v-on:update:checked="ToggleStartStop">Timer</checkbox>
            </div>
            <div v-if="!CreateQuickAction" class="col-12 col-md-4 checkbox-radios">
                <label>{{ ActionTimeLabel }}</label>
                <el-date-picker
                    v-if="ActionTimeLabel === 'Action Time'"
                    type="datetime"
                    v-model:model-value="Dates.Action"
                    format="MM/DD/YYYY h:mm a"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Action Time">
                </el-date-picker>
                <el-date-picker
                    v-if="ActionTimeLabel === 'Start Time'"
                    type="datetime"
                    v-model:model-value="Dates.Start"
                    format="MM/DD/YYYY h:mm a"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Start Time">
                </el-date-picker>
                <el-date-picker
                    v-if="ActionTimeLabel === 'Stop Time'"
                    type="datetime"
                    v-model:model-value="Dates.Stop"
                    format="MM/DD/YYYY h:mm a"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="Stop Time">
                </el-date-picker>
            </div>
            <div class="col-12 col-md-4">
                <!-- ToDo: width: 768px the tags are extending outside the box -->
              <label>Tags</label>
              <Multiselect
                  v-model="formInputs.Tags"
                  mode="tags"
                  placeholder="Select your Tags"
                  :options="options"
                  noOptionsText="Create Tags by typing a tag name and hit enter. You can create/select multiple tags."
                  noResultsText="Looks like you are out of options. Create more. Once you are done typing the tag name, hit enter to create it."
                  :searchable="true"
                  :createTag="true"
                  @tag="CreateTag"
                  :appendNewTag="false"
                  :appendNewOption="false"
                  :key="'TagsKey'+NewOptionKey"
              />
            </div>
            <div v-if="!FromQuickAction" class="col-12 col-md-4">
                <label>&nbsp;</label>
                <checkbox id="Quick" v-model:model-value="formInputs.quick" :disabled="CreateQuickAction">Make Quick Action Button</checkbox>
            </div>
            <div v-if="CreateQuickAction" class="col-12 col-md-4">
                <label>&nbsp;</label>
                <checkbox id="IsTimed" v-model:model-value="formInputs.is_timed">Is Timed</checkbox>
            </div>
        </div>

        <div class="row" v-if="!ActionLoading">
            <div class="col-12 mt-3">
              <Multiselect
                  v-model="Recipe"
                  placeholder="Insert A Recipe"
                  :options="Recipes"
                  valueProp="id"
                  label="name"
                  noOptionsText="Type to search for recipes"
                  noResultsText="Looks like you are out of options."
                  :searchable="true"
                  @search-change="searchRecipes"
                  @select="AddRecipe"
                  :key="'RecipesKey'+NewRecipeOptionKey"
                  class="selectRecipe col-lg-4"
              />
                <label>Description</label><br>
                <QuillEditor theme="snow" toolbar="full" v-model:content="formInputs.description" contentType="html" :key="keyTA" />
            </div>
        </div>

        <div class="row" v-if="!ActionLoading">
            <Images divClasses="col-12 text-center mt-3" v-model:Images="formInputs.images" @update:Images="UpdateImages"></Images>
        </div>

        <div class="row" v-if="!ActionLoading">
            <div class="col-12 text-center">
                <button class="btn btn-primary">{{ AddEdit }} Action{{ ActionID ? ' #'+ActionID : '' }}</button>
            </div>
        </div>

    </form>
</template>
<script>
import { QuillEditor, Quill } from '@vueup/vue-quill';
import { ElDatePicker, ElTimePicker } from "element-plus";
import { format, parseISO } from "date-fns";
import { Checkbox, Radio } from "@/components/index";
import { Switch } from "@/components";
import Multiselect from '@vueform/multiselect';
import Images from "@/components/Inputs/Images";

import apiActions from '@/Modules/Actions/api/actions';
import apiRecipes from '@/Modules/Health/api/recipes';
import apiTags from '@/Modules/Tags/api/tags';

let FormInputs = () => {
    return {
        users_id: 0,
        action: '',
        description: 'D Script Shun',
        unit_of_measurement: '',
        measurement: '',
        desired_time: '',
        is_timed: false,
        start: null,
        stop: null,
        action_time: format(new Date(), 'yyyy-MM-dd HH:mm'),
        quick: false,
        quick_id: 0,
        Tags: [],
        images: [
            /*"https://drive.google.com/uc?export=view&id=1TBhEzhJN5rlwup2-Gv7u2WuzmAP1vV_W",
            "https://www.chess.com/bundles/web/images/web/board-puzzles.4a54c49f@2x.png",
            "https://lichess1.org/assets/_AdloW8/images/user-blog-default.png",
            "https://www.chess.com/bundles/web/images/offline-play/standardboard.1d6f9426.png"*/
        ]
    };
};

export default {
    components: {
        [Switch.name]: Switch,
        [ElDatePicker.name]: ElDatePicker,
        [ElTimePicker.name]: ElTimePicker,
        QuillEditor,
        Checkbox, Radio,
        Multiselect,
        Images
    },
    data() {
        return {
            ActionLoading: false,
            formInputs: FormInputs(),
            Error: '',
            options: [],
            NewOptionKey: 0,
            Dates: {
                Action: null,
                Start: null,
                Stop: null
            },
            DesiredTime: null,
            keyTA: 0,
            Recipe: '',
            NewRecipeOptionKey: 0
        };
    },
    props: {
        ActionID: {
            type: Number,
            default: 0
        },
        QuickAction: {
            type: Object,
            default() { return { placeholder: true }; }
        },
        CreateQuickAction: {
            type: Boolean,
            default: false
        },
        InsertNew: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        AddEdit() {
            return this.InsertNew ? 'Insert New' : 'Update';
        },
        Action() {
            return this.ActionID ? this.$store.getters.Actions_GetByID(this.ActionID) : '';
        },
        UserID() {
            return this.$store.state.Users.id;
        },
        Tags() {
            return this.$store.getters.Tags_GetOptions();
        },
        FromQuickAction() {
            return typeof this.QuickAction !== 'undefined' && !this.CreateQuickAction;
        },
        ActionTimeLabel() {
            return this.formInputs.is_timed ? 'Stop Time' : 'Action Time';
        },
        MeasurementUnits() {
            return this.$store.state.Actions.MeasurementUnits.map(Unit => {
                return {
                    value: Unit.id,
                    label: Unit.name
                };
            });
        },
        labelUnitOfMeasurement() {
            let Option = this.MeasurementUnits.filter(Unit => Unit.value === this.formInputs.unit_of_measurement);
            return Option.length ? 'Measurement ('+Option[0].label+')' : 'Measurement';
        },
        labelDesiredTime() {
            return this.formInputs.unit_of_measurement === 5 ? 'Desired Time of Day' : 'Desired Time (Hours:Minutes:Seconds)';
        },
        formatDesiredTime() {
            return this.formInputs.unit_of_measurement === 5 ? 'h:mm a' : 'HH:mm:ss';
        },
        Recipes() {
            return this.$store.getters.Recipes_GetAll();
        }
    },
    mounted() {
        this.SetFormInputs();

        this.$store.dispatch('Actions_GetMeasurementUnits');

        apiTags.Index().then(res => {
            this.$store.dispatch('Tags_UpdateAll', res.data);
        });
    },
    methods: {
        UpdateImages(Images) {
            this.formInputs.images = Images;
        },
        SetFormInputs() {
            this.Error = '';
            this.formInputs = FormInputs();
            let ThisIsAQuickAction = typeof this.QuickAction.placeholder === 'undefined';
            if (this.ActionID || ThisIsAQuickAction)
            {
                let Action = ThisIsAQuickAction ? this.QuickAction : this.Action;
                let Now = new Date();
                this.formInputs.users_id = this.UserID;
                this.formInputs.action = Action.action || '';
                this.formInputs.is_timed = !!Action.is_timed || false;
                this.DesiredTime = Action.desired_time ? new Date(Now.getFullYear()+'-'+(Now.getMonth()+1)+'-'+Now.getDate()+' '+Action.desired_time) : null;
                this.Dates.Start = Action.start ? new Date(Action.start) : (Action.start || Action.is_timed ? Now : null);
                this.Dates.Stop = Action.stop ? new Date(Action.stop) : (Action.start ? Now : null);
                this.Dates.Action = Action.action_time ? new Date(Action.action_time) : (this.InsertNew ? Now : null);
                this.formInputs.description = Action.description || '';
                this.formInputs.measurement = Action.measurement || '';
                this.formInputs.unit_of_measurement = Action.unit_of_measurement || '';
                this.formInputs.quick = this.FromQuickAction ? false : Action.quick;
                this.formInputs.quick_id = ThisIsAQuickAction ? Action.id : Action.quick_id;
                this.formInputs.images = typeof Action.images !== 'undefined' ? [...Action.images] : [];
                if (this.CreateQuickAction) { this.Dates.Action = null; }
                if (typeof Action.tags !== 'undefined') { this.formInputs.Tags = Action.tags.map(Tag => Tag.id); }
                this.ActionLoading = false;
            } else { this.ActionLoading = false; }
        },
        UpdateOptions() {
            this.options = this.$store.getters.Tags_GetOptions();
        },
        CreateTag(query) {
            apiTags.Add({users_id: this.UserID, title: query}).then(res => {
                let Tag = res.data;
                this.$store.dispatch('Tags_AddTag', Tag);
                this.GetNewTagIndex(query).then(index => {
                    this.UpdateOptions();
                    this.formInputs.Tags[index] = Tag.id;
                    this.NewOptionKey += 1;
                });
            });
        },
        GetNewTagIndex(query) {
            return new Promise(resolve => {
                setTimeout(() => {
                    let index = this.formInputs.Tags.indexOf(query);
                    resolve(index === -1 ? this.GetNewTagIndex(query) : index);
                }, 1);
            });
        },
        ToggleStartStop() {
            this.formInputs.is_timed = !this.formInputs.is_timed;
            let Action = typeof this.QuickAction.placeholder === 'undefined' ? this.QuickAction : this.Action;
            let Now = new Date();
            if (this.formInputs.is_timed)
            {
                this.Dates.Start = Action.start ? new Date(Action.start) : Now;
                this.Dates.Stop = Action.stop ? new Date(Action.stop) : (Action.start ? Now : null);
                this.Dates.Action = this.Dates.Start;
            }
            else
            {
                this.Dates.Start = null;
                this.Dates.Stop = null;
                this.Dates.Action = Action.action_time ? new Date(Action.action_time) : Now;
            }
        },
        changeUnitOfMeasurement(v) {
            this.formInputs.is_timed = v === 6;
        },
        searchRecipes(query) {
            if (query.length === 0)
            {
                this.$store.dispatch('Recipes_UpdateAll', query);
                this.NewRecipeOptionKey += 1;
            }
            if (query.length > 2) { this.$store.dispatch('Recipes_Search', query); }
        },
        AddRecipe(RecipeID) {
            let Recipe = this.Recipes.find(Rec => Rec.id === RecipeID);
            this.formInputs.description += '<a href="'+this.$store.state.Site.Domain+'/Recipes/'+RecipeID+'" data-recipe="'+RecipeID+'">'+Recipe.name+'</a>';
            this.Recipe = null;
        },
        save() {
            this.Error = '';
            if (this.InsertNew)
            {
                apiActions.Add(this.formInputs).then(res => {
                    if (!this.formInputs.quick) { this.$store.dispatch('Actions_AddAction', res.data); }
                    if (this.formInputs.quick || (this.formInputs.start && !this.formInputs.stop)) { this.$store.dispatch('Actions_UpdateQuickActions'); }
                    this.$emit('CloseActionAddEditModal');
                }).catch(e => {
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
            else
            {
                let ActionID = this.QuickAction.id || this.ActionID;
                apiActions.Update(ActionID, this.formInputs).then(res => {
                    if (!this.formInputs.quick) { this.$store.dispatch('Actions_UpdateAction', res.data); }
                    this.$store.dispatch('Actions_UpdateQuickActions');
                    this.$emit('CloseActionAddEditModal');
                }).catch(e => {
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
        },
        processErrors(Errors, Status) {
            let i = 0;
            if (Status === 422)
            {
                for (let x in Errors)
                {
                    this.Error += (i?'<br>':'')+Errors[x].join('<br>');
                    i++;
                }
            }
        }
    },
    watch: {
        Tags() {
            if (!this.NewOptionKey) {
                this.UpdateOptions();
                this.NewOptionKey += 1;
            }
        },
        ActionID() {
            if (this.ActionID)
            {
                this.ActionLoading = true;
                apiActions.FindByID(this.ActionID).then(res => {
                    this.$store.dispatch('Actions_AddAction', res.data);
                    this.SetFormInputs();
                });
            }
            else { this.SetFormInputs(); }
        },
        QuickAction() {
            this.keyTA += 1;
            if (typeof this.QuickAction.placeholder !== 'boolean') { this.SetFormInputs(); }
        },
        'Dates.Action'() {
            let Action = this.Dates.Action;
            if (typeof Action === 'string') { return false; }
            this.formInputs.action_time = Action ? format(Action, 'yyyy-MM-dd HH:mm') : null;
        },
        'Dates.Start'() {
            let Start = this.Dates.Start;
            if (typeof Start === 'string') { return false; }
            if (Start)
            {
                this.formInputs.start = format(Start, 'yyyy-MM-dd HH:mm');
                this.formInputs.action_time = format(Start, 'yyyy-MM-dd HH:mm');
            }
        },
        'Dates.Stop'() {
            let Stop = this.Dates.Stop;
            if (typeof Stop === 'string') { return false; }
            this.formInputs.stop = Stop ? format(Stop, 'yyyy-MM-dd HH:mm') : null;
        },
        DesiredTime() {
            let TOD = this.DesiredTime;
            if (typeof TOD === 'string') { return false; }
            this.formInputs.desired_time = TOD ? format(TOD, 'HH:mm:ss') : null;
        }
    }
};
</script>
<style lang="scss">
.formAction {
    .selectRecipe {
        float: right;
        min-height: 30px;
    }
        .ql-container { height: auto; min-height: 100px; }
}
</style>
