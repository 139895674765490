<template>
    <div class="Page">

      <h1>Quick Actions</h1>

      <el-table stripe style="width: 100%" :data="QuickActions">
        <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
            :formatter="TableDataFormatter"
        >
        </el-table-column>
        <el-table-column :min-width="48" :width="110" fixed="right" label="Actions">
          <template v-slot:default="props">
            <div class="table-actions">
              <n-button
                  @click="handleEdit(props.$index, props.row)"
                  class="edit"
                  type="warning"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="pencil" />
              </n-button>
              <n-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  round
                  icon
              >
                <font-awesome-icon icon="trash-can" />
              </n-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <modal :class="{show:modals.QuickAction.show}" :show-close="true" headerClasses="justify-content-center" type="Large" :CloseFunc="CloseActionModal">
          <template v-slot:header>
              <h4 class="title title-up">{{ modals.QuickAction.title }}</h4>
          </template>
          <ActionAddEdit
              :QuickAction="modals.QuickAction.Action"
              :CreateQuickAction="modals.QuickAction.CreateQuickAction"
              :InsertNew="modals.QuickAction.InsertNew"
              @CloseActionAddEditModal="CloseActionModal"
          ></ActionAddEdit>
      </modal>

    </div>
</template>
<script>
import { Modal } from "@/components";
import { ElTable, ElTableColumn } from "element-plus";
import ActionAddEdit from 'src/Modules/Actions/Components/Action_AddEdit';

export default {
    name: 'QuickActions',
    components: {
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        Modal,
        ActionAddEdit
    },
    data() {
        return {
            selectedTags: [],
            searchQuery: '',
            tableColumns: [
                {
                    prop: 'action',
                    label: 'Quick Action',
                    minWidth: 200,
                },
                {
                    prop: 'measurement',
                    label: 'Measurement',
                    minWidth: 100,
                },
                {
                    prop: 'unit_of_measurement',
                    label: 'Unit of Measurement',
                    minWidth: 120,
                },
            ],
            modals: {
                QuickAction: {
                    show: false,
                    title: 'Insert New Quick Action',
                    InsertNew: true,
                    CreateQuickAction: true,
                    Action: { placeholder: true }
                }
            }
        };
    },
    computed: {
        QuickActions() {
            return this.$store.getters.Actions_GetQuickActions();
        },
        UserID() {
            return this.$store.state.Users.id;
        },
        Tags() {
            return this.$store.getters.Tags_GetOptions();
        }
    },
    mounted() {
        this.$store.dispatch('Actions_GetMeasurementUnits');
        this.$store.dispatch('Actions_UpdateQuickActions');
        this.$store.dispatch('Tags_UpdateAll');
    },
    methods: {
        TableDataFormatter(row, column, cellValue, index) {
            let Data = '';
            switch (column.property)
            {
                case 'unit_of_measurement':
                    let UofM = this.$store.getters.Actions_GetMeasurmentUnitsByID(row.unit_of_measurement);
                    Data = UofM.name;
                break;
                case 'measurement':
                    Data = cellValue;
                    if ([5,6].indexOf(row.unit_of_measurement) > -1) { Data = row.desired_time; }
                break;
                default:
                    Data = cellValue;
            }
            return Data;
        },
        UpdateOptions() {
            this.options = this.$store.getters.Tags_GetOptions();
        },
        handleEdit(index, row) {
            this.modals.QuickAction.title = 'Edit Quick Action';
            this.modals.QuickAction.InsertNew = false;
            this.ShowActionModal(row);
        },
        handleDelete(index, row) {
            this.$store.dispatch('Actions_RemoveAction', row);
        },
        ShowActionModal(QuickAction, CreateQuickAction) {
            this.modals.QuickAction.Action = QuickAction;
            this.modals.QuickAction.show = true;
        },
        CloseActionModal() {
            this.modals.QuickAction.Action = { placeholder: true };
            this.modals.QuickAction.title = 'Create New Quick Action';
            this.modals.QuickAction.InsertNew = true;
            this.modals.QuickAction.show = false;
        }
    }
};
</script>
