import { config } from '@fortawesome/fontawesome-svg-core';
import apiUsers from 'src/Modules/Users/api/users';

let DefaultState = () => {
    return {
        AllRoles: [],
        AllUsers: [],
        Sites: [],
        Memberships: [],
        Roles: [],
        id: 0,
        username: 'NLI',
        name: 'Not Logged In',
        email: 'Not@Logged.in',
        /*address: '1 Happy Street',
        city: 'Joyville',
        state: 'Euphoria',
        zip: '12345',
        phone: '(123) 456-7890',*/
        gravatar: 'NotLoggedIn',
        SuccessMessage: '',
        ErrorMessage: ''
    };
};

export default {
    state: DefaultState(),
    getters: {
        userGetUsername: state => () => {
            return state.username;
        },
        userGetEmail: state => () => {
            return state.email;
        },
        UsersGetAll: state => () => {
            return state.AllUsers;
        },
        UsersGetRole: state => () => {
            return state.Roles.length ? state.Roles[0] : false;
        },
        UsersGetByID: state => (UserID) => {
            for (let x in state.AllUsers) { if (state.AllUsers[x].id === UserID) return state.AllUsers[x]; }
        },
        userGetSuccessMessage: state => () => {
            let Message = state.SuccessMessage;
            state.SuccessMessage = '';
            return Message;
        },
        profileInputs: state => () => {
            let User = {
                username: state.username,
                name: state.name,
                email: state.email
            };
            return User;
        },
        UsersGetAllRoles: state => () => {
            return state.AllRoles;
        },
        UsersGetUserAllowedRoles: state => () => {
            return state.AllRoles.filter(Role => !Role.level || (typeof state.Roles[0] !== 'undefined' && Role.level >= state.Roles[0].level));
        },
        UsersGetSites: state => () => {
            return state.Sites;
        },
        UsersGetMemberships: state => () => {
            return state.Memberships;
        }
    },
    mutations: {
        USER_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        USER_ADD(state, User) {
            state.AllUsers.push(User);
        },
        USER_UPDATE_USER(state, User) {
            for (let x in state.AllUsers) { if (state.AllUsers[x].id === User.id) { state.AllUsers[x] = User; } }
        },
        USER_REMOVE_USER(state, User) {
            for (let x in state.AllUsers) { if (state.AllUsers[x].id === User.id) { state.AllUsers.splice(x, 1); } }
        },
        USER_UPDATE(state, User) {
            state.id = User.id;
            state.username = User.username;
            state.name = User.name;
            state.email = User.email;
            state.gravatar = User.gravatar;
            state.Roles = User.Roles;
            state.Memberships = User.Memberships;
        },
        USER_UPDATE_ROLES(state, Roles) {
            state.Roles = Roles;
        },
        USER_UPDATE_USER_ROLES(state, Roles) {
            state.Roles = Roles;
        },
        USER_UPDATE_ALL_ROLES(state, Roles) {
            state.AllRoles = Roles;
        },
        USER_UPDATE_ALL(state, Users) {
            state.AllUsers = Users;
        },
        USER_UPDATE_SUCCESS(state, Message) {
            state.SuccessMessage = Message;
        },
        USER_UPDATE_SITES(state, sites) {
            state.Sites = sites;
        }
    },
    actions: {
        User_ResetState({commit}) {
            commit('USER_RESET_STATE');
        },
        Users_AddUser({commit}, User) {
            commit('USER_ADD', User);
        },
        Users_UpdateUser({commit, state}, User) {
            commit('USER_UPDATE_USER', User);
        },
        Users_UpdateSelf({commit}, User) {
            commit('USER_UPDATE', User);
        },
        Users_RemoveUser({commit}, User) {
            commit('USER_REMOVE_USER', User);
        },
        User_UpdateRoles({commit}, Roles) {
            commit('USER_UPDATE_ROLES', Roles);
        },
        Users_UpdateAllRoles({commit}, Roles) {
            commit('USER_UPDATE_ALL_ROLES', Roles);
        },
        User_UpdateUserRoles({commit}, Roles) {
            commit('USER_UPDATE_ROLES', Roles);
        },
        Users_UpdateAll({commit}, Users) {
            commit('USER_UPDATE_ALL', Users);
        },
        User_UpdateSuccessMessage({commit}, Message) {
            commit('USER_UPDATE_SUCCESS', Message);
        },
        User_UpdateSites ({commit}) {
            apiUsers.GetAllSites().then(res => {
                if (res.status === 200) { commit('USER_UPDATE_SITES', res.data); }
            });
        }
    }
};
