<template>
  <div
    class="form-group"
    :class="[
        divClasses,
      { 'has-danger': error },
      { 'has-danger': isError },
      { 'has-success': !error && touched },
      { 'has-success': isValid },
    ]"
  >
    <label>Images</label>
    <fg-input type="text" placeholder="Image URL" v-model:value="Image" addonRightIcon="FA-plus" :addonRightIconClick="AddImage"></fg-input>
    <div class="row ImageGrid" @drop="onDrop($event, 'Nevermind')" @dragleave.prevent>
      <div v-for="(Image, k) in Images" :key="'Image'+k" class="col-12 col-md-3" :class="{borderRightRed: borderRightRed(k), borderLeftRed: borderLeftRed(k)}"
           draggable="true"
           @dragstart="startDrag($event, k)"
           @dragenter="dragEnter($event, k)"
           @dragleave="dragLeave($event, k)"
           @drop="onDrop($event, k)"
           @dragover.prevent
           @dragenter.prevent>
        <img :src="Image" alt="Image">
        <span class="btn btn-danger" @click="RemoveImage(k)">
            <font-awesome-icon icon="trash-can" title="Delete" />
        </span>
        <font-awesome-icon icon="border-none" title="Move" class="grip btn btn-link"/>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  inheritAttrs: false,
  name: "Images Input",
  props: {
    Images: Array,
    name: String,
    isError: Boolean,
    isValid: Boolean,
    required: Boolean,
    label: String,
    error: String,
    divClasses: String,
    labelClasses: String,
    inputClasses: String,
    value: [String, Number],
    addonRightIcon: String,
    addonRightIconClick: {
      type: Function,
      default: () => {}
    },
    addonLeftIcon: String,
  },
  data() {
    return {
      touched: false,
      focused: false,
      errorMessage: '',
      Image: '',
      DraggingImageKey: false,
      HoveringImageKey: false,
      HoveringDepth: 0
    };
  },
  methods: {
    borderRightRed(HoveredKey) {
      if (HoveredKey !== this.HoveringImageKey) { return false; }
      return this.DraggingImageKey < this.HoveringImageKey;
    },
    borderLeftRed(HoveredKey) {
      if (HoveredKey !== this.HoveringImageKey) { return false; }
      return this.DraggingImageKey > this.HoveringImageKey;
    },
    startDrag(evt, k) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('number', k);
      this.DraggingImageKey = k;
    },
    dragEnter(evt, k) {
      this.HoveringDepth = this.HoveringDepth ? this.HoveringDepth+1 : 1;
      this.HoveringImageKey = k;
    },
    dragLeave(evt, k) {
      this.HoveringDepth--;
      this.HoveringImageKey = this.HoveringDepth ? this.HoveringImageKey : false;
    },
    onDrop(evt, newKey) {
      if (newKey === 'Nevermind') { return false; }
      let oldKey = parseInt(evt.dataTransfer.getData('number'));
      if (newKey === oldKey) { return false; }
      let image = this.Images[oldKey];
      let newKeys = [];
      for (let x in this.Images)
      {
        x = parseInt(x);
        if (x === oldKey) { continue; }
        if (x === newKey)
        {
          if (oldKey > newKey)
          {
            newKeys.push(image);
            newKeys.push(this.Images[x]);
          }
          else
          {
            newKeys.push(this.Images[x]);
            newKeys.push(image);
          }
        }
        else { newKeys.push(this.Images[x]); }
      }
      this.$emit('update:Images', newKeys);
      this.DraggingImageKey = false;
      this.HoveringImageKey = false;
      this.HoveringDepth = false;
    },
    AddImage() {
      if (!this.Image.length) { return false; }
      let Image = this.Image;
      if (Image.indexOf('https://drive.google.com/') === 0)
      {
        let aImage = Image.replace('https://drive.google.com/', '').split('/');
        console.log({aImage, positionDelete: aImage[aImage.length-2]});
        Image = 'https://drive.google.com/uc?export=view&id='+aImage[aImage.length-2];
      }
      this.Images.push(Image);
      this.Image = '';
    },
    RemoveImage(index) {
      console.log({index});
      this.Images.splice(index, 1);
    }
  },
};
</script>
<style lang="scss">
</style>
