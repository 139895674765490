import apiWeights from '../api/weight';

let DefaultState = () => {
    return {
        BodyWeights: [],
        Pagination: {
            total: 0,
            current_page: 1,
            per_page: 20,
            last_page: 1,
        }
    };
};

export default {
    state: DefaultState(),
    getters: {
        BodyWeights_GetAll: state => () => {
            return state.BodyWeights;
        },
        BodyWeights_GetPagination: state => () => {
            return state.Pagination;
        },
        BodyWeights_GetByID: state => (WeightID) => {
            return state.BodyWeights.find(BW => BW.id === WeightID);
        }
    },
    mutations: {
        BODY_WEIGHTS_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        BODY_WEIGHTS_ADD(state, Weight) {
            Weight.images = JSON.parse(Weight.images);
            state.BodyWeights.push(Weight);
        },
        BODY_WEIGHTS_UPDATE_WEIGHT(state, Weight) {
            for (let x in state.BodyWeights) {
                if (state.BodyWeights[x].id === Weight.id) {
                    Weight.images = JSON.parse(Weight.images);
                    state.BodyWeights[x] = Weight;
                }
            }
        },
        BODY_WEIGHTS_REMOVE_WEIGHT(state, WeightID) {
            for (let x in state.BodyWeights) { if (state.BodyWeights[x].id === WeightID) { state.BodyWeights.splice(x, 1); } }
        },
        BODY_WEIGHTS_UPDATE_ALL(state, Weights) {
            state.BodyWeights = Weights.data.map(Weight => {
                Weight.images = JSON.parse(Weight.images);
                return Weight;
            });
            state.Pagination = {
                total: Weights.total,
                current_page: Weights.current_page,
                per_page: Weights.per_page,
                last_page: Weights.last_page,
            };
        },
    },
    actions: {
        BodyWeights_ResetState({commit}) {
            commit('BODY_WEIGHTS_RESET_STATE');
        },
        BodyWeights_AddWeight({commit}, Weight) {
            commit('BODY_WEIGHTS_ADD', Weight);
        },
        BodyWeights_UpdateWeight({commit}, Weight) {
            commit('BODY_WEIGHTS_UPDATE_WEIGHT', Weight);
        },
        BodyWeights_RemoveWeight({commit}, WeightID) {
            commit('BODY_WEIGHTS_REMOVE_WEIGHT', WeightID);
        },
        BodyWeights_UpdateAll({commit}, Weights) {
            console.log({Weights});
            if (typeof Weights.GetemAll === 'undefined') { commit('BODY_WEIGHTS_UPDATE_ALL', Weights); }
            else
            {
                let Promise = Weights.GetemAll ? apiWeights.Index() : apiWeights.FindByUserID(Weights.UserID);
                Promise.then(res => {
                    if (typeof res.data.data !== 'undefined') { commit('BODY_WEIGHTS_UPDATE_ALL', res.data); }
                });
            }
        },
    }
};
