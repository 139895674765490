import DashboardLayout from "src/Modules/Users/Layouts/DashboardLayout.vue";
import AuthLayout from "src/Modules/Users/Layouts/AuthLayout.vue";

// Page Headers
import DashboardHeader from "src/Modules/Users/Pages/Dashboard/DashboardHeader.vue";
import DefaultHeader from "src/Modules/Users/Pages/DefaultHeader";

// Dashboard pages
import Dashboard from "./Pages/Dashboard/Dashboard.vue";

// Pages
import Users from 'src/Modules/Users/Pages/Dashboard/Users';
import Profile from "src/Modules/Users/Pages/Dashboard/UserProfile/UserProfile.vue";
import Login from 'src/Modules/Users/Pages/Auth/Login.vue';
import ForgotPassword from 'src/Modules/Users/Pages/Auth/ForgotPassword.vue';
import ResetPassword from 'src/Modules/Users/Pages/Auth/ResetPassword.vue';
import Register from "src/Modules/Users/Pages/Auth/Register.vue";
import VerifyEmail from "src/Modules/Users/Pages/Auth/VerifyEmail";
// import Lock from "src/pages/Dashboard/Pages/Lock.vue";

let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [
        {
            path: "/Login",
            name: "Login",
            component: Login,
            meta: { guestOnly: true }
        },
        {
            path: "/ForgotPassword",
            name: "ForgotPassword",
            component: ForgotPassword,
            meta: { guestOnly: true }
        },
        {
            path: "/ResetPassword",
            name: "ResetPassword",
            component: ResetPassword,
            meta: { guestOnly: true }
        },
        {
            path: "/Register",
            name: "Register",
            component: Register,
            meta: { guestOnly: true }
        }/*,
        {
            path: "/lock",
            name: "Lock",
            component: Lock
        }*/
    ]
};

let UserPages = {
    path: "/Users",
    component: DashboardLayout,
    children: [
        {
            path: "",
            name: "Users",
            components: { default: Users, header: DefaultHeader },
            meta: { authOnly: true }
        }
    ]
};

const routes = [
    UserPages,
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/dashboard",
        name: "Dashboard Home",
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                // components: { default: Dashboard, header: DashboardHeader },
                components: { default: Dashboard, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: "Profile",
                name: "Profile Profile",
                components: { default: Profile, header: DefaultHeader },
                meta: { authOnly: true }
            },
            {
                path: "/Email/Verify/:ID/:Hash",
                name: "VerifyEmail",
                components: { default: VerifyEmail, header: DefaultHeader },
                meta: { authOnly: true }
            }
        ]
    },
    authPages,
];

export default routes;
