<template>
    <form @submit.prevent="save">

        <div v-if="Error" class="alert alert-danger" v-html="Error"></div>

        <div class="row">
            <div class="col-12">
                <fg-input label="Name" placeholder="Name" v-model:value="formInputs.name"></fg-input>
            </div>
            <div class="col-12">
                <fg-input label="Ingredients" placeholder="Mesaurment" v-model:value="IngredientMeasurment"></fg-input>
                <MultiText :Inputs="Ingredients" :LabelText="IngredientMeasurment" :InputsLabels="IngredientsMeasurments" placeholder="Ingredient"></MultiText>
            </div>
            <div class="col-12">
                <MultiTextArea :Inputs="formInputs.steps" label="Steps" placeholder="Step"></MultiTextArea>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <label>Image</label>
                <fg-input type="url" placeholder="Image URL" v-model:value="Image" addonRightIcon="FA-plus" :addonRightIconClick="AddImage"></fg-input>
                <img v-if="formInputs.image" :src="formInputs.image" alt="Image">
            </div>
            <div class="col-6">
                <label>Video</label>
                <fg-input type="url" placeholder="Video" v-model:value="Video" addonRightIcon="FA-plus" :addonRightIconClick="AddVideo"></fg-input>
                <iframe v-if="formInputs.video && formInputs.video.indexOf('youtube.com') > -1"
                    width="560" height="315" :src="formInputs.video"
                    style="border: 0"
                    ></iframe>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <button class="btn btn-primary">{{ AddEdit }} Recipe{{ RecipeID ? ' #'+RecipeID : '' }}</button>
            </div>
        </div>

    </form>
</template>
<script>
import { format } from "date-fns";
import MultiText from "@/components/Inputs/MultiText";
import MultiTextArea from "@/components/Inputs/MultiTextArea";
import { Notification } from "@/components";
import { useToast } from "vue-toastification";

import apiTags from '@/Modules/Tags/api/tags';
import apiRecipes from '@/Modules/Health/api/recipes';

let FormInputs = () => {
    return {
        name: '',
        image: '',
        video: '',
        ingredients: [],
        steps: []
    };
};

export default {
    components: {
        MultiText,
        MultiTextArea
    },
    data() {
        return {
            formInputs: FormInputs(),
            Ingredients: [],
            IngredientsMeasurments: [],
            IngredientMeasurment: '',
            Image: '',
            Video: '',
            SettingFormInputs: false,
            Error: ''
        };
    },
    props: {
        Recipe: {
            type: Object,
            default: {}
        },
        InsertNew: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        AddEdit() {
            this.SetFormInputs();
            return this.InsertNew ? 'Insert New' : 'Update';
        },
        RecipeID() {
            return typeof this.Recipe.id !== 'undefined' ? this.Recipe.id : 0;
        },
        jsonIngredients() {
            let Ing = []; 
            for (let k in this.Ingredients)
            {
                Ing.push({Measurment: this.IngredientsMeasurments[k], Ingredient: this.Ingredients[k]});
            }
            return Ing;
        }
    },
    mounted() {
        this.SetFormInputs();
    },
    methods: {
        UpdateImages(Images) {
            this.formInputs.image = Image;
        },
        SetFormInputs() {
            this.SettingFormInputs = true;
            this.Error = '';
            this.formInputs = this.RecipeID ? this.Recipe : FormInputs();
            this.IngredientsMeasurments = [];
            let Ingredients = [];
            this.formInputs.ingredients.forEach(Ing => {
                Ingredients.push(Ing.Ingredient);
                this.IngredientsMeasurments.push(Ing.Measurment);
            });
            this.Ingredients = Ingredients;
        },
        save() {
            this.Error = '';
            if (this.InsertNew)
            {
                apiRecipes.Add(this.formInputs).then(res => {
                    this.$store.dispatch('Recipes_Add', res.data);
                    this.$emit('CloseRecipeModal');
                    this.formInputs = FormInputs();
                    this.NotifyUser('Recipe Added Successfully!', 'success');
                }).catch(e => {
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
            else
            {
                apiRecipes.Update(this.Recipe.id, this.formInputs).then(res => {
                    this.$store.dispatch('Recipes_Update', res.data);
                    this.$emit('CloseRecipeModal');
                    this.formInputs = FormInputs();
                    this.NotifyUser('Recipe Updated Successfully!', 'success');
                }).catch(e => {
                    this.processErrors(e.response.data.errors, e.response.status);
                });
            }
        },
        processErrors(Errors, Status) {
            let i = 0;
            if (Status === 422)
            {
                for (let x in Errors)
                {
                    this.Error += (i?'<br>':'')+Errors[x].join('<br>');
                    i++;
                }
            }
        },
        NotifyUser(text, type) {
            const toast = useToast();
            const icon = "now-ui-icons ui-1_bell-53";
            const content = {
                component: Notification,
                props: { icon, text, type },
            };
            toast(content, {
                hideProgressBar: true,
                icon: false,
                closeButton: false,
                position: 'top-right',
            });
        },
        SetIngredients() {
            this.formInputs.ingredients = [];
            for (let k in this.Ingredients)
            {
                this.formInputs.ingredients.push({Measurment: this.IngredientsMeasurments[k], Ingredient: this.Ingredients[k]});
            }
        },
        AddImage() {
            let Image = this.Image;
            if (Image.indexOf('https://drive.google.com/') === 0)
            {
                let aImage = Image.replace('https://drive.google.com/').split('/');
                Image = 'https://drive.google.com/uc?export=view&id='+aImage[aImage.length-2];
            }
            this.formInputs.image = Image;
            this.Image = '';
        },
        AddVideo() {
            // if (!this.Image.length) { return false; }
            let Video = this.Video;
            if (Video.indexOf('https://drive.google.com/') === 0)
            {
                let aVideo = Video.replace('https://drive.google.com/').split('/');
                Video = 'https://drive.google.com/uc?export=view&id='+aVideo[aVideo.length-2];
            }
            this.formInputs.video = Video;
            this.Video = '';
        }
    },
    watch: {
        jsonIngredients() {
            if (!this.SettingFormInputs)
            {
                this.formInputs.ingredients = this.jsonIngredients;
            }
                this.SettingFormInputs = false;
        }
    }
};
</script>
<style lang="scss">
</style>
