<template>
  <div
    class="MultiText form-group"
    :class="[
        divClasses,
      { 'has-danger': error },
      { 'has-danger': isError },
      { 'has-success': !error && touched },
      { 'has-success': isValid },
    ]"
  >
    <label>{{ label }}</label>
    <fg-input type="text" :placeholder="placeholder" v-model:value="InputText" addonRightIcon="FA-plus" :addonRightIconClick="AddText"></fg-input>
    <div class="row">
      <div v-for="(Input, k) in Inputs" :key="'Input'+k" class="col-12 TextLine">
        <span class="btn btn-danger mr-4" @click="RemoveText(k)">
            <font-awesome-icon icon="trash-can" title="Delete" />
        </span>
        <label>{{ InputLabel(k) }}</label>{{ Input }}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  inheritAttrs: false,
  name: "Text Input",
  props: {
    LabelText: String,
    Inputs: Array,
    InputsLabels: Array,
    name: String,
    isError: Boolean,
    isValid: Boolean,
    required: Boolean,
    label: String,
    placeholder: String,
    error: String,
    divClasses: String,
    labelClasses: String,
    inputClasses: String,
    value: [String, Number],
    addonRightIcon: String,
    addonRightIconClick: {
      type: Function,
      default: () => {}
    },
    addonLeftIcon: String,
  },
  data() {
    return {
      touched: false,
      focused: false,
      errorMessage: '',
      InputText: ''
    };
  },
  methods: {
    AddText() {
      if (!this.InputText.length) { return false; }
      this.Inputs.push(this.InputText);
      this.InputsLabels.push(this.LabelText);
      this.InputText = '';
    },
    InputLabel(k) {
      let Label = '';
      if (typeof this.InputsLabels[k] !== 'undefined')
      { Label = this.InputsLabels[k]; }
      return Label;
    },
    RemoveText(index) {
      this.Inputs.splice(index, 1);
      this.InputsLabels.splice(index, 1);
    }
  },
};
</script>
<style lang="scss">
.MultiText {
  .TextLine {
    label { margin-right: 10px; min-width: 120px; text-align: right; font-weight: bold; }
  }
}
</style>
