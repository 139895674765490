import apiContents from './api/contents';
import apiCourses from './api/courses';
import apiCompleted from './api/completed';
import apiSubjects from './api/subjects';

let DefaultState = () => {
    return {
        Courses: [],
        Subjects: [],
        Contents: [],
        Completed: [],
        CoursesFetched: false,
        ContentsFetched: false,
        CompletedFetched: false,
        CompletedAdding: [],
        CoursesLoading: false,
        ContentsLoading: false,
        Pagination: {
            total: 0,
            current_page: 1,
            per_page: 20,
            last_page: 1,
        }
    };
};

export default {
    state: DefaultState(),
    getters: {
        Courses_GetAll: state => () => {
            return state.Courses.sort((a, b) => {
                if (a.title > b.title) { return 1; }
                if (a.title < b.title) { return -1; }
                return 0;
            });
        },
        Courses_GetPagination: state => () => {
            return state.Pagination;
        },
        Courses_GetByID: state => (CourseID) => {
            return state.Courses.find(A => A.id === parseInt(CourseID)) || {id: 0, title: ''};
        },
        CoursesSubjects_GetAll: state => () => {
            return state.Subjects.sort((a, b) => {
                if (a.sequence > b.sequence) { return 1; }
                if (a.sequence < b.sequence) { return -1; }
                return 0;
            });
        },
        CoursesSubjects_GetByID: state => (SubjectID) => {
            return state.Subjects.find(A => A.id === parseInt(SubjectID)) || {id: 0, title: '', sequence: '', contents_count: 0};
        },
        CoursesSubjectsContents_GetAll: state => () => {
            return state.Contents.sort((a, b) => {
                if (a.sequence > b.sequence) { return 1; }
                if (a.sequence < b.sequence) { return -1; }
                return 0;
            });
        },
        CoursesSubjectsContents_GetByID: state => (ContentID) => {
            return state.Contents.find(A => A.id === parseInt(ContentID)) || {id: 0, title: '', sequence: '', content: ''};
        },
        CoursesSubjectsContents_GetPrevContent: state => ContentSequence => {
            return state.Contents.find(Content => { return Content.sequence === ContentSequence-1; });
        },
        CoursesSubjectsContents_GetNextContent: state => ContentSequence => {
            return state.Contents.find(Content => { return Content.sequence === ContentSequence+1; });
        },
        CoursesCompleted_Courses: state => () => {
            return state.Completed.filter(Complete => { return Complete.courses_id && !Complete.subjects_id && !Complete.contents_id; });
        },
        CoursesCompleted_Subjects: state => () => {
            return state.Completed.filter(Complete => { return Complete.courses_id && Complete.subjects_id && !Complete.contents_id; });
        },
        CoursesCompleted_Contents: state => () => {
            return state.Completed.filter(Complete => { return Complete.courses_id && Complete.subjects_id && Complete.contents_id; });
        }
    },
    mutations: {
        COURSES_RESET_STATE(state) {
            Object.assign(state, DefaultState());
        },
        COURSES_ADD(state, Course) {
            let CourseExists = false;
            for (let x in state.Courses)
            {
                if (state.Courses[x].id === Course.id)
                {
                    state.Courses[x] = Course;
                    CourseExists = true;
                }
            }
            if (!CourseExists) { state.Courses.push(Course); }
            state.CoursesFetched = true;
        },
        COURSES_UPDATE_COURSE(state, Course) {
            for (let x in state.Courses) { if (state.Courses[x].id === Course.id) { state.Courses[x] = Course; } }
        },
        COURSES_REMOVE_COURSE(state, Course) {
            for (let x in state.Courses) { if (state.Courses[x].id === Course.id) { state.Courses.splice(x, 1); } }
        },
        COURSES_UPDATE_ALL(state, Courses) {
            console.log({Courses});
            state.Subjects = [];
            state.Courses = Courses.map(Course => {
                state.Subjects = [...state.Subjects, ...Course.subjects];
                delete Course.subjects;
                return Course;
            });
            state.CoursesLoading = false;
            state.CoursesFetched = true;
        },
        COURSES_SUBJECTS_UPDATE_ALL(state, Subjects) {
            state.Subjects = Subjects
            state.CoursesLoading = false;
        },
        COURSES_SUBJECTS_ADD(state, Subject) {
            let SubjectExists = false;
            for (let x in state.Subjects)
            {
                if (state.Subjects[x].id === Subject.id)
                {
                    state.Subjects[x] = Subject;
                    SubjectExists = true;
                }
            }
            if (!SubjectExists) { state.Subjects.push(Subject); }
        },
        COURSES_SUBJECTS_REMOVE_SUBJECT(state, Subject) {
            for (let x in state.Subjects) { if (state.Subjects[x].id === Subject.id) { state.Subjects.splice(x, 1); } }
        },
        COURSES_SUBJECTS_CONTENTS_UPDATE_ALL(state, Contents) {
            state.Contents = Contents;
            state.ContentsLoading = false;
            state.ContentsFetched = true;
        },
        COURSES_SUBJECTS_CONTENTS_ADD(state, Content) {
            let ContentExist = false;
            for (let x in state.Contents)
            {
                if (state.Contents[x].id === Content.id)
                {
                    state.Contents[x] = Content;
                    ContentExist = true;
                }
            }
            if (!ContentExist) { state.Contents.push(Content); }
        },
        COURSES_SUBJECTS_CONTENTS_UPDATE_CONTENT(state, Content) {
            for (let x in state.Contents) { if (state.Contents[x].id === Content.id) { state.Contents[x] = Content; } }
        },
        COURSES_SUBJECTS_CONTENTS_REMOVE_CONTENT(state, Content) {
            for (let x in state.Contents) { if (state.Contents[x].id === Content.id) { state.Contents.splice(x, 1); } }
        },
        COURSES_COMPLETED_UPDATE_ALL(state, Completed) {
            state.Completed = Completed;
            state.CompletedFetched = true;
        },
        COURSES_COMPLETED_ADD(state, Completed) {
            let CompletedExists = false;
            for (let x in state.Completed)
            {
                if (state.Completed[x].id === Completed.id)
                {
                    state.Completed[x] = Completed;
                    CompletedExists = true;
                }
            }
            if (!CompletedExists) { state.Completed.push(Completed); }
            state.CompletedAdding.shift();
        },
        COURSES_COMPLETED_UPDATE_CONTENT(state, Completed) {
            for (let x in state.Completed) { if (state.Completed[x].id === Completed.id) { state.Completed[x] = Completed; } }
        }
    },
    actions: {
        Courses_ResetState({commit}) {
            commit('COURSES_RESET_STATE');
        },
        Courses_AddCourse({commit}, Course) {
            commit('COURSES_ADD', Course);
        },
        Courses_UpdateCourse({commit}, Course) {
            commit('COURSES_UPDATE_COURSE', Course);
        },
        Courses_RemoveCourse({commit}, Course) {
            apiCourses.Delete(Course.id).then(res => {
                commit('COURSES_REMOVE_COURSE', Course);
            });
        },
        Courses_UpdateAll({commit, state}, Courses) {
            if (!state.CoursesLoading)
            {
                state.CoursesFetched = false;
                state.CoursesLoading = true;
                if (Courses) { commit('COURSES_UPDATE_ALL', Courses); }
                else
                {
                    apiCourses.Index().then(res => {
                        commit('COURSES_UPDATE_ALL', res.data);
                    });
                }
            }
        },
        Courses_GetByID({commit}, CourseID) {
            return apiCourses.FindByID(CourseID).then(res => {
                commit('COURSES_ADD', res.data);
                if (res.data.subjects) { commit('COURSES_SUBJECTS_UPDATE_ALL', res.data.subjects); };
                return res.data;
            });
        },
        CoursesSubjects_RemoveSubject({commit}, Subject) {
            apiSubjects.Delete(Subject.courses_id, Subject.id).then(res => {
                commit('COURSES_SUBJECTS_REMOVE_SUBJECT', Subject);
            });
        },
        CoursesSubjectsContents_UpdateAll({commit, state}, Contents) {
            if (!state.ContentsLoading)
            {
                state.ContentsFetched = false;
                state.ContentsLoading = true;
                if (Contents)
                {
                    if (typeof Contents.CourseID === 'undefined') { commit('COURSES_SUBJECTS_CONTENTS_UPDATE_ALL', Contents); }
                    else
                    {
                        apiContents.Index(Contents.CourseID, Contents.SubjectID).then(res => {
                            console.log({res});
                            commit('COURSES_SUBJECTS_CONTENTS_UPDATE_ALL', res.data);
                        });
                    }
                }
                else
                {
                    apiContents.Index().then(res => {
                        commit('COURSES_SUBJECTS_CONTENTS_UPDATE_ALL', res.data);
                    });
                }
            }
        },
        CoursesSubjectsContents_AddContent({commit}, Content) {
            commit('COURSES_SUBJECTS_CONTENTS_ADD', Content);
        },
        CoursesSubjectsContents_UpdateContent({commit}, Content) {
            commit('COURSES_SUBJECTS_CONTENTS_UPDATE_CONTENT', Content);
        },
        CoursesSubjectsContents_GetByID({commit}, Content) {
            return apiContents.FindByID(Content.CourseID, Content.SubjectID, Content.ContentID).then(res => {
                commit('COURSES_SUBJECTS_CONTENTS_ADD', res.data);
                return res.data;
            });
        },
        CoursesSubjectsContents_RemoveContent({commit}, Content) {
            apiContents.Delete(Content.CourseID, Content.SubjectID, Content.ContentID).then(res => {
                commit('COURSES_SUBJECTS_CONTENTS_REMOVE_CONTENT', {id: Content.ContentID});
            });
        },
        CoursesCompleted_GetByUserID({commit, state}, UserID) {
            state.CompletedFetched = false;
            apiCompleted.Index(UserID).then(res => {
                commit('COURSES_COMPLETED_UPDATE_ALL', res.data);
            });
        },
        CoursesCompleted_Add({commit, state}, CompletedData) {
            state.CompletedAdding.push(CompletedData);
            let Existing = state.Completed.find(Complete => {
                return Complete.courses_id === CompletedData.courses_id && Complete.subjects_id === CompletedData.subjects_id && Complete.contents_id === CompletedData.contents_id;
            });
            if (typeof Existing !== 'undefined')
            {
                apiCompleted.Update(Existing.id, CompletedData).then(res => { commit('COURSES_COMPLETED_ADD', res.data); });
            }
            else
            {
                apiCompleted.Add(CompletedData.users_id, CompletedData).then(res => { commit('COURSES_COMPLETED_ADD', res.data); });
            }
        }
    }
};
