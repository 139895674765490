<template>
    <router-view></router-view>
</template>

<script>
export default {
    computed: {
        State() { return this.$store.state; }
    }
}
</script>